import axios from 'axios';
import {
  CoingeckoCategory,
  CoingeckoToken,
  CoingeckoTokenFromFullList,
} from 'src/api/coingecko/types';
import { CoingeckoCategoryId } from 'src/constants/chain';

const inst = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
});

const getTokensList = () =>
  inst.get<CoingeckoTokenFromFullList[]>('/coins/list?include_platform=true');

const getTop100Tokens = (categoryId: CoingeckoCategoryId) => {
  if (categoryId === 'ethereum-ecosystem') {
    return inst.get<CoingeckoToken[]>(
      `/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=400&page=0`,
    );
  }
  return inst.get<CoingeckoToken[]>(`/coins/markets?vs_currency=usd&category=${categoryId}&page=0`);
};

function getTokensPrices<T extends string[]>(tokensIds: T) {
  return inst.get<Record<T[number], { usd: string }>>(
    `/simple/price?vs_currencies=usd&ids=${tokensIds.join(',')}`,
  );
}

const getCategories = () => inst.get<CoingeckoCategory[]>('/coins/categories/list');

export const coingeckoApi = {
  getTokensList,
  getTop100Tokens,
  getTokensPrices,
  getCategories,
};
