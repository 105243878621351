import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

import { CloseModal } from 'src/assets/images/icons/ic-close-modal';
import { Tick } from 'src/assets/images/icons/ic-tick';
import { Link } from 'src/uiKit/Link';
import { CloseButton, Icon, ToastContainer } from './styled';

export const Notification: React.FC = () => {
  function onCloseToast(id: string) {
    toast.dismiss(id);
  }

  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: '10rem',
      }}
      toastOptions={{
        duration: 3000,
        style: {
          maxWidth: 'unset',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
          padding: '3rem 8rem 3rem 4rem',
          borderRadius: '2rem',
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) => (
            <ToastContainer>
              <CloseButton onClick={() => onCloseToast(t.id)}>{CloseModal}</CloseButton>
              <Icon status={t.type}>{Tick}</Icon>
              <span>{message}</span>
              {t.icon && (
                <div style={{ gridColumn: '2 / 3' }}>
                  {t.icon && <Link href={t.icon.toString()} />}
                </div>
              )}
            </ToastContainer>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
