import { BigNumber } from 'bignumber.js';
import { getBorrowModule01Loans } from 'src/contracts/borrowmodule01/contractFunctions';
import { Auction, AuctionFromChain, AuctionsInfoFromChain } from 'src/types/auctions';
import { fromBpToPercent } from 'src/utils/misc';

export function formatAuction(id: BigNumber.Value, auction: AuctionFromChain): Auction {
  const auctionInfo = {
    borrower: auction[0][0],
    startTS: auction[0][1],
    interestRateMin: fromBpToPercent(auction[0][2]),
    interestRateMax: fromBpToPercent(auction[0][3]),
  };
  return {
    id: id.toString(),
    auctionInfo: auctionInfo,
    state: auction[1],
    durationDays: auction[2],
    startTS: auction[3],
    interestRate: fromBpToPercent(auction[4]),
    collateral: auction[5],
    collateralType: auction[6],
    collateralIdOrAmount: auction[7].toString(),
    lender: auction[8],
    debtCurrency: auction[9],
    debtAmount: auction[10].toString(),
  };
}

export function formatAuctions(auctionsInfo: AuctionsInfoFromChain) {
  return auctionsInfo[1].map((auction, i) => formatAuction(auctionsInfo[0][i], auction));
}

export function getAuctionIR(auction: Auction) {
  return '0';
}

export async function fetchAuctionById(id: string | number) {
  try {
    const auctionInfo = (await getBorrowModule01Loans(id)) as AuctionFromChain;
    return formatAuction(id, auctionInfo);
  } catch (e) {
    console.error(e);
    return null;
  }
}
