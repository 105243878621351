import { getNftParams } from 'src/constants/nfts';
import {
  getErc721TokenTokenOfOwnerByIndex,
  getErc721TokenTokensOfOwner,
} from 'src/contracts/erc721token/contractFunctions';
import { chainStore } from 'src/store/chainStore';
import { Token } from 'src/types/tokens';
import { BN } from 'src/utils/bigNumber';

export const getNftsWithIndexes = async (infos: Token[], wallet: string) => {
  const nftsIndexes = await Promise.all(
    infos.map(async (token) => {
      if (BN(token.balance).lte(0)) return null;
      const preprocessor = getNftParams(
        chainStore.appChainId.getValue(),
        token.address,
      )?.preprocessor;

      if (preprocessor) return preprocessor(wallet, +token.balance);

      let indexes;

      indexes = await getErc721TokenTokensOfOwner(token.address, wallet);

      if (indexes) return indexes;

      indexes = await Promise.all(
        Array.from({ length: +token.balance }, (val, i) => {
          return getErc721TokenTokenOfOwnerByIndex(token.address, wallet, i);
        }),
      );

      return indexes;
    }),
  );

  return infos.map((token, i) => ({
    ...token,
    indexes: nftsIndexes[i]?.map(String),
  }));
};

export const getNftsImages = () => {};

export const getRaiseNfts = async (wallet: string) => {};
