import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChainSelector } from 'src/components/Header/ChainSelector';
import {
  HeaderBtnsContainer,
  HeaderConnectBtn,
  HeaderContainer,
  HeaderMenuBtn,
  HeaderShowAllOrders,
  HeaderWalletAddress,
  Logo,
  UiHeader,
} from 'src/components/Header/styled';
import { NetworkAttention } from 'src/components/NetworkAttention';
import { useAppType } from 'src/hooks/useAppType';
import { useSubject } from 'src/hooks/useSubject';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3Controller } from 'src/providers/Web3CtxProvider';
import { walletStore } from 'src/store/walletStore';
import { isMobile } from 'src/utils/helpers/isMobile';
import { shortenHash } from 'src/utils/ui';
import { TransactionStatus } from '../TransactionStatus';
import { MenuNavigation } from './MenuNavigation';

export const Header: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const userAddress = useSubject(walletStore.address);
  const setModal = useSetModal();

  const { connect, disconnect } = useWeb3Controller();

  const { chainUrlRepresentation } = useParams();
  const appType = useAppType();

  const showHeaderItems = chainUrlRepresentation;

  function handleConnectBtnClick() {
    const isTrust = window.ethereum?.isTrust;
    const isMetaMask = window.ethereum?.isMetaMask;

    if (!userAddress) {
      if (isMobile() && (isTrust || isMetaMask)) {
        connect();
      } else {
        setModal({ key: 'connect_wallet' });
      }
    } else disconnect();
  }

  function onCloseMenu() {
    setShowMenu(false);
  }

  useEffect(() => {
    if (!showMenu) return;

    document.addEventListener('click', onCloseMenu);

    return () => document.removeEventListener('click', onCloseMenu);
  }, [showMenu]);

  useEffect(() => {
    onCloseMenu();
  }, [chainUrlRepresentation]);

  return (
    <HeaderContainer>
      {showHeaderItems && <NetworkAttention />}
      <UiHeader>
        <Logo to={chainUrlRepresentation ? `/${chainUrlRepresentation}/${appType}` : '/'}>
          borrow
        </Logo>
        {showHeaderItems && (
          <HeaderBtnsContainer>
            <ChainSelector />
            <HeaderShowAllOrders>
              <Link to={`/${chainUrlRepresentation}/${appType}/all-offers`}>Show all orders</Link>
            </HeaderShowAllOrders>
            {userAddress && <HeaderWalletAddress>{shortenHash(userAddress)}</HeaderWalletAddress>}
            <TransactionStatus />
            <HeaderConnectBtn type="button" onClick={handleConnectBtnClick}>
              {userAddress ? 'Disconnect' : 'Connect wallet'}
            </HeaderConnectBtn>
            <HeaderMenuBtn onClick={() => setShowMenu(!showMenu)} />
          </HeaderBtnsContainer>
        )}
        {showMenu && (
          <MenuNavigation appType={appType} onCloseMenu={onCloseMenu} userAddress={userAddress} />
        )}
      </UiHeader>
    </HeaderContainer>
  );
};
