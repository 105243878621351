import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AppType } from 'src/constants/appType';
import { useAppType } from 'src/hooks/useAppType';
import { MenuSection } from './MenuSection';

export const AllPositions = () => {
  const appType = useAppType();
  const { chainUrlRepresentation } = useParams();

  const allPositions20 = useMemo(
    () => [
      {
        id: 'allOffers',
        name: 'All active orders',
        to: `/${chainUrlRepresentation}/${appType}/all-offers`,
      },
      {
        id: 'collections',
        name: 'Create new order',
        to: `/${chainUrlRepresentation}/${appType}/borrow`,
      },
    ],
    [appType, chainUrlRepresentation],
  );

  const allPositions721 = useMemo(
    () => [
      {
        id: 'allOffers',
        name: 'All active orders',
        to: `/${chainUrlRepresentation}/${appType}/all-offers`,
      },
      {
        id: 'collections',
        name: 'Create new order',
        to: `/${chainUrlRepresentation}/${appType}/borrow`,
      },
    ],
    [appType, chainUrlRepresentation],
  );

  return <MenuSection links={appType === AppType.erc20 ? allPositions20 : allPositions721} />;
};
