import { SpinnerContainer } from 'src/uiKit/Spinner';
import { borderRadius, color, fontFamily } from 'src/uiKit/theme';
import styled from 'styled-components';

export const ChainSelectorContainer = styled.div`
  position: relative;
  z-index: 10;
  margin-right: 1.8rem;
  color: ${color.black};
  white-space: nowrap;
`;

export const ChainSelectorCurrent = styled.button<{ showOptions: boolean; disabled: boolean }>`
  min-width: 14.5rem;
  text-align: center;
  position: relative;
  display: block;
  background: ${(p) => p.theme.chainSelectorColor};
  padding: 1.9rem ${(p) => (p.disabled ? '2rem' : '4rem')} 1.9rem 2rem;
  box-shadow: none;
  border: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  ${(p) =>
    p.showOptions &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  :after {
    content: '';
    display: ${(p) => (p.disabled ? 'none' : 'block')};
    position: absolute;
    right: 1.7rem;
    top: 45%;
    width: 1rem;
    height: 1rem;
    border-left: 0.2rem solid ${color.black};
    border-bottom: 0.2rem solid ${color.black};
    transform: translateY(-50%) rotate(-45deg);
  }
  ${SpinnerContainer} {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

export const ChainSelectorOptions = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background: ${(p) => p.theme.chainSelectorColor};
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: ${borderRadius.normal};
  border-bottom-right-radius: ${borderRadius.normal};
  min-width: 100%;
  text-align: left;
  padding: 0 1rem 2rem;
  margin-top: -0.2rem;
`;

export const ChainSelectorOption = styled.a`
  font-size: 2rem;
  line-height: 2em;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  padding-left: 1rem;
  :first-child {
    border-top: 0.2rem solid ${color.gray};
    padding-top: 0.5rem;
  }
`;
