import axios from 'axios';

const getWalletNfts = async (walletAddress: string) => {
  return await axios.get(
    `https://api.opensea.io/api/v1/collections?asset_owner=${walletAddress}&offset=0&limit=300`,
  );
};

export const openSeaApi = {
  getWalletNfts,
};
