import { BigNumber } from 'bignumber.js';
import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { trustApiGetTokensList } from 'src/api/trust';
import { getUserBalancesFromZapper } from 'src/api/zapper/zapper';
import { ChainId } from 'src/constants/chain';
import { FOO_WALLET, ZERO_ADDRESS } from 'src/constants/constants';
import { NOT_SUPPORTED_TOKENS, TOKENS_LIST_RINKEBY } from 'src/constants/tokens';
import { getBobHubOracleQuoteAssets } from 'src/contracts/bobhuboracle/contractFunctions';
import { getChainConfig, isBoba, isMainNet, isMoonbeam, isRinkeby } from 'src/store/chainStore';
import { pricesStore } from 'src/store/pricesStore';
import { Token, TokenFromTrust, TokensMap } from 'src/types/tokens';
import { BN } from 'src/utils/bigNumber';
import { getTop100TokensFromCoingecko } from 'src/utils/coingecko';
import { getTokensFromCoinMarketCap } from 'src/utils/coinmarketcap';
import { isAddressesEq } from 'src/utils/compareAddresses';
import { assertFulfilled } from 'src/utils/diff';
import { fetchTokensParams } from 'src/utils/token';

const log = debug('store:erc20Store');

export const erc20Store = {
  tokens: new BehaviorSubject<TokensMap>(new Map()),
  tokensListFromTrust: new BehaviorSubject<TokenFromTrust[]>([]),
  tokensLoading: new BehaviorSubject(true),
  balancesRequestPending: new BehaviorSubject(false),
  fetchedForChainId: null as null | ChainId,
  fetchingPrices: false,

  getTokensList(walletAddress: string | null, chainId: ChainId) {
    if (this.fetchedForChainId === chainId) return;

    this.getTokensListFromTrust()
      .then((tokens) => erc20Store.tokensListFromTrust.next(tokens))
      .catch(() => erc20Store.tokensListFromTrust.next([]));

    log('getTokensList fired', { walletAddress });

    this.tokensLoading.next(true);
    this.tokens.next(new Map());

    const promises = [];

    promises.push(this.getTop100Tokens(walletAddress || FOO_WALLET));
    promises.push(this.getStables(walletAddress || FOO_WALLET));

    Promise.all(promises).then((resp) => {
      this.addTokensToList(resp.filter(Boolean).flat());
      this.getUsdpPriceFromBobHub();
      pricesStore.getAndSetPricesFromZapper();
      erc20Store.fetchedForChainId = chainId;
      erc20Store.tokensLoading.next(false);
    });
  },

  async getTokensListFromTrust() {
    const trustWalletPath = getChainConfig()?.trustWalletPath;

    if (!trustWalletPath) return [];

    try {
      const resp = await trustApiGetTokensList(trustWalletPath);
      return resp.data.tokens;
    } catch (e) {
      return [];
    }
  },

  async getUsdpPriceFromBobHub() {
    const usdp = [...this.tokens.getValue().values()].find(
      (token) => token.symbol.toLowerCase() === 'usdp' || token.symbol.toLowerCase() === 'usg',
    );
    if (!usdp) return;
    if (!isMainNet()) {
      try {
        const priceFromChain = (await getBobHubOracleQuoteAssets([
          '0x1456688345527be1f37e9e627da0837d6f08c925',
        ])) as [BigNumber, number][];
        const price = BN(priceFromChain[0][0].toString())
          .div('0x10000000000000000000000000000')
          .toFixed(2);

        pricesStore.setTokenPrice(usdp.address, price, 'bobhub');
      } catch (e) {
        console.error(e);
        return;
      }
    }
    pricesStore.setTokenPrice(usdp.address, '1');
    return;
  },

  async getStables(wallet: string) {
    let stables = getChainConfig()?.stables;
    const weth = getChainConfig()?.wethAddress;

    if (!stables) {
      console.warn(`STABLES FOR CHAIN ${getChainConfig()?.id} NOT FOUND`);
      return;
    }

    if (weth) stables = [...stables, weth];

    return await this.fetchTokensParams(stables, wallet);
  },

  async getTop100Tokens(wallet: string) {
    if (isRinkeby()) {
      await this.fetchAndSetTokensParams(TOKENS_LIST_RINKEBY, null);
      return;
    }

    const request = (
      await Promise.allSettled([getTop100TokensFromCoingecko(), getTokensFromCoinMarketCap()])
    ).filter(assertFulfilled);

    const filteredTokens = request
      .map((res) => res.value)
      .flat()
      .filter(
        (token) =>
          !NOT_SUPPORTED_TOKENS.includes(token.address) &&
          !isAddressesEq(token.address, getChainConfig()?.ethMockAddress || ''),
      );

    let addresses = filteredTokens.map((token) => token.address.toLowerCase());
    const additionalTokensList = getChainConfig()?.additionalTokensList;

    if (additionalTokensList) {
      addresses = [...addresses, ...additionalTokensList];
    }

    const { erc20Tokens } = await fetchTokensParams(addresses, wallet);

    return erc20Tokens
      .map((token, i) => {
        const existingToken = filteredTokens.find((el) => isAddressesEq(el.address, token.address));
        if (existingToken)
          return {
            ...token,
            image: existingToken.image,
          };
        return token;
      })
      .filter(Boolean);
  },

  async getWalletTokens(walletAddress: string) {
    if (isRinkeby() || isMoonbeam() || isBoba()) return;
    return this.fetchAndSetWalletTokensFromZapper(walletAddress);
  },

  fetchAndSetWalletTokensFromZapper(walletAddress: string) {
    log('fetchAndSetWalletTokensFromZapper fired');
    const zapperNetwork = getChainConfig()?.zapper?.network;
    if (!zapperNetwork) return;

    return new Promise<void>((resolve) =>
      getUserBalancesFromZapper(walletAddress, zapperNetwork, async (resp) => {
        if (resp.appId === 'tokens') {
          const tokens = Object.values(resp.balance.wallet);
          const addresses: string[] = [];
          const prices: string[] = [];

          tokens.forEach((token) => {
            addresses.push(token.address);
            prices.push(token.context.price);
          });

          pricesStore.setTokensPrices(addresses, prices, 'zapper');

          const { erc20Tokens } = await fetchTokensParams(addresses, walletAddress);

          erc20Store.addTokensToList(erc20Tokens);

          return resolve();
        }
      }),
    );
  },

  async fetchTokensParams(addresses: string[], walletAddress: string | null) {
    try {
      const { erc20Tokens } = await fetchTokensParams(addresses, walletAddress);

      if (erc20Tokens.length !== addresses.length) {
        addresses.forEach((address) => {
          const exist = erc20Tokens.find((token) => isAddressesEq(address, token.address));
          if (exist) return;
          const forAdding = this.tokens.getValue().get(address.toLowerCase());
          if (!forAdding) {
            console.warn('CHECK THE FETCHING FUNCTION');
            return;
          }
          erc20Tokens.push(forAdding);
        });
      }

      this.addTokensToList(erc20Tokens);

      pricesStore.fetchTokensPrice(addresses);

      return erc20Tokens;
    } catch (e) {
      console.error(e);
      console.warn('fetchAndSetTokensParams fault to load tokens params: ', addresses);
    }

    return [];
  },

  async fetchAndSetTokensParams(addresses: string[], walletAddress: string | null) {
    try {
      const erc20Tokens = await this.fetchTokensParams(addresses, walletAddress);

      this.addTokensToList(erc20Tokens);

      return erc20Tokens;
    } catch (e) {
      console.error(e);
      console.warn('fetchAndSetTokensParams fault to load tokens params: ', addresses);
    }

    return [];
  },

  addTokensToList(tokens: Token[]) {
    const newTokensMap = new Map(this.tokens.getValue());
    const blockList = getChainConfig()?.blockList?.map((el) => el.toLowerCase());

    tokens.forEach((token) => {
      if (isAddressesEq(token.address, ZERO_ADDRESS)) return;
      if (blockList?.includes(token.address.toLowerCase())) return;
      if (newTokensMap.has(token.address.toLowerCase())) return;
      else newTokensMap.set(token.address.toLowerCase(), token);
    });

    this.tokens.next(newTokensMap);
  },

  async getTokenParams(address: string, walletAddress: string | null): Promise<Token> {
    let params = this.tokens.getValue().get(address.toLowerCase());

    if (!params) {
      await this.fetchAndSetTokensParams([address], walletAddress);
      params = this.tokens.getValue().get(address.toLowerCase()) as Token;
    }

    return params;
  },

  async getTokensParams(addresses: string[], walletAddress: string | null): Promise<Token[]> {
    const hasParams = addresses.every((address) =>
      this.tokens.getValue().get(address.toLowerCase()),
    );

    if (hasParams) {
      return addresses.map((address) => this.tokens.getValue().get(address.toLowerCase()) as Token);
    }

    return await this.fetchAndSetTokensParams(addresses, walletAddress);
  },

  async fetchAndSetTokensBalances(tokensAddresses: string[], walletAddress: string) {
    if (tokensAddresses.length === 0 || this.balancesRequestPending.getValue()) return;
    if (isAddressesEq(walletAddress, FOO_WALLET)) return;

    // TODO: remove when contracts will be fixed
    tokensAddresses = tokensAddresses.filter((address) => !NOT_SUPPORTED_TOKENS.includes(address));

    this.tokens.next(new Map());
    this.balancesRequestPending.next(true);

    const { erc20Tokens } = await fetchTokensParams(tokensAddresses, walletAddress);

    this.setTokensBalances(erc20Tokens);

    this.balancesRequestPending.next(false);
  },

  setTokensBalances(tokens: Token[]) {
    const tokensWithNewBalances = new Map([...erc20Store.tokens.getValue()]);

    tokens.forEach((token) => {
      const tokenFromStore = tokensWithNewBalances.get(token.address.toLowerCase());

      if (!tokenFromStore) {
        tokensWithNewBalances.set(token.address, token);
        return;
      }

      tokensWithNewBalances.set(token.address.toLowerCase(), {
        ...tokenFromStore,
        balance: token.balance,
      });
    });

    this.tokens.next(tokensWithNewBalances);
  },

  setTokenBalance(address: string, token: Token) {
    const tokenInTheList = this.tokens.getValue().get(address);

    if (!tokenInTheList) {
      this.addTokensToList([token]);
      return;
    }

    tokenInTheList.balance = token.balance;

    this.tokens.next(
      new Map([...this.tokens.getValue(), [tokenInTheList.address, tokenInTheList]]),
    );
  },

  clearBalances() {
    this.tokens.next(
      new Map(
        [...this.tokens.getValue().values()].map((token) => [
          token.address,
          { ...token, balance: '0' },
        ]),
      ),
    );
  },

  balanceTrackingCallback(walletAddress: string, tokenAddress: string) {
    fetchTokensParams([tokenAddress], walletAddress, true)
      .then(({ erc20Tokens }) => {
        const token = erc20Tokens[0];
        if (token) erc20Store.setTokenBalance(tokenAddress, token);
      })
      .catch((e) => {
        console.error(e);
      });
  },

  refreshAllBalances(walletAddress: string) {
    log('refreshAllBalances fired', walletAddress);
    if (this.tokensLoading.getValue()) return;
    this.fetchAndSetTokensBalances([...erc20Store.tokens.getValue().keys()], walletAddress);
  },
};
