import React from 'react';
import { useEffect, useState } from 'react';
import { useSubject } from 'src/hooks/useSubject';
import { getEthersWsProvider } from 'src/store/chainStore';
import {
  getTransactionsFromStorage,
  resetTransactionsStore,
  trackTx,
  transactionsStore,
} from 'src/store/transactionsStore';
import { walletStore } from 'src/store/walletStore';
import { TxStatus } from 'src/types/transactions';
import { Spinner } from 'src/uiKit/Spinner';
import { StatusContainer } from './styled';

export const TransactionStatus: React.FC = React.memo(() => {
  const walletAddress = useSubject(walletStore.address);
  const transactions = useSubject(transactionsStore.transactions);
  const [amount, setAmount] = useState(0);

  const getPendingTx = () => {
    return walletAddress
      ? Object.values(getTransactionsFromStorage()[walletAddress] || {}).filter(
          (tx) => tx.status === TxStatus.Pending,
        )
      : [];
  };

  useEffect(() => {
    resetTransactionsStore();
    if (!walletAddress) {
      setAmount(0);
      return;
    }

    const promises = getPendingTx().map(
      async (tx) => await getEthersWsProvider().getTransaction(tx.hash),
    );
    Promise.all(promises)
      .then((transactions) => {
        transactions.forEach((res) => {
          if (res) {
            trackTx(res);
          }
        });
      })
      .catch((e: any) => {
        console.error('Failed to get Transaction:', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  useEffect(() => {
    setAmount(getPendingTx().length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return amount ? (
    <StatusContainer>
      {amount} pending
      <Spinner style={{ width: '3rem' }} />
    </StatusContainer>
  ) : null;
});
