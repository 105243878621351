import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from 'src/assets/images/icons/ic-arrow-down.svg';

import FilterIcon from 'src/assets/images/icons/ic-filters.svg';
import { FilterContext, FiltersType } from 'src/context/FilterContext';
import {
  FilterLabel,
  FilterOption,
  FiltersContainer,
  FiltersOptions,
  FiltersTitle,
} from 'src/components/LeftSideMenu/components/Filter/styled';

type FilterType<T extends keyof FiltersType> = {
  id: T;
  label: string;
  values: FiltersType[T];
  isOpen: boolean;
};

const list: FilterType<keyof FiltersType>[] = [
  {
    id: 'loanTerm',
    label: 'Loan term',
    values: ['1d - 1m', '1m - 3m', '3m - 6m', '6m - 1y', '1y +'],
    isOpen: false,
  },
  {
    id: 'loanAmount',
    label: 'Loan price',
    values: ['< $1000', '$1000 - $10000', '$10000 - $100000', '> $100000'],
    isOpen: false,
  },
  {
    id: 'interestRate',
    label: 'Interest rate',
    values: ['0,1% - 10%', '10% - 20%', '20% +'],
    isOpen: false,
  },
];

type FilterProps<T extends keyof FiltersType> = {
  onFilterChange: (id: T, value: FiltersType[T][0]) => void;
};

export const Filter: React.FC<FilterProps<keyof FiltersType>> = ({ onFilterChange }) => {
  const [showFilters, setShowFilters] = useState(false);
  const { filters } = useContext(FilterContext);
  const [filtersList, setFiltersList] = useState(list);

  function documentClickListener() {
    setShowFilters(false);
  }

  function filterLabelClick(id: string) {
    setFiltersList((prevValue) =>
      prevValue.map((filter) =>
        filter.id === id
          ? {
              ...filter,
              isOpen: !filter.isOpen,
            }
          : filter,
      ),
    );
  }

  useEffect(() => {
    document.addEventListener('click', documentClickListener);
    return () => document.removeEventListener('click', documentClickListener);
  }, []);

  return (
    <FiltersContainer
      static
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation();
        setShowFilters(true);
      }}
    >
      <FiltersTitle
        style={{ marginBottom: 0 }}
        onClick={(e) => {
          e.nativeEvent.stopImmediatePropagation();
          setShowFilters(!showFilters);
        }}
      >
        <span>Filters</span>
        <img src={FilterIcon} alt="filter icon" />
      </FiltersTitle>
      <FiltersOptions showFilters={showFilters}>
        {filtersList.map((filter) => (
          <div key={filter.id}>
            <FilterLabel onClick={() => filterLabelClick(filter.id)}>
              {filter.label}
              <ArrowIcon />
            </FilterLabel>
            {filter.isOpen &&
              filter.values.map((value) => (
                <FilterOption
                  key={value}
                  onClick={() => onFilterChange(filter.id, value)}
                  isSelected={[...filters[filter.id]].includes(value)}
                >
                  {value}
                </FilterOption>
              ))}
          </div>
        ))}
      </FiltersOptions>
    </FiltersContainer>
  );
};
