export const getExecuteBtnLabel = (
  isAccountConnected: boolean,
  isWrongNetwork: boolean,
  isFullPrice: boolean,
  isApprovalNeeded: boolean,
): string => {
  if (!isAccountConnected) return 'Login to interact';
  if (isWrongNetwork) return 'Wrong netwrork';
  if (!isFullPrice) return 'Fetching data...';
  if (isApprovalNeeded) return 'Approve & Execute';
  return 'Execute';
};

export const shortenHash = (hash: string, n = 5): string =>
  `${hash.slice(0, n)}...${hash.slice(-n + 1)}`;
