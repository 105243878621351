import styled from 'styled-components';

import { color } from '../theme';

export const UiCheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  span {
    position: relative;
    padding-left: 4rem;
    font-size: 2rem;

    :before {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 1.5rem;
      transform: translateY(-50%);
      width: 2.1rem;
      height: 2.1rem;
      border: 0.2rem solid ${color.gray};
      border-radius: 0.5rem;
    }

    :after {
      content: '';
      position: absolute;
      left: 0.6rem;
      top: 1.2rem;
      width: 1.2rem;
      height: 0.6rem;
      border-left: 0.2rem solid ${color.white};
      border-bottom: 0.2rem solid ${color.white};
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;

    :checked ~ span {
      :before {
        border-color: ${color.black};
        background: ${color.black};
      }
    }
  }
`;
