import { Link, useParams } from 'react-router-dom';
import { useAppType } from 'src/hooks/useAppType';
import { base, breakpoint } from 'src/uiKit/theme';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5rem;
  margin-top: auto;
  @media (min-width: ${breakpoint.tablet}) {
    padding-top: ${base.base8};
    flex-direction: row;
  }
`;

const Links = styled.div`
  font-size: ${base.base6};
  margin-bottom: 2rem;
  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 0;
  }
  img {
    width: 4rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    :hover {
      text-decoration: underline;
    }
    :not(:last-child) {
      margin-right: ${base.base6};
    }
  }
`;

export const Footer = () => {
  const { chainUrlRepresentation } = useParams();
  const appType = useAppType();

  return (
    <StyledFooter>
      <Links>
        <Link to={`/${chainUrlRepresentation}/${appType}/knowledge-center`}>Docs</Link>
        <Link to={`/${chainUrlRepresentation}/${appType}/knowledge-center?tab=faq`}>FAQ</Link>
      </Links>
      <Links>
        <a href="https://twitter.com/unitprotocol" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/ic-twitter.svg" alt="soc icon" />
        </a>
        <a
          href="https://github.com/unitprotocol/borrow-module"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/icons/ic-github.svg" alt="soc icon" />
        </a>
        <a href="https://discord.gg/XZWEAKYR" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/ic-discord.svg" alt="soc icon" />
        </a>
      </Links>
    </StyledFooter>
  );
};
