import axios, { AxiosResponse } from 'axios';
import { CoinmarketcapCategory } from 'src/constants/chain';
import { IS_DEV } from 'src/constants/constants';
import { CoinmarketcapTokenFromChain } from './types';

const inst = axios.create({
  baseURL: IS_DEV ? '/' : 'https://cmc-pro-api.unitusercontent.xyz',
  headers: {
    'X-CMC_PRO_API_KEY': IS_DEV ? 'd72a8bef-148f-465c-a92e-814e5c1cd154' : '',
  },
});

const getCategory = (chainId: CoinmarketcapCategory) =>
  inst.get<AxiosResponse<{ coins: CoinmarketcapTokenFromChain[] }>>(
    `/v1/cryptocurrency/category?id=${chainId}&limit=900`,
  );

const getTokensList = () => inst.get('/v2/cryptocurrency/info');
// inst.get('/v1/cryptocurrency/listings/latest?start=1&limit=5000&convert=USD');

export const coinmarketcapApi = {
  getCategory,
  getTokensList,
};
