import * as ethers from 'ethers'
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'

import { getChainConfig, getEthersWsProvider } from 'src/store/chainStore'

    import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/borrowModule01.json'

export function getBorrowModule01Contract(provider: ethers.providers.WebSocketProvider | JsonRpcSigner) {
  return new ethers.Contract(getChainConfig()?.contracts.borrowModule01 as string, abi, provider)
}

export function getBorrowModule01BASIS_POINTS_IN_1() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.BASIS_POINTS_IN_1().catch((e: any) => {
    console.error('Failed to call BASIS_POINTS_IN_1 in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01MAX_DURATION_DAYS() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.MAX_DURATION_DAYS().catch((e: any) => {
    console.error('Failed to call MAX_DURATION_DAYS in borrowModule01:', e)
  })
            }
    
export function submitBorrowModule01Accept(_loanId: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'accept', [
    _loanId,
 { ...options },
  ])
            }
    
export function submitBorrowModule01CancelAuction(_loanId: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'cancelAuction', [
    _loanId,
 { ...options },
  ])
            }
    
export function getBorrowModule01GetActiveAuctions() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveAuctions().catch((e: any) => {
    console.error('Failed to call getActiveAuctions in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetActiveAuctionsCount() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveAuctionsCount().catch((e: any) => {
    console.error('Failed to call getActiveAuctionsCount in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetActiveAuctionsLimited(_offset: any, _limit: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveAuctionsLimited(
    _offset,
    _limit
  ).catch((e: any) => {
    console.error('Failed to call getActiveAuctionsLimited in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetActiveLoans() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveLoans().catch((e: any) => {
    console.error('Failed to call getActiveLoans in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetActiveLoansCount() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveLoansCount().catch((e: any) => {
    console.error('Failed to call getActiveLoansCount in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetActiveLoansLimited(_offset: any, _limit: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getActiveLoansLimited(
    _offset,
    _limit
  ).catch((e: any) => {
    console.error('Failed to call getActiveLoansLimited in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetLoans() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getLoans().catch((e: any) => {
    console.error('Failed to call getLoans in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetLoansCount() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getLoansCount().catch((e: any) => {
    console.error('Failed to call getLoansCount in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetLoansLimited(_offset: any, _limit: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getLoansLimited(
    _offset,
    _limit
  ).catch((e: any) => {
    console.error('Failed to call getLoansLimited in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetUserLoans(_user: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getUserLoans(
    _user
  ).catch((e: any) => {
    console.error('Failed to call getUserLoans in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetUserLoansCount(_user: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getUserLoansCount(
    _user
  ).catch((e: any) => {
    console.error('Failed to call getUserLoansCount in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01GetUserLoansLimited(_user: any, _offset: any, _limit: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.getUserLoansLimited(
    _user,
    _offset,
    _limit
  ).catch((e: any) => {
    console.error('Failed to call getUserLoansLimited in borrowModule01:', e)
  })
            }
    
export function submitBorrowModule01Liquidate(_loanId: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'liquidate', [
    _loanId,
 { ...options },
  ])
            }
    
export function getBorrowModule01LoanIdsByUser(addressInput1: any, uint256Input2: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.loanIdsByUser(
    addressInput1,
    uint256Input2
  ).catch((e: any) => {
    console.error('Failed to call loanIdsByUser in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01Loans(uint256Input1: any) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.loans(
    uint256Input1
  ).catch((e: any) => {
    console.error('Failed to call loans in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01OnERC721Received(
  operator: any,
  addressInput2: any,
  uint256Input3: any,
  bytesInput4: any,
) {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.onERC721Received(
    operator,
    addressInput2,
    uint256Input3,
    bytesInput4
  ).catch((e: any) => {
    console.error('Failed to call onERC721Received in borrowModule01:', e)
  })
            }
    
export function getBorrowModule01Parameters() {
              const contract = getBorrowModule01Contract(getEthersWsProvider())
                return contract.parameters().catch((e: any) => {
    console.error('Failed to call parameters in borrowModule01:', e)
  })
            }
    
export function submitBorrowModule01Repay(_loanId: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'repay', [
    _loanId,
 { ...options },
  ])
            }
    
export function submitBorrowModule01StartAuction(_params: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'startAuction', [
    _params,
 { ...options },
  ])
            }
    
export function submitBorrowModule01UpdateAuctionInterestRateMax(
  _loanId: any,
  _newInterestRateMax: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getBorrowModule01Contract(signer)
                return sendTransaction(contract, 'updateAuctionInterestRateMax', [
    _loanId,
    _newInterestRateMax,
 { ...options },
  ])
            }
    
