import { base, breakpoint } from 'src/uiKit/theme';
import styled from 'styled-components';

export const Links = styled.div`
  padding: 1.25rem 3.5rem;
  font-size: ${base.base6};
  margin-bottom: 2rem;
  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 0;
  }
  img {
    width: 3rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    :hover {
      text-decoration: underline;
    }
    :not(:last-child) {
      margin-right: ${base.base6};
    }
  }
`;