import styled from 'styled-components';

import { borderRadius, breakpoint, color, fontFamily } from '../theme';

export const ModalInner = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 100rem;
  vertical-align: middle;
  position: relative;
`;

export const ModalContent = styled.div`
  position: relative;
  padding: 10rem 20rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: ${color.black};
  text-align: initial;
  font-size: 2rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    border-radius: ${borderRadius.large};
  }
`;

export const ModalTitle = styled.h1`
  font-size: 5rem;
  ${fontFamily.maru};
  font-style: normal;
  margin-bottom: 1em;
  color: inherit;
  margin-top: 0;
  @media screen and (min-width: ${breakpoint.tablet}) {
    text-align: center;
    margin-top: initial;
  }
`;

export const StyledModal = styled.div`
  text-align: center;
  height: 100%;
  overflow: auto;
  padding: 0;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding: 11.2rem 0 3rem;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0;
  }
`;

export const ModalControlsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
`;

export const ModalCloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 6.2rem;
  color: ${color.black};
  margin: 0 auto;
  border: none;
  border-radius: ${borderRadius.middle};
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: absolute;
  bottom: calc(100% + 3rem);
  left: calc(100% + 3rem);
`;

export const ModalOuter = styled.div<{
  forCollateral: boolean;
  forMenu: boolean;
  forConnect: boolean;
  forTransaction: boolean;
}>`
  position: fixed;
  background: rgba(37, 37, 37, 0.3);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  vertical-align: middle;
  z-index: 1000;
  ${(p) =>
    p.forTransaction &&
    `
    ${ModalInner} {
      width: auto;
      min-width: 54rem;
    }
    ${ModalContent} {
      padding: 8.6rem 6.5rem 2.5rem;
      border-radius: 3.4rem;
    }
    ${ModalCloseBtn} {
      width: 2rem;
      height: 2rem;
      right: 4rem;
      left: unset;
      top: 4rem;
      box-shadow: none;
      color: ${color.gray};
      background-color: unset;
    }
  `}
  ${(p) =>
    p.forConnect &&
    `
    ${ModalInner} {
      max-width: auto;
      width: auto;
    }
    ${ModalContent} {
      padding: 6rem 10rem 6rem 6rem;
    }
    ${ModalCloseBtn} {
      display: none;
    }
  `}
  ${(p) =>
    p.forMenu &&
    `
    ${ModalInner} {
      vertical-align: top;
      max-width: 100%;
    }
    ${StyledModal} {
      padding-top: 0;
      padding-bottom: 0;
      @media screen and (min-width: ${breakpoint.desktop}) {
        padding: 0 0 3rem;
      }
    }
    ${ModalContent} {
      padding: 2rem;
      background: ${color.white};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: ${color.black};
      @media screen and (min-width: ${breakpoint.tablet}) {
        padding: 4rem 3rem;
        height: 100vh;
      }
      @media screen and (min-width: ${breakpoint.desktop}) {
        height: auto;
        padding: 7.5rem 17.5rem 17.5rem;
        border-bottom-right-radius: ${borderRadius.large};
        border-bottom-left-radius: ${borderRadius.large};
      }
    }
    ${ModalCloseBtn} {
      position: fixed;
      background: ${color.white};
      color: ${color.black};
      left: auto;
      right: 2rem;
      top: 2rem;
      transform: translate(0, 0);
      @media screen and (min-width: ${breakpoint.desktop}) {
        position: absolute;
        top: auto;
        bottom: 2.5rem;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  `}
  ${(p) =>
    p.forCollateral &&
    `
    ${StyledModal} {
      padding-bottom: 0;
    }
    ${ModalContent} {
      padding: 7.5rem 17.5rem;
      background: ${color.white};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 3rem;
      @media (min-width: ${breakpoint.desktop}) {
        padding: 7.5rem 17.5rem;
      }
    }
    ${ModalInner} {
      max-width: 100%;
      @media (min-width: ${breakpoint.desktop}) {
        max-width: 80%;
      }
    }
    ${ModalTitle} {
      color: ${color.white}
    }
    ${ModalCloseBtn} {
      position: fixed;
      color: ${color.white};
      background-color: ${color.purple};
      right: 2.5rem;
      top: 3rem;
      left: auto;
      @media screen and (min-width: ${breakpoint.tablet}) {
        right: calc(10% - 13rem);
        top: 2.5rem;
      }
    }
  `}
`;
