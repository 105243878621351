import { useSetModal } from 'src/providers/ModalsProvider';
import { Modal } from 'src/uiKit/Modal';
import { UIButton } from 'src/uiKit/UiButton/styled';
import { Controls, StyledMessage } from './styled';

type ConfirmationProps = {
  message: string;
  onAccept: () => void;
  onCancel: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationProps> = ({
  message,
  onAccept,
  onCancel,
}) => {
  const setModal = useSetModal();
  return (
    <Modal onCloseModal={() => setModal(null)} forTransaction>
      <StyledMessage>{message}</StyledMessage>
      <Controls>
        <UIButton outlined onClick={onCancel}>
          Cancel
        </UIButton>
        <UIButton onClick={onAccept}>Confirm</UIButton>
      </Controls>
    </Modal>
  );
};
