export enum AppType {
  erc721 = 'erc-721',
  erc20 = 'erc-20',
}

const [,, initialAppType] = window.location.pathname.split('/');
const supportedApps = Object.values(AppType);

export const isInitialAppTypeValid = supportedApps.includes(initialAppType as AppType);
export const appType = (isInitialAppTypeValid ? initialAppType : AppType.erc721) as AppType;

export const ECOSYSTEM = 'https://unit.xyz/';