export const NFTS: Record<
  number,
  | Record<
      string,
      {
        preprocessor?: (wallet: string, maxIndex: number) => string[];
        collectionLogo: string;
        opensea: string;
      }
    >
  | undefined
> = {
  1: {
    '0xaaa7a35e442a77e37cde2f445b359aabf5ad0387': {
      collectionLogo: '/images/nftsCollections/eth/SM.png',
      opensea: 'https://opensea.io/collection/streetmachine',
    },
    '0xdd1f245d2920c5e144417ba2e59cf094bd615a91': {
      collectionLogo: '/images/nftsCollections/eth/SM.png',
      opensea: 'https://opensea.io/collection/streetmachine-v2',
    },
    '0x60e4d786628fea6478f785a6d7e704777c86a7c6': {
      collectionLogo: '/images/nftsCollections/eth/mutant-ape-yacht-club.png',
      opensea: 'https://opensea.io/collection/mutant-ape-yacht-club',
    },
    '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d': {
      collectionLogo: '/images/nftsCollections/eth/boredapeyachtclub.png',
      opensea: 'https://opensea.io/collection/boredapeyachtclub',
    },
    '0x20172dab13e0cf9465382b32fe46cd51247cad71': {
      collectionLogo: '/images/nftsCollections/eth/pixel-cats.png',
      opensea: 'https://opensea.io/collection/pixel-cats',
    },
    '0xb7f7f6c52f2e2fdb1963eab30438024864c313f6': {
      collectionLogo: '/images/nftsCollections/eth/punks.png',
      opensea: 'https://opensea.io/collection/wrapped-cryptopunks/',
    },
    '0xed5af388653567af2f388e6224dc7c4b3241c544': {
      collectionLogo: '/images/nftsCollections/eth/azuki.jpg',
      opensea: 'https://opensea.io/collection/azuki',
    },
    '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b': {
      collectionLogo: '/images/nftsCollections/eth/clonex.avif',
      opensea: 'https://opensea.io/collection/clonex',
    },
    '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e': {
      collectionLogo: '/images/nftsCollections/eth/doodles.avif',
      opensea: 'https://opensea.io/collection/doodles-official',
    },
    '0xadec97ddc587f5285528069112b9b09f5ae5d15e': {
      collectionLogo: '/images/nftsCollections/eth/cyber-samurai.avif',
      opensea: 'https://opensea.io/collection/cybersamurai',
    },
    '0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d': {
      collectionLogo: '/images/nftsCollections/eth/fluf.avif',
      opensea: 'https://opensea.io/collection/fluf-world',
    },
    '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258': {
      collectionLogo: '/images/nftsCollections/eth/othr.avif',
      opensea: 'https://opensea.io/collection/otherdeed',
    },
    '0xfcf5306ec78088dd85bb484816a1e37161b005e6': {
      collectionLogo: '/images/nftsCollections/eth/only-bots.avif',
      opensea: 'https://opensea.io/collection/onlybots',
    },
    '0xf87e31492faf9a91b02ee0deaad50d51d56d5d4d': {
      collectionLogo: '/images/nftsCollections/eth/decentraland.avif',
      opensea: 'https://opensea.io/collection/decentraland',
    },
    '0x638196832001384b3a6457beb04372cff1878681': {
      collectionLogo: '/images/nftsCollections/eth/ghostlers.webp',
      opensea: 'https://opensea.io/collection/ghostlers',
    },
    '0xbd3531da5cf5857e7cfaa92426877b022e612cf8': {
      collectionLogo: '/images/nftsCollections/eth/penguins.png',
      opensea: 'https://opensea.io/collection/pudgypenguins',
    },
    '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7': {
      collectionLogo: '/images/nftsCollections/eth/meebits.png',
      opensea: 'https://opensea.io/collection/meebits',
    },
    '0x7fda36c8daedcc55b73e964c2831d6161ef60a75': {
      collectionLogo: '/images/nftsCollections/eth/yaypegs.png',
      opensea: 'https://opensea.io/collection/yaypegs',
    },
    '0xe6d48bf4ee912235398b96e16db6f310c21e82cb': {
      collectionLogo: '/images/nftsCollections/eth/godhatesnftees.png',
      opensea: 'https://opensea.io/collection/godhatesnftees',
    },
    '0x61e3d1c26802df805e9fc22dc26342e29ecfe860': {
      collectionLogo: '/images/nftsCollections/eth/savage-nation.png',
      opensea: 'https://opensea.io/collection/savage-nation',
    },
    '0xa6a4511e25c7f2a29be5ccf3978a0f3e238fd2c1': {
      collectionLogo: '/images/nftsCollections/eth/yaygifs.png',
      opensea: 'https://opensea.io/collection/yaygifs',
    },
    '0x39ee2c7b3cb80254225884ca001f57118c8f21b6': {
      collectionLogo: '/images/nftsCollections/eth/thepotatoz.png',
      opensea: 'https://opensea.io/collection/thepotatoz',
    },
    '0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03': {
      collectionLogo: '/images/nftsCollections/eth/nouns.png',
      opensea: 'https://opensea.io/collection/nouns',
    },
    '0xbda2481db91fc0f942ed3f53de378ba45ba9d17e': {
      collectionLogo: '/images/nftsCollections/eth/wolf-game-farmer.png',
      opensea: 'https://opensea.io/collection/wolf-game-farmer',
    },
    '0x062e691c2054de82f28008a8ccc6d7a1c8ce060d': {
      collectionLogo: '/images/nftsCollections/eth/pudgyrods.png',
      opensea: 'https://opensea.io/collection/pudgyrods',
    },
    '0x34cadc2ff14d57d6c3f806b5d798c6a27642af2e': {
      collectionLogo: '/images/nftsCollections/eth/pepeliberationarmy-v2.png',
      opensea: 'https://opensea.io/collection/pepeliberationarmy-v2',
    },
    '0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb': {
      collectionLogo: '/images/nftsCollections/eth/veefriends.png',
      opensea: 'https://opensea.io/collection/veefriends',
    },
    '0x1a92f7381b9f03921564a437210bb9396471050c': {
      collectionLogo: '/images/nftsCollections/eth/cool-cats-nft.png',
      opensea: 'https://opensea.io/collection/cool-cats-nft',
    },
    '0x518ba36f1ca6dfe3bb1b098b8dd0444030e79d9f': {
      collectionLogo: '/images/nftsCollections/eth/wonky-stonks.png',
      opensea: 'https://opensea.io/collection/wonky-stonks',
    },
    '0x9bf53d8c65f03d895dacaa776cc960e462ecb599': {
      collectionLogo: '/images/nftsCollections/eth/primera-by-mitchell-and-yun.png',
      opensea: 'https://opensea.io/collection/primera-by-mitchell-and-yun',
    },
    '0x6601a8e7b3d5ae631c1b1d07f01e75abe1cd1b89': {
      collectionLogo: '/images/nftsCollections/eth/bitz-ape.png',
      opensea: 'https://opensea.io/collection/bitz-ape',
    },
    '0xaf60f9140dfdf85f8db527b4db609ce1986bf735': {
      collectionLogo: '/images/nftsCollections/eth/squeakersnft.png',
      opensea: 'https://opensea.io/collection/squeakersnft',
    },
    '0x9370045ce37f381500ac7d6802513bb89871e076': {
      collectionLogo: '/images/nftsCollections/eth/apehaterclub.png',
      opensea: 'https://opensea.io/collection/apehaterclub',
    },
    '0x8c3fb10693b228e8b976ff33ce88f97ce2ea9563': {
      collectionLogo: '/images/nftsCollections/eth/the-plague.png',
      opensea: 'https://opensea.io/collection/the-plague',
    },
    '0x7d8820fa92eb1584636f4f5b8515b5476b75171a': {
      collectionLogo: '/images/nftsCollections/eth/murakami-flowers-2022-official.png',
      opensea: 'https://opensea.io/collection/murakami-flowers-2022-official',
    },
    '0x764aeebcf425d56800ef2c84f2578689415a2daa': {
      collectionLogo: '/images/nftsCollections/eth/sewerpass.png',
      opensea: 'https://opensea.io/collection/sewerpass',
    },
    '0x769272677fab02575e84945f03eca517acc544cc': {
      collectionLogo: '/images/nftsCollections/eth/thecaptainz.png',
      opensea: 'https://opensea.io/collection/thecaptainz',
    },
    '0xba30e5f9bb24caa003e9f2f0497ad287fdf95623': {
      collectionLogo: '/images/nftsCollections/eth/bored-ape-kennel-club.png',
      opensea: 'https://opensea.io/collection/bored-ape-kennel-club',
    },
    '0xeb2dfc54ebafca8f50efcc1e21a9d100b5aeb349': {
      collectionLogo: '/images/nftsCollections/eth/kubz.png',
      opensea: 'https://opensea.io/collection/kubz',
    },
    '0xccdf1373040d9ca4b5be1392d1945c1dae4a862c': {
      collectionLogo: '/images/nftsCollections/eth/porsche911.png',
      opensea: 'https://opensea.io/collection/porsche-911',
    },
    '0x597d757f8502f1fe8e7dd6fc7fe884a51c5ae2b9': {
      collectionLogo: '/images/nftsCollections/eth/liveLikeACatNekonoyouniikiru.png',
      opensea: 'https://opensea.io/collection/live-like-a-cat-nekonoyouniikiru',
    },
    '0xc99c679c50033bbc5321eb88752e89a93e9e83c5': {
      collectionLogo: '/images/nftsCollections/eth/killabears.png',
      opensea: 'https://opensea.io/collection/killabears',
    },
    '0xbce3781ae7ca1a5e050bd9c4c77369867ebc307e': {
      collectionLogo: '/images/nftsCollections/eth/goblintownwtf.png',
      opensea: 'https://opensea.io/collection/goblintownwtf',
    },
    '0xe1b81cd6a494cbca06a8e2055a62c2cf0fa5a8ac': {
      collectionLogo: '/images/nftsCollections/eth/kubzRelic.png',
      opensea: 'https://opensea.io/collection/kubzRelic',
    },
    '0x6c410cf0b8c113dc6a7641b431390b11d5515082': {
      collectionLogo: '/images/nftsCollections/eth/rtfktAnimusEgg.png',
      opensea: 'https://opensea.io/collection/rtfkt-animus-egg',
    },
    '0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d': {
      collectionLogo: '/images/nftsCollections/eth/0n1Force.png',
      opensea: 'https://opensea.io/collection/0n1-force',
    },
    '0x7df64f69544c5bf71171dc5ab69b8602c2ff1e27': {
      collectionLogo: '/images/nftsCollections/eth/kitaroworldofficial.png',
      opensea: 'https://opensea.io/collection/kitaroworldofficial',
    },
    '0xfe8c6d19365453d26af321d0e8c910428c23873f': {
      collectionLogo: '/images/nftsCollections/eth/genesis-creepz.png',
      opensea: 'https://opensea.io/collection/genesis-creepz',
    },
    '0xe785e82358879f061bc3dcac6f0444462d4b5330': {
      collectionLogo: '/images/nftsCollections/eth/world-of-women-nft.png',
      opensea: 'https://opensea.io/collection/world-of-women-nft',
    },
    '0x123b30e25973fecd8354dd5f41cc45a3065ef88c': {
      collectionLogo: '/images/nftsCollections/eth/alienfrensnft.png',
      opensea: 'https://opensea.io/collection/alienfrensnft',
    },
  },
  4: undefined,
  10: undefined,
  56: undefined,
  66: undefined,
  100: undefined,
  137: {
    '0x24a11e702cd90f034ea44faf1e180c0c654ac5d9': {
      collectionLogo: '/images/nftsCollections/polygon/trump.png',
      opensea: 'https://opensea.io/collection/trump-digital-trading-cards',
    },
    '0x6c97ef47d60b35f858ad04c4b4f649747e00a417': {
      collectionLogo: '/images/nftsCollections/polygon/galxe-holiday-2022.png',
      opensea: 'https://opensea.io/collection/galxe-holiday-2022',
    },
    '0xdb46d1dc155634fbc732f92e853b10b288ad5a1d': {
      collectionLogo: '/images/nftsCollections/polygon/lens-protocol-profiles.png',
      opensea: 'https://opensea.io/collection/lens-protocol-profiles',
    },
    '0xfbe3ab0cbfbd17d06bdd73aa3f55aaf038720f59': {
      collectionLogo: '/images/nftsCollections/polygon/voxies.png',
      opensea: 'https://opensea.io/collection/voxies',
    },
    '0x9498274b8c82b4a3127d67839f2127f2ae9753f4': {
      collectionLogo: '/images/nftsCollections/polygon/polygonpunks.png',
      opensea: 'https://opensea.io/collection/polygonpunks',
    },
    '0xbac7e3182bb6691f180ef91f7ae4530abb3dc08d': {
      collectionLogo: '/images/nftsCollections/polygon/the-smurfs-society-access-crystals.png',
      opensea: 'https://opensea.io/collection/the-smurfs-society-access-crystals',
    },
    '0x8f8e18dbebb8ca4fc2bc7e3425fcdfd5264e33e8': {
      collectionLogo: '/images/nftsCollections/polygon/voxie-tactics-items.png',
      opensea: 'https://opensea.io/collection/voxie-tactics-items',
    },
    '0x9d77cb4d8371736e2a2b2bfaa677b7841cdc8fc1': {
      collectionLogo: '/images/nftsCollections/polygon/derace-horses.png',
      opensea: 'https://opensea.io/collection/derace-horses',
    },
    '0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d': {
      collectionLogo: '/images/nftsCollections/polygon/polygonapeyachtclub.png',
      opensea: 'https://opensea.io/collection/polygonapeyachtclub',
    },
    '0x098e1bc661398ed6858626ba5750e56ce03f9072': {
      collectionLogo: '/images/nftsCollections/polygon/cryptokhat.png',
      opensea: 'https://opensea.io/collection/cryptokhat',
    },
    '0xbebe08aaeb53b29ee3e8ea10bcb4fe64e7d3a536': {
      collectionLogo: '/images/nftsCollections/polygon/cryptus-x-crypton-2023.png',
      opensea: 'https://opensea.io/collection/cryptus-x-crypton-2023',
    },
    '0xce8a6e03e6996f259191a18c4e2aa398319b04e9': {
      collectionLogo: '/images/nftsCollections/polygon/wfwolfpack.png',
      opensea: 'https://opensea.io/collection/wfwolfpack',
    },
    '0x687e4d88c45eba1aefb0ace6d127d038e7235943': {
      collectionLogo: '/images/nftsCollections/polygon/piratenationpolygon.png',
      opensea: 'https://opensea.io/collection/piratenationpolygon',
    },
    '0x8a57d0cb88e5dea66383b64669aa98c1ab48f03e': {
      collectionLogo: '/images/nftsCollections/polygon/theredvillagechampions.png',
      opensea: 'https://opensea.io/collection/theredvillagechampions',
    },
    '0xef453154766505feb9dbf0a58e6990fd6eb66969': {
      collectionLogo: '/images/nftsCollections/polygon/mrcrypto-by-racksmafia.png',
      opensea: 'https://opensea.io/collection/mrcrypto-by-racksmafia',
    },
    '0x5492ef6aeeba1a3896357359ef039a8b11621b45': {
      collectionLogo: '/images/nftsCollections/polygon/chumbivalleyofficial.png',
      opensea: 'https://opensea.io/collection/chumbivalleyofficial',
    },
    '0x026224a2940bfe258d0dbe947919b62fe321f042': {
      collectionLogo: '/images/nftsCollections/polygon/lobsterdao.png',
      opensea: 'https://opensea.io/collection/lobsterdao',
    },
    '0x925f7eb0fe634702049a1344119d4046965b5c8c': {
      collectionLogo: '/images/nftsCollections/polygon/notbanksyechoes.png',
      opensea: 'https://opensea.io/collection/notbanksyechoes',
    },
    '0x9b091d2e0bb88ace4fe8f0fab87b93d8ba932ec4': {
      collectionLogo: '/images/nftsCollections/polygon/neotokyoOuterCitizens.png',
      opensea: 'https://opensea.io/collection/neotokyo-outer-citizens',
    },
  },
  288: undefined,
  1284: undefined,
  1285: undefined,
  42161: undefined,
  43114: undefined,
  1313161554: undefined,
};

export const getNftParams = (chainId: number, nftAddress: string) =>
  NFTS[chainId]?.[nftAddress] || NFTS[chainId]?.[nftAddress.toLowerCase()];
