import { coinmarketcapApi } from 'src/api/coinmarketcap';
import { TOKEN_ADDRESS_LENGTH } from 'src/constants/constants';
import { chainStore } from 'src/store/chainStore';
import { pricesStore } from 'src/store/pricesStore';
import { Token } from 'src/types/tokens';

export const getTokensFromCoinMarketCap = async () => {
  const chainId = chainStore.chainConfig.getValue()?.coinmarketcap?.categoryId;

  if (!chainId) return [];

  const tokens = (await coinmarketcapApi.getCategory(chainId)).data.data.coins.filter(
    (token) => token.platform && token.platform.token_address.length === TOKEN_ADDRESS_LENGTH,
  );

  const addresses: string[] = [];
  const prices: string[] = [];
  const formattedTokens: Token[] = [];

  tokens.forEach((token) => {
    addresses.push(token.platform.token_address.toLowerCase());
    prices.push(String(token.quote.USD.price));
    formattedTokens.push({
      name: token.name,
      address: token.platform.token_address.toLowerCase(),
      balance: '0',
      decimals: 0,
      symbol: token.symbol.toUpperCase(),
      type: 1 as 1,
    });
  });

  pricesStore.setTokensPrices(addresses, prices, 'coinmarketcap');

  return formattedTokens;
};
