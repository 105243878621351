import { getChainConfig } from 'src/store/chainStore';
import { erc20Store } from 'src/store/erc20Store';
import { erc721Store } from 'src/store/erc721Store';
import { Token } from 'src/types/tokens';

export const getTokenParamsFromStores = async (address: string, wallet: string | null) => {
  const collections = getChainConfig()?.contracts.nftsCollections;

  if (
    collections &&
    Object.values(collections)
      .map((el) => el.toLowerCase())
      .includes(address.toLowerCase())
  ) {
    return (await erc721Store.getNftParams(address.toLowerCase())) as Token;
  }

  return await erc20Store.getTokenParams(address.toLowerCase(), wallet);
};

export function assertFulfilled<T>(
  item: PromiseSettledResult<T>,
): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}
