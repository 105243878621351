import abi from 'src/contracts/abis/ERC721.json'
import web3Core from 'web3-core'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackErc721TokenApproval = ({ callback, owner, approved, tokenId, contract }: { callback: ([event]: [web3Core.Log]) => void, owner: string, approved: string, tokenId: string, contract: string }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'Approval',
    params: { owner, approved, tokenId, contract },
    topic: '0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925',
    namespace: 'erc721Token',
  })

export const trackErc721TokenApprovalForAll = ({ callback, owner, operator, contract }: { callback: ([event]: [web3Core.Log]) => void, owner: string, operator: string, contract: string }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'ApprovalForAll',
    params: { owner, operator, contract },
    topic: '0x17307eab39ab6107e8899845ad3d59bd9653f200f220920489ca2b5937696c31',
    namespace: 'erc721Token',
  })

export const trackErc721TokenTransfer = ({ callback, from, to, tokenId, contract }: { callback: ([event]: [web3Core.Log]) => void, from: string, to: string, tokenId: string, contract: string }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'Transfer',
    params: { from, to, tokenId, contract },
    topic: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
    namespace: 'erc721Token',
  })
