import abi from 'src/contracts/abis/ERC20.json'
import web3Core from 'web3-core'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackErc20TokenApproval = ({ callback, src, guy, contract }: { callback: ([event]: [web3Core.Log]) => void, src: string, guy: string, contract: string }, eventForAddress: string) =>
  eventTracker.trackEvent(
    {
      callback,
      contract,
      abi,
      name: 'Approval',
      params: { src, guy, contract },
      topic: '0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925',
      namespace: 'erc20Token',
    },
    eventForAddress,
  )

export const trackErc20TokenTransfer = ({ callback, src, dst, contract }: { callback: ([event]: [web3Core.Log]) => void, src: string, dst: string, contract: string }, eventForAddress: string) =>
  eventTracker.trackEvent(
    {
      callback,
      contract,
      abi,
      name: 'Transfer',
      params: { src, dst, contract },
      topic: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
      namespace: 'erc20Token',
    },
    eventForAddress,
  )

export const trackErc20TokenDeposit = ({ callback, dst, contract }: { callback: ([event]: [web3Core.Log]) => void, dst: string, contract: string }, eventForAddress: string) =>
  eventTracker.trackEvent(
    {
      callback,
      contract,
      abi,
      name: 'Deposit',
      params: { dst, contract },
      topic: '0xe1fffcc4923d04b559f4d29a8bfc6cda04eb5b0d3c460751c2402c5c5cc9109c',
      namespace: 'erc20Token',
    },
    eventForAddress,
  )

export const trackErc20TokenWithdrawal = ({ callback, src, contract }: { callback: ([event]: [web3Core.Log]) => void, src: string, contract: string }, eventForAddress: string) =>
  eventTracker.trackEvent(
    {
      callback,
      contract,
      abi,
      name: 'Withdrawal',
      params: { src, contract },
      topic: '0x7fcf532c15f0a6db0bd6d0e038bea71d30d808c7d98cb3bf7268a95bf5081b65',
      namespace: 'erc20Token',
    },
    eventForAddress,
  )
