import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { getParametersStorageCustomParams } from 'src/contracts/parametersstorage/contractFunctions';

const log = debug('store:auctionsParamsStore');

export const auctionsParamsStore = {
  duration: new BehaviorSubject('0'),

  async init() {
    log('init fired');
    const duration = parseInt(await this.fetchAuctionsDuration(), 16).toString();
    this.setAuctionDuration(duration);
  },

  fetchAuctionsDuration() {
    log('fetchAuctionsDuration fired');
    return getParametersStorageCustomParams(0);
  },

  setAuctionDuration(duration: string) {
    log('setAuctionDuration fired', duration);
    this.duration.next(duration);
  },
};
