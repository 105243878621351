import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider';
import * as ethers from 'ethers';
import abi from 'src/contracts/abis/ERC721.json';

import { getEthersWsProvider } from 'src/store/chainStore';

import { sendTransaction } from 'src/store/transactionsStore';

export function getErc721TokenContract(
  provider: ethers.providers.WebSocketProvider | JsonRpcSigner,
  contractAddress: string,
) {
  return new ethers.Contract(contractAddress as string, abi, provider);
}

export function submitErc721TokenApprove(
  contractAddress: string,
  to: any,
  tokenId: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
  const contract = getErc721TokenContract(signer, contractAddress);
  return sendTransaction(contract, 'approve', [to, tokenId, { ...options }]);
}

export function getErc721TokenBalanceOf(contractAddress: string, owner: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.balanceOf(owner).catch((e: any) => {
    console.error('Failed to call balanceOf in erc721Token:', e);
  });
}

export function getErc721TokenGetApproved(contractAddress: string, tokenId: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.getApproved(tokenId).catch((e: any) => {
    console.error('Failed to call getApproved in erc721Token:', e);
  });
}

export function getErc721TokenIsApprovedForAll(contractAddress: string, owner: any, operator: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.isApprovedForAll(owner, operator).catch((e: any) => {
    console.error('Failed to call isApprovedForAll in erc721Token:', e);
  });
}

export function getErc721TokenName(contractAddress: string) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.name().catch((e: any) => {
    console.error('Failed to call name in erc721Token:', e);
  });
}

export function getErc721TokenOwnerOf(contractAddress: string, tokenId: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.ownerOf(tokenId).catch((e: any) => {
    console.error('Failed to call ownerOf in erc721Token:', e);
  });
}

export function submitErc721TokenSafeTransferFrom(
  contractAddress: string,
  from: any,
  to: any,
  tokenId: any,
  data: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
  const contract = getErc721TokenContract(signer, contractAddress);
  return sendTransaction(contract, 'safeTransferFrom', [from, to, tokenId, data, { ...options }]);
}

export function submitErc721TokenSetApprovalForAll(
  contractAddress: string,
  operator: any,
  _approved: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
  const contract = getErc721TokenContract(signer, contractAddress);
  return sendTransaction(contract, 'setApprovalForAll', [operator, _approved, { ...options }]);
}

export function getErc721TokenSupportsInterface(contractAddress: string, interfaceId: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.supportsInterface(interfaceId).catch((e: any) => {
    console.error('Failed to call supportsInterface in erc721Token:', e);
  });
}

export function getErc721TokenSymbol(contractAddress: string) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.symbol().catch((e: any) => {
    console.error('Failed to call symbol in erc721Token:', e);
  });
}

export function getErc721TokenTokenByIndex(contractAddress: string, index: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.tokenByIndex(index).catch((e: any) => {
    console.error('Failed to call tokenByIndex in erc721Token:', e);
  });
}

export function getErc721TokenTokenOfOwnerByIndex(contractAddress: string, owner: any, index: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.tokenOfOwnerByIndex(owner, index).catch((e: any) => {
    console.error('Failed to call tokenOfOwnerByIndex in erc721Token:', e);
  });
}

export function getErc721TokenTokensOfOwner(contractAddress: string, owner: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.tokensOfOwner(owner).catch((e: any) => {
    console.error('Failed to call tokensOfOwner in erc721Token:', e);
  });
}

export function getErc721TokenTokenURI(contractAddress: string, tokenId: any) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.tokenURI(tokenId).catch((e: any) => {
    console.error('Failed to call tokenURI in erc721Token:', e);
  });
}

export function getErc721TokenTotalSupply(contractAddress: string) {
  const contract = getErc721TokenContract(getEthersWsProvider(), contractAddress);
  return contract.totalSupply().catch((e: any) => {
    console.error('Failed to call totalSupply in erc721Token:', e);
  });
}

export function submitErc721TokenTransferFrom(
  contractAddress: string,
  from: any,
  to: any,
  tokenId: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
  const contract = getErc721TokenContract(signer, contractAddress);
  return sendTransaction(contract, 'transferFrom', [from, to, tokenId, { ...options }]);
}
