import debug from 'debug';
import { FOO_WALLET } from 'src/constants/constants';
import { getAssetViewerCheckAssets } from 'src/contracts/assetviewer/contractFunctions';
import { getChainConfig } from 'src/store/chainStore';
import { erc20Store } from 'src/store/erc20Store';
import { erc721Store } from 'src/store/erc721Store';
import { Token, TokenPropsFromChain } from 'src/types/tokens';

export function formatTokenParams(props: TokenPropsFromChain, walletAddress: string | null) {
  return {
    address: props[0].toLowerCase(),
    type: props[1],
    decimals: props[2],
    name: props[3],
    symbol: props[4],
    balance: walletAddress ? props[5].toString() : '0',
  };
}

export function getTokenPriceSourceParams(
  source: Token['source'],
  tokenName: string,
  tokenAddress: string,
) {
  switch (source) {
    case 'coingecko':
      return {
        link: `https://www.coingecko.com/en/coins/${tokenName.toLowerCase().split(' ').join('-')}`,
        sourceName: 'CoinGecko',
      };
    case 'coinmarketcap':
      return {
        link: `https://coinmarketcap.com/currencies/${tokenName
          .toLowerCase()
          .split(' ')
          .join('-')}`,
        sourceName: 'CoinMarketCap',
      };
    case 'bobhub':
      return {
        link: `${getChainConfig()?.explorer}/address/${getChainConfig()?.contracts.bobHubOracle}`,
        sourceName: 'BobHub',
      };
    case 'zapper':
      return {
        link: `https://zapper.fi/token/${getChainConfig()?.zapper?.network}/${tokenAddress}`,
        sourceName: 'Zapper',
      };
    default:
      return {
        link: '#',
        sourceName: '',
      };
  }
}
let iteration = 0;

export const fetchTokensParams = async (
  addresses: string[],
  wallet: string | null,
  forNewBalances = false,
) => {
  const log = debug(`utils:fetchTokensParams_${iteration}`);

  iteration = iteration + 1;

  log('fired with params', { addresses, wallet });

  const erc20Tokens: Token[] = [];
  const erc721Tokens: Token[] = [];

  try {
    if (!forNewBalances) {
      addresses = addresses
        .map((address) => {
          const erc721Token = erc721Store.nfts.getValue().get(address);
          if (erc721Token) {
            erc721Tokens.push(erc721Token);
            return null;
          }
          const erc20Token = erc20Store.tokens.getValue().get(address);
          if (erc20Token) {
            erc20Tokens.push(erc20Token);
            return null;
          }
          return address.toLowerCase();
        })
        .filter(Boolean);
    }

    const tokensParamsFromChain = (await getAssetViewerCheckAssets(
      wallet || FOO_WALLET,
      addresses,
    )) as TokenPropsFromChain[];

    const tokensParamsFormatted = tokensParamsFromChain.map((token) =>
      formatTokenParams(token, wallet),
    );

    log('tokensParamsFormatted: ', tokensParamsFormatted);

    tokensParamsFormatted.forEach((token) => {
      if (token.type === 1) erc20Tokens.push(token);
      if (token.type === 2) erc721Tokens.push(token);
    });
  } catch (e) {
    console.log(e);
  }

  log('result', {
    erc20Tokens,
    erc721Tokens,
  });

  return {
    erc20Tokens,
    erc721Tokens,
  };
};
