import { ChainConfig, ChainId, NETWORK_LIST, urlRepresentationsList } from 'src/constants/chain';

export const getChainConfigById = (chainId: ChainId) =>
  NETWORK_LIST.find((chain) => chain.id === chainId) as ChainConfig;

export const isChainSupported = (chainId: null | number): chainId is ChainId =>
  NETWORK_LIST.some((chain) => chain.id === chainId);

export const getChainConfigByUrlRepresentation = (urlRepresentation: string) =>
  NETWORK_LIST.find((chain) => chain.urlRepresentation === urlRepresentation) ||
  getChainConfigById(1);

export const getChainRepresentationFromUrl = () => {
  const { pathname } = window.location;
  const chainRepresentationFromUrl = pathname.split('/')[1];
  if (!urlRepresentationsList.includes(chainRepresentationFromUrl)) {
    return 'eth';
  }
  return chainRepresentationFromUrl;
};

export const checkWeb3Network = (
  userAddress: string | null,
  chainId: number,
  chainConfig: ChainConfig | null,
) => ({
  isConnected: Boolean(userAddress),
  isSupported: isChainSupported(chainId),
  networksMatch: chainConfig?.id === chainId,
});
