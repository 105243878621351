export type EventEmitterListener<T> = (val: T) => unknown

export class EventEmitter<T> {
  public listeners: EventEmitterListener<T>[] = []

  listen = (listener: EventEmitterListener<T>): (() => void) => {
    this.listeners.push(listener)
    return () => this.unlisten(listener)
  }

  emit = (val: T) => {
    this.listeners.forEach((l) => l(val))
  }

  unlisten = (listener: EventEmitterListener<T>) => {
    this.listeners = this.listeners.filter((l) => l !== listener)
  }
}
