import { borderRadius, breakpoint, color } from 'src/uiKit/theme';
import styled from 'styled-components';

export const StatusContainer = styled.div`
  position: fixed;
  top: 3rem;
  right: 1.2rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 2rem;
  align-items: center;
  padding: 1rem 3rem;
  margin-right: 1.8rem;
  width: max-content;
  color: ${color.black};
  font-size: 2rem;
  background: ${color.white};
  border-radius: ${borderRadius.normal};
  z-index: 1;
  @media screen and (min-width: ${breakpoint.desktop}) {
    position: relative;
    top: 0;
    right: 0;
  }
`;
