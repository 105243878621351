import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'src/utils/helpers/isMobile';
import {
  FiltersContainer,
  FiltersTitle,
} from 'src/components/LeftSideMenu/components/Filter/styled';
import { MenuItem } from './styled';

export type MenuLink = {
  id: string;
  name: string;
  to: string;
};

export const MenuSection: FC<{
  title?: string;
  links: MenuLink[];
}> = ({ title, links }) => {
  const location = useLocation();

  return (
    <FiltersContainer static>
      {title && !isMobile() && <FiltersTitle>{title}</FiltersTitle>}
      {links.map((link) => (
        <MenuItem key={link.id} isActive={location.pathname === link.to} to={link.to}>
          {link.name}
        </MenuItem>
      ))}
    </FiltersContainer>
  );
};
