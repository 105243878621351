import axios, { AxiosResponse } from 'axios';
import { LooksRareCollectionStatsResp } from 'src/api/looksRare/types';
import { assertFulfilled } from 'src/utils/diff';

async function getCollectionsParams(addresses: string[]) {
  return (
    await Promise.allSettled(
      addresses.map((address) =>
        axios.get<AxiosResponse<LooksRareCollectionStatsResp>>(
          'https://api.looksrare.org/api/v1/collections/stats',
          {
            params: {
              address,
            },
          },
        ),
      ),
    )
  )
    .filter(assertFulfilled)
    .map((el) => el.value.data.data);
}

export const looksRareApi = { getCollectionsParams };
