import { useLocation } from 'react-router-dom';
import { AppType } from 'src/constants/appType';

export const useAppType = () => {
  const location = useLocation();
  const [,, initialAppType] = location.pathname.split('/');
  
  const supportedApps = Object.values(AppType);
  const isInitialAppTypeValid = supportedApps.includes(initialAppType as AppType);
  
  return (isInitialAppTypeValid ? initialAppType : null) as AppType;
}