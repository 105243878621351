import React, { useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { openSeaApi } from 'src/api/opensea';
import { WithLeftSideMenu } from 'src/components/LeftSideMenu/withLeftSideMenu';
import { Modals } from 'src/components/Modals/Modals';
import { Notification } from 'src/components/Notification';
import { IS_DEV } from 'src/constants/constants';
import { useAppType } from 'src/hooks/useAppType';
import { useSubject } from 'src/hooks/useSubject';
import { RemoveTrailingSlash } from 'src/plugins/RemoveTrailingSlash';
import { auctionsStore } from 'src/store/auctionsStore';
import { chainStore } from 'src/store/chainStore';
import { erc20Store } from 'src/store/erc20Store';
import { loansStore } from 'src/store/loansStore';
import { walletStore } from 'src/store/walletStore';
import { GlobalStyles } from 'src/uiKit/GlobalStyles';
import { color } from 'src/uiKit/theme';
import { ChainUrlRepresentation } from 'src/views/chain-url-representation';
import { ThemeProvider } from 'styled-components';
import { ECOSYSTEM } from './constants/appType';

const Erc20Index = React.lazy(() =>
  import('src/views/erc-20').then((module) => ({ default: module.Erc20Index })),
);

const Erc20AllAuctions = React.lazy(() =>
  import('src/views/erc-20/all-auctions').then((module) => ({ default: module.Erc20AllAuctions })),
);

const Erc20Borrow = React.lazy(() =>
  import('src/views/erc-20/borrow').then((module) => ({ default: module.Erc20Borrow })),
);

const KnowledgeCenter = React.lazy(() =>
  import('src/views/knowledge-center').then((module) => ({
    default: module.KnowledgeCenter,
  })),
);

const BorrowTokenAddress = React.lazy(() =>
  import('src/views/erc-20/borrow/borrow-token-address').then((module) => ({
    default: module.BorrowTokenAddress,
  })),
);

const LendLendId = React.lazy(() =>
  import('src/views/erc-20/lend/lend-id').then((module) => ({ default: module.LendLendId })),
);

const Erc721Index = React.lazy(() =>
  import('src/views/erc-721').then((module) => ({ default: module.Erc721Index })),
);

const Erc721Borrow = React.lazy(() =>
  import('src/views/erc-721/borrow').then((module) => ({ default: module.Erc721Borrow })),
);

const Erc721Lend = React.lazy(() =>
  import('src/views/erc-721/lend').then((module) => ({ default: module.Erc721Lend })),
);

const Erc721 = React.lazy(() =>
  import('src/views/erc721').then((module) => ({ default: module.Erc721 })),
);

const BorrowNftCollectionAddress = React.lazy(() =>
  import('src/views/erc-721/borrow/nft-collection-address').then((module) => ({
    default: module.BorrowNftCollectionAddress,
  })),
);

const YourNfts = React.lazy(() =>
  import('src/views/erc-721/your-nfts').then((module) => ({
    default: module.YourNfts,
  })),
);

const AllAuctions721 = React.lazy(() =>
  import('src/views/erc-721/all-auctions').then((module) => ({
    default: module.AllAuctions,
  })),
);

const YourLoans = React.lazy(() =>
  import('src/views/erc-20/your-loans').then((module) => ({
    default: module.YourLoans20,
  })),
);

const erc20Theme = {
  mainBlockBg: '#fff',
  mainBlockBorder: 'none',
  auctionItemHoverBg: color.grayText,
  auctionItemHoverColor: color.white,
  chainSelectorColor: color.white,
};

const erc721Theme = {
  mainBlockBg: '#fff',
  mainBlockBorder: '0.1rem solid rgba(0,0,0,0.1)',
  auctionItemHoverBg: 'rgba(0,0,0,.1)',
  auctionItemHoverColor: 'inherit',
  chainSelectorColor: color.grayBg,
};

export const App = () => {
  const walletAddress = useSubject(walletStore.address);
  const chainId = useSubject(chainStore.appChainId);
  const appType = useAppType();
  const location = useLocation();

  useEffect(() => {
    if (!walletAddress) {
      erc20Store.clearBalances();
      return;
    } else {
      erc20Store.getWalletTokens(walletAddress);
      erc20Store.refreshAllBalances(walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (!walletAddress || !chainId) return;
    loansStore.fetchUserLoans(walletAddress, chainId);
  }, [walletAddress, chainId]);

  useEffect(() => {
    auctionsStore.fetchAuctions();
  }, [chainId]);

  useEffect(() => {
    if (!walletAddress) return;
    openSeaApi.getWalletNfts(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    if (!IS_DEV && !appType) {
      window.location.replace(ECOSYSTEM);
    }
  }, [appType]);

  return (
    <ThemeProvider
      theme={
        appType === 'erc-20' || location.pathname.includes('knowledge-center')
          ? erc20Theme
          : erc721Theme
      }
    >
      <GlobalStyles />
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/:chainUrlRepresentation" element={<ChainUrlRepresentation />}>
          <Route path="erc-20">
            <Route index element={<Erc20Index />} />
            <Route path="knowledge-center" element={<KnowledgeCenter />} />
            <Route path="all-offers" element={<Erc20AllAuctions />} />
            <Route path="my-positions">
              <Route
                path="live-offers"
                element={
                  <WithLeftSideMenu withFilters>
                    <YourLoans loanGroup={'all'} />
                  </WithLeftSideMenu>
                }
              />
              <Route
                path="borrowing"
                element={
                  <WithLeftSideMenu withFilters>
                    <YourLoans loanGroup={'borrowing'} />
                  </WithLeftSideMenu>
                }
              />
              <Route
                path="lending"
                element={
                  <WithLeftSideMenu withFilters>
                    <YourLoans loanGroup={'lending'} />
                  </WithLeftSideMenu>
                }
              />
            </Route>
            <Route path="borrow">
              <Route index element={<Erc20Borrow />} />
              <Route element={<BorrowTokenAddress />} path=":tokenAddress" />
            </Route>
            <Route path="lend/:auctionId">
              <Route index element={<LendLendId />} />
            </Route>
          </Route>
          <Route path="erc-721" element={<Erc721 />}>
            <Route index element={<Erc721Index />} />
            <Route path="knowledge-center" element={<KnowledgeCenter />} />
            <Route
              path="all-offers"
              element={
                <WithLeftSideMenu withFilters>
                  <AllAuctions721 />
                </WithLeftSideMenu>
              }
            />
            <Route path="my-positions">
              <Route
                path="live-offers"
                element={
                  <WithLeftSideMenu withFilters>
                    <YourLoans nftsOnly loanGroup={'all'} />
                  </WithLeftSideMenu>
                }
              />
              <Route
                path="borrowing"
                element={
                  <WithLeftSideMenu>
                    <YourLoans nftsOnly loanGroup={'borrowing'} />
                  </WithLeftSideMenu>
                }
              />
              <Route
                path="lending"
                element={
                  <WithLeftSideMenu withFilters>
                    <YourLoans nftsOnly loanGroup={'lending'} />
                  </WithLeftSideMenu>
                }
              />
              <Route
                path="my-nfts"
                element={
                  <WithLeftSideMenu>
                    <YourNfts />
                  </WithLeftSideMenu>
                }
              />
            </Route>
            <Route path="borrow">
              <Route
                index
                element={
                  <WithLeftSideMenu>
                    <Erc721Borrow />
                  </WithLeftSideMenu>
                }
              />
              <Route path=":tokenAddress">
                <Route
                  index
                  element={
                    <WithLeftSideMenu>
                      <BorrowNftCollectionAddress />
                    </WithLeftSideMenu>
                  }
                />
                <Route path=":nftId" element={<BorrowTokenAddress />} />
              </Route>
            </Route>
            <Route path="lend/:auctionId">
              <Route
                index
                element={
                  <WithLeftSideMenu>
                    <Erc721Lend />
                  </WithLeftSideMenu>
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
      <Modals />
      <Notification />
    </ThemeProvider>
  );
};
