import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';
import { useAppType } from 'src/hooks/useAppType';
import { useCurrentChainsList } from 'src/hooks/useCurrentChainsList';
import { useSubject } from 'src/hooks/useSubject';
import { chainStore } from 'src/store/chainStore';
import { erc20Store } from 'src/store/erc20Store';
import { walletStore } from 'src/store/walletStore';
import { breakpoint } from 'src/uiKit/theme';
import { getChainConfigByUrlRepresentation } from 'src/utils/chain';
import styled from 'styled-components';

export const Container = styled.div<{ large?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  max-width: ${(p) => (p.large ? '100%' : '190rem')};
  margin: 0 auto;
  min-width: 280px;
  width: 100%;
  height: 100%;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding: ${(p) => (p.large ? '0 3.5em' : '0 0.5rem')};
  }
`;

export const ChainUrlRepresentation = () => {
  const walletAddress = useSubject(walletStore.address);
  const chainId = useSubject(chainStore.appChainId);
  const appType = useAppType();
  const { chainUrlRepresentation } = useParams();
  const chainList = useCurrentChainsList();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // store subscriptions

  useEffect(() => {
    if (pathname === '/') return;

    if (!chainList.find((chain) => pathname.includes(`/${chain.urlRepresentation}/`))) {
      navigate(`/${chainList[0].urlRepresentation}/${appType}`);
      return;
    }

    erc20Store.getTokensList(walletAddress, chainId);

    console.log('dksjfkdjf');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, chainId, chainList]);

  useEffect(() => {
    const tokenType = pathname.indexOf('erc-20') + 1 ? 'erc-20' : 'erc-721';
    if (pathname === `/${tokenType}`) {
      navigate(`/eth/${tokenType}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const chainConfig = getChainConfigByUrlRepresentation(chainUrlRepresentation || 'eth');
    chainStore.setAppChainId(chainConfig.id);
  }, [chainUrlRepresentation]);

  return (
    <Container>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
};
