export const color = {
  pink: 'rgba(235, 82, 161, 1)',
  purple: '#D88BF9',
  litePurple: '#DB98F8',
  gray: 'rgba(191, 191, 191, 1)',
  liteGray: '#f7f7f7',
  grayText: '#929292',
  grayBg: '#f0f0f0',
  darkGray: '#d6d6d6',
  black: 'rgba(37, 37, 37, 1)',
  white: '#fff',
  border: {
    gray: 'rgba(37, 37, 37, 0.2)',
  },
  green: 'rgba(18, 227, 73, 1)',
  CDPDots: {
    opened: 'rgba(235, 82, 161, 1)',
    withBalance: '#D88BF9',
  },
};

export const borderRadius = {
  normal: '1.9rem',
  middle: '2.5rem',
  large: '6.2rem',
  round: '50%',
};

export const breakpoint = {
  phone: '500px',
  tablet: '768px',
  desktop: '1170px',
  hd: '1440px',
  fullHd: '1920px',
};

export const fontFamily = {
  inter: `font-family: 'Circe Regular';`,
  maru: `font-family: 'GT Maru Black';`,
};

export const base = {
  base1: '0.5rem',
  base2: '1rem',
  base3: '1.5rem',
  base4: '2rem',
  base5: '2.5rem',
  base6: '3rem',
  base7: '3.5rem',
  base8: '4rem',
};
