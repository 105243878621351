import { BigNumber } from 'bignumber.js';
import { BN } from 'src/utils/bigNumber';

export const safeJsonParse = <T>(maybeJson?: string | any): T | undefined => {
  try {
    if (!maybeJson) return;
    return JSON.parse(maybeJson);
  } catch (err) {
    console.error('Failed to parse JSON', maybeJson, err);
  }
};

export const parseBool = (x?: string | null) => Boolean(safeJsonParse(x));

/** Converts percentages to basis points. */
export const toBp = (percentage: BigNumber.Value) => BN(percentage).times(100).toString();

/** Converts basis points to percentages */
export const fromBpToPercent = (bp: BigNumber.Value) => BN(bp).div(100).toString();
