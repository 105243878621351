import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'src/App';
import 'src/index.css';
import { ModalsProvider } from 'src/providers/ModalsProvider';
import { Web3CtxProvider } from 'src/providers/Web3CtxProvider';
import { initStoresSub } from 'src/store/subscriptions';

initStoresSub();

const app = (
  <StrictMode>
    <ModalsProvider>
      <BrowserRouter>
        <Web3CtxProvider>
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </Web3CtxProvider>
      </BrowserRouter>
    </ModalsProvider>
  </StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
