import { base, breakpoint } from 'src/uiKit/theme';
import styled from 'styled-components';

export const LeftSideMenuContainer = styled.div`
  grid-gap: 1rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  top: -17rem;
  display: none;
  @media screen and (min-width: ${breakpoint.phone}) {
    display: grid;
  }
  @media screen and (min-width: ${breakpoint.tablet}) {
    top: -25rem;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    display: block;
    margin-left: auto;
    width: 48rem;
    padding-right: ${base.base6};
    height: max-content;
    position: sticky;
    top: ${base.base4};
  }
`;
