import { color } from 'src/uiKit/theme';
import styled from 'styled-components';

export const ClipIcon = styled.img`
  height: 2rem;
  padding-left: 0.6rem;
`;

export const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: ${color.black};
`;
