import { BigNumber } from 'bignumber.js';

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: 1e9,
  DECIMAL_PLACES: 18,
});

export const BN = (num: BigNumber.Value): BigNumber => new BigNumber(num);

export function getDisplayAmount(value: string, decimals = 18, round = 4, cut = true) {
  const getFirstTwoNonZeroValues = /^-?\d*\.?0*\d0*\d/;
  const findAllAfterDot = /\.([^.]+)$/;
  const res = BN(value).div(BN(10).pow(decimals));
  if (!cut) return res.toString();
  if (BN(res).lt(0.01)) return (res.toString().match(getFirstTwoNonZeroValues) || ['0'])[0];
  if (BN(res.toString().match(findAllAfterDot)?.[0] || 0).eq(0)) return res.toFixed(0);
  if (res.toString().includes('.')) {
    return res.toString().substring(0, res.toString().indexOf('.') + 5);
  }
  return res.toFixed(round);
}

export function getDisplayPrice(
  amount: string | undefined | null,
  precision: null | number = 2,
): string {
  if (!amount) return '-';
  const val = BN(amount);

  if (!precision) return val.valueOf();

  if (val.isInteger()) return val.toFixed(precision);

  const count = val
    .toString()
    .replace(/\d+(?=.)\./, '')
    .search(/[1-9]/);
  let formattedVal = val.toFixed(count + precision);

  if (formattedVal.match(/\./)) {
    return formattedVal.replace(/\.?0+$/, '');
  }
  return formattedVal;
}

export function getAtomicAmount(value: string, decimals = 18) {
  return BN(value).times(BN(10).pow(decimals)).toFixed(0);
}

export function formatNumber(x: BigNumber.Value, precision = 2): string {
  if (BN(x).gte(1_000_000)) {
    return `${BN(x).div(1_000_000).toFixed(precision)}M`;
  }
  if (x >= 1_000) {
    return `${BN(x).div(1_000).toFixed(precision)}K`;
  }
  return BN(x).toFixed(precision);
}

export const safeBN = (n: string) => {
  try {
    return BN(n);
  } catch (err) {
    return null;
  }
};
