import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider';
import debug from 'debug';
import { BehaviorSubject } from 'rxjs';

const log = debug('store:walletStore');

export const walletStore = {
  address: new BehaviorSubject<string | null>(null),
  prevAddress: new BehaviorSubject<string | null>(null),
  signer: new BehaviorSubject<JsonRpcSigner | null>(null),
  chainId: new BehaviorSubject<number>(1),
  isConnecting: new BehaviorSubject(false),

  clearAll() {
    log('clearAll fired');
    this.setWallet(null);
    this.setSigner(null);
  },

  setWallet(walletAddress: string | null) {
    log('setWallet fired with params: ', walletAddress);
    this.prevAddress.next(this.address.getValue());
    this.address.next(walletAddress);
  },

  setSigner(signer: JsonRpcSigner | null) {
    log('setSigner fired with params: ', signer);
    this.signer.next(signer);
  },

  getSigner() {
    return this.signer.getValue();
  },

  setChainId(chainId: number) {
    log('setChainId fired with params: ', chainId);
    this.chainId.next(chainId);
  },
};
