import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CloseModal } from 'src/assets/images/icons/ic-close-modal';
import { SocialLinks } from 'src/components/Header/SocialLinks';
import { AppType, ECOSYSTEM } from 'src/constants/appType';
import { ChainSelector } from '../ChainSelector';
import menuNavigation from './menuNavigation.json';
import { CloseButton, MenuContainer, MenuItem, MobileButtonsContainer, StyledList } from './styled';

export const MenuNavigation: FC<{
  appType: AppType;
  userAddress: string | null;
  onCloseMenu: () => void;
}> = ({ appType, userAddress, onCloseMenu }) => {
  const { chainUrlRepresentation } = useParams();
  const menu = appType === AppType.erc721 ? menuNavigation.erc721 : menuNavigation.erc20;

  return (
    <MenuContainer>
      <MobileButtonsContainer>
        <ChainSelector />
        <CloseButton onClick={onCloseMenu}>{CloseModal}</CloseButton>
      </MobileButtonsContainer>
      {/*<ButtonsWrap>*/}
      {/*  {appType !== AppType.erc20 && (*/}
      {/*    <StyledLink to={`/eth/${AppType.erc20}`}>*/}
      {/*      <UIButton>ERC20</UIButton>*/}
      {/*    </StyledLink>*/}
      {/*  )}*/}
      {/*  {appType !== AppType.erc721 && (*/}
      {/*    <StyledLink to={`/eth/${AppType.erc721}`}>*/}
      {/*      <UIButton>NFTS</UIButton>*/}
      {/*    </StyledLink>*/}
      {/*  )}*/}
      {/*  <StyledLink to={'/eth/erc-721-bonds'} disabled>*/}
      {/*    <UIButton>*/}
      {/*      NFT Bonds <span>coming soon</span>*/}
      {/*    </UIButton>*/}
      {/*  </StyledLink>*/}
      {/*</ButtonsWrap>*/}
      <StyledList>
        <a href={ECOSYSTEM}>
          <MenuItem>Ecosystem</MenuItem>
        </a>
      </StyledList>
      {userAddress && (
        <StyledList>
          {menu.myPositions.map((el) => (
            <Link to={`/${chainUrlRepresentation}/${appType}${el.link}`}>
              <MenuItem key={el.name}>{el.name}</MenuItem>
            </Link>
          ))}
        </StyledList>
      )}
      <StyledList>
        {menu.mainNav.map((el) => (
          <Link to={`/${chainUrlRepresentation}/${appType}${el.link}`}>
            <MenuItem key={el.name}>{el.name}</MenuItem>
          </Link>
        ))}
      </StyledList>
      <StyledList>
        <Link to={`/${chainUrlRepresentation}/${appType}/knowledge-center`}>
          <MenuItem>Knowledge center</MenuItem>
        </Link>
        <SocialLinks />
      </StyledList>
    </MenuContainer>
  );
};
