import * as ethers from 'ethers'
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'

import { getChainConfig, getEthersWsProvider } from 'src/store/chainStore'

    import abi from 'src/contracts/abis/assetViewer.json'

export function getAssetViewerContract(provider: ethers.providers.WebSocketProvider | JsonRpcSigner) {
  return new ethers.Contract(getChainConfig()?.contracts.assetViewer as string, abi, provider)
}

export function getAssetViewerVERSION() {
              const contract = getAssetViewerContract(getEthersWsProvider())
                return contract.VERSION().catch((e: any) => {
    console.error('Failed to call VERSION in assetViewer:', e)
  })
            }
    
export function getAssetViewerCheckAssets(_user: any, _assets: any) {
              const contract = getAssetViewerContract(getEthersWsProvider())
                return contract.checkAssets(
    _user,
    _assets
  ).catch((e: any) => {
    console.error('Failed to call checkAssets in assetViewer:', e)
  })
            }
    
