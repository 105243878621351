import styled from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../uiKit/theme'

export const StyledNetworkAttention = styled.div`
  position: fixed;
  z-index: 1000;
  background: ${color.black};
  color: #fff;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2.5rem;
  ${fontFamily.maru};
  padding: 1em 1.4em;
  ${(p) => p.onClick && 'cursor: pointer;'}
  border-radius: ${borderRadius.normal};
  width: calc(100% - 5rem);
  text-align: center;
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: auto;
  }
`
