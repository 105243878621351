import { Link } from 'react-router-dom';
import { base, borderRadius, breakpoint, color, fontFamily } from 'src/uiKit/theme';
import styled from 'styled-components';
import { ChainSelectorContainer } from './ChainSelector/styled';

export const HeaderMenuBtn = styled.div`
  position: relative;
  background: center / 4rem no-repeat url('/images/icons/ic-header-info.svg'), ${color.grayBg};
  width: 8rem;
  border-radius: ${borderRadius.normal};
  cursor: pointer;
  margin-left: auto;
  min-height: 6rem;

  :hover {
    background-image: url('/images/icons/ic-header-info-white.svg');
    background-color: ${color.black};
  }

  @media screen and (min-width: ${breakpoint.tablet}) {
    order: initial;
    margin-right: 0;
  }
`;

export const HeaderBackBtn = styled.img`
  width: 9rem;
  display: block;
  background: ${color.grayBg};
  margin-right: 2rem;
  border-radius: ${borderRadius.normal};
  cursor: pointer;

  @media screen and (min-width: ${breakpoint.phone}) {
    width: 10rem;
  }
`;

export const HeaderBtnsContainer = styled.div`
  display: flex;
  align-items: stretch;
  margin-left: auto;
  ${ChainSelectorContainer} {
    display: none;
  }
  @media screen and (min-width: ${breakpoint.tablet}) {
    ${ChainSelectorContainer} {
      display: block;
    }
  }
`;

export const HeaderConnectBtn = styled.button`
  display: block;
  background: ${(p) => p.theme.chainSelectorColor};
  color: ${color.black};
  padding: 1.2rem 2rem;
  box-shadow: none;
  border: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    margin-right: 1.8rem;
  }
`;

export const HeaderShowAllOrders = styled(HeaderConnectBtn)`
  display: none;
  background: ${(p) => p.theme.chainSelectorColor};
  a {
    text-decoration: none;
    color: inherit;
  }
  @media (min-width: ${breakpoint.desktop}) {
    display: block;
  }
`;

export const HeaderWalletAddress = styled.button`
  display: block;
  background: ${color.grayBg};
  padding: 1.2rem 2rem;
  box-shadow: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;
  border: 0.1em solid rgba(37, 37, 37, 0.1);
  color: #4e4e4e;
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 2rem;
    margin-right: 1.8rem;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  padding-bottom: 4rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding-bottom: 5rem;
  }
`;

export const UiHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
`;

export const Logo = styled(Link)`
  font-size: ${base.base7};
  font-weight: bold;
  line-height: 0.8em;
  text-decoration: none;
  color: inherit;
  ${fontFamily.maru};
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 8.5rem;
  }
`;
