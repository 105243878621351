import React, { FC, useContext } from 'react';
import { Filter } from 'src/components/LeftSideMenu/components/Filter';
import { FilterContext, FiltersType } from 'src/context/FilterContext';
import { useSubject } from 'src/hooks/useSubject';
import { walletStore } from 'src/store/walletStore';
import { AllPositions } from './components/AllPositions';
import { MyPositions } from './components/MyPositions';
import { LeftSideMenuContainer } from './styled';

export const LeftSideMenu: FC<{ showFilters?: boolean }> = ({ showFilters }) => {
  const { setFilters, filters } = useContext(FilterContext);
  const walletAddress = useSubject(walletStore.address);

  function onFilterChange<T extends keyof FiltersType>(id: T, value: FiltersType[T][0]) {
    const newValues = [...filters[id]].includes(value)
      ? [...filters[id]].filter((f) => f !== value)
      : [...filters[id], value];

    setFilters({ ...filters, [id]: newValues });
    sessionStorage.setItem(id, newValues.toString());
  }

  return (
    <LeftSideMenuContainer>
      {walletAddress && <MyPositions />}
      <AllPositions />
      {showFilters && <Filter onFilterChange={onFilterChange} />}
    </LeftSideMenuContainer>
  );
};
