import React, { createContext, Dispatch, useContext, useState } from 'react';
import { FCC } from 'src/types/fcc';

export type ModalSettings =
  | { key: 'connect_wallet' }
  | { key: 'transaction'; txHash?: string; message?: string; txError?: string }
  | {
      key: 'confirmation';
      message: string;
      onAccept: () => void;
      onCancel: () => void;
    };

type ModalState = null | ModalSettings;

const initialModalState: ModalState = null;

const ModalStx = createContext<[ModalState, Dispatch<React.SetStateAction<ModalState>>]>([
  initialModalState,
  () => {},
]);

export const ModalsProvider: FCC = ({ children }) => {
  const value = useState<ModalState>(initialModalState);

  return <ModalStx.Provider value={value}>{children}</ModalStx.Provider>;
};

export const useModal = () => useContext(ModalStx)[0];

export const useSetModal = () => useContext(ModalStx)[1];
