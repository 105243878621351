import debug from 'debug';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { appType } from 'src/constants/appType';
import { useRevision } from 'src/hooks/useRevision';

import { getWeb3Controller, Web3Controller, Web3ControllerState } from 'src/utils/Web3Controller';
import Web3Modal from 'web3modal';
import { FCC } from '../types/fcc';

const log = debug('components:Web3CtxProvider');

export const web3Controller = getWeb3Controller(
  {
    walletProvider: null,
    userAddress: null,
    version: 0,
    isConnecting: false,
    chainId: null,
  },
  new Web3Modal({
    cacheProvider: true,
    providerOptions: {} as any,
  }),
);

web3Controller.init(appType);

const Web3Ctx = createContext<{ web3Controller: Web3Controller }>({ web3Controller });

export const Web3CtxProvider: FCC = ({ children }) => {
  const [rev, rerender] = useRevision(web3Controller.getState().version);

  useEffect(() => web3Controller.onUpdate(rerender), []);

  const controllerApi = useMemo(() => {
    log(`useMemo: update ctx value (rev = ${rev})`, web3Controller.getState());
    return { web3Controller };
  }, [rev]);

  return <Web3Ctx.Provider value={controllerApi}>{children}</Web3Ctx.Provider>;
};

export const useWeb3Controller = () => useContext(Web3Ctx).web3Controller;

export const useWeb3State = (): Web3ControllerState =>
  useContext(Web3Ctx).web3Controller.getState();
