import { base, borderRadius, breakpoint, color, fontFamily } from 'src/uiKit/theme';
import styled from 'styled-components';

export const FiltersContainer = styled.div<{ static?: boolean }>`
  position: ${(p) => (p.static ? 'relative' : 'sticky')};
  top: 0;
  z-index: 1;
  display: flex;
  border-radius: ${borderRadius.normal};
  padding: 1rem 0;
  cursor: pointer;
  margin-bottom: ${base.base2};
  flex-direction: column;
  border: none;
  @media screen and (min-width: ${breakpoint.desktop}) {
    align-items: flex-start;
    cursor: initial;
    border: ${(p) => p.theme.mainBlockBorder};
    padding: 2rem 4rem;
    margin-bottom: ${base.base4};
    background: ${color.white};
  }
`;

export const FiltersTitle = styled.span`
  ${fontFamily.maru}
  font-size: ${base.base5};
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  margin-bottom: ${base.base4};

  span {
    @media screen and (min-width: ${breakpoint.desktop}) {
      font-size: ${base.base7};
      margin-bottom: ${base.base4};
    }
  }

  img {
    width: 3rem;
    margin-left: ${base.base4};
    @media screen and (min-width: ${breakpoint.desktop}) {
      margin-bottom: ${base.base4};
    }
  }
`;

export const FilterLabel = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop)
})<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => (p.isActive ? color.black : 'inherit')};
  text-decoration: ${(p) => (p.isActive ? 'underline' : 'none')};
  font-size: ${base.base5};
  font-weight: 700;
  cursor: pointer;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;

export const FilterOption = styled.span<{ onlyMobile?: boolean; isSelected?: boolean }>`
  display: block;
  position: relative;
  padding-left: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: ${(p) => (p.isSelected ? color.black : '#515151')};
  cursor: pointer;

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  :last-child {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${breakpoint.desktop}) {
    font-size: 2rem;
    display: ${(p) => (p.onlyMobile ? 'none' : 'block')};
  }

  :before {
    content: '';
    display: ${(p) => (p.isSelected ? 'block' : 'none')};
    position: absolute;
    left: 0.5rem;
    top: 0.85rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${color.black};
  }
`;

export const FiltersOptions = styled.div<{ showFilters: boolean }>`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  position: absolute;
  left: 0;
  top: calc(100% + 1rem);
  background: ${color.white};
  opacity: ${(p) => (p.showFilters ? 1 : 0)};
  visibility: ${(p) => (p.showFilters ? 'visible' : 'hidden')};
  padding: 2rem 4rem;
  white-space: nowrap;
  border-radius: ${borderRadius.normal};
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  @media screen and (min-width: ${breakpoint.desktop}) {
    position: initial;
    opacity: 1 !important;
    visibility: visible !important;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
`;
