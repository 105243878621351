import { BN } from 'src/utils/bigNumber';

export const MAX_UINT = BN(2).pow(256).minus(1).toString();
export const ZERO_ADDRESS = `0x${'0'.repeat(40)}`;
export const FOO_WALLET = `0x${'0'.repeat(39)}1`;
export const TOKEN_ADDRESS_LENGTH = 42;
export const MAX_IR = '600';
export const MIN_IR = '0.1';

export const IS_DEV = process.env.NODE_ENV === 'development';
