import axios, { AxiosResponse } from 'axios';
import { RarifyCollectionParamsResp } from 'src/api/rarify/types';
import { assertFulfilled } from 'src/utils/diff';

async function getCollectionsParams(addresses: string[], rarifyChain: string) {
  return (
    await Promise.allSettled(
      addresses.map((address) =>
        axios.get<AxiosResponse<RarifyCollectionParamsResp>>(
          `https://api.rarify.tech/data/contracts/${rarifyChain}:${address}/smart-floor-price`,
          {
            headers: { Authorization: 'Bearer ac2ff7f0-9c69-4670-927f-21b66e247bb9' },
          },
        ),
      ),
    )
  )
    .filter(assertFulfilled)
    .map((el) => ({
      ...el.value.data.data,
      address: `0x${el.value.data.data.id.split(':')[1].toLowerCase()}`,
    }));
}

export const rarifyApi = {
  getCollectionsParams,
};
