import * as ethers from 'ethers'
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'

import { getChainConfig, getEthersWsProvider } from 'src/store/chainStore'

    import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/parametersStorage.json'

export function getParametersStorageContract(provider: ethers.providers.WebSocketProvider | JsonRpcSigner) {
  return new ethers.Contract(getChainConfig()?.contracts.parametersStorage as string, abi, provider)
}

export function getParametersStorageBASIS_POINTS_IN_1_PERCENT() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.BASIS_POINTS_IN_1_PERCENT().catch((e: any) => {
    console.error('Failed to call BASIS_POINTS_IN_1_PERCENT in parametersStorage:', e)
  })
            }
    
export function getParametersStorageMAX_FEE_BASIS_POINTS() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.MAX_FEE_BASIS_POINTS().catch((e: any) => {
    console.error('Failed to call MAX_FEE_BASIS_POINTS in parametersStorage:', e)
  })
            }
    
export function getParametersStorageMAX_OPERATOR_FEE_PERCENT() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.MAX_OPERATOR_FEE_PERCENT().catch((e: any) => {
    console.error('Failed to call MAX_OPERATOR_FEE_PERCENT in parametersStorage:', e)
  })
            }
    
export function getParametersStorageVERSION() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.VERSION().catch((e: any) => {
    console.error('Failed to call VERSION in parametersStorage:', e)
  })
            }
    
export function getParametersStorageAssetCustomFee(addressInput1: any) {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.assetCustomFee(
    addressInput1
  ).catch((e: any) => {
    console.error('Failed to call assetCustomFee in parametersStorage:', e)
  })
            }
    
export function getParametersStorageAssetCustomParams(addressInput1: any, uint256Input2: any) {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.assetCustomParams(
    addressInput1,
    uint256Input2
  ).catch((e: any) => {
    console.error('Failed to call assetCustomParams in parametersStorage:', e)
  })
            }
    
export function getParametersStorageBaseFeeBasisPoints() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.baseFeeBasisPoints().catch((e: any) => {
    console.error('Failed to call baseFeeBasisPoints in parametersStorage:', e)
  })
            }
    
export function getParametersStorageCustomParams(uint256Input1: any) {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.customParams(
    uint256Input1
  ).catch((e: any) => {
    console.error('Failed to call customParams in parametersStorage:', e)
  })
            }
    
export function getParametersStorageGetAssetFee(_asset: any) {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.getAssetFee(
    _asset
  ).catch((e: any) => {
    console.error('Failed to call getAssetFee in parametersStorage:', e)
  })
            }
    
export function getParametersStorageIsManager(addressInput1: any) {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.isManager(
    addressInput1
  ).catch((e: any) => {
    console.error('Failed to call isManager in parametersStorage:', e)
  })
            }
    
export function getParametersStorageOperatorFeePercent() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.operatorFeePercent().catch((e: any) => {
    console.error('Failed to call operatorFeePercent in parametersStorage:', e)
  })
            }
    
export function getParametersStorageOperatorTreasury() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.operatorTreasury().catch((e: any) => {
    console.error('Failed to call operatorTreasury in parametersStorage:', e)
  })
            }
    
export function getParametersStorageParameters() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.parameters().catch((e: any) => {
    console.error('Failed to call parameters in parametersStorage:', e)
  })
            }
    
export function submitParametersStorageSetAssetCustomFee(
  _asset: any,
  _enabled: any,
  _feeBasisPoints: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setAssetCustomFee', [
    _asset,
    _enabled,
    _feeBasisPoints,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetAssetCustomParam(
  _asset: any,
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setAssetCustomParam', [
    _asset,
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetAssetCustomParamAsAddress(
  _asset: any,
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setAssetCustomParamAsAddress', [
    _asset,
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetAssetCustomParamAsUint(
  _asset: any,
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setAssetCustomParamAsUint', [
    _asset,
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetBaseFee(_feeBasisPoints: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setBaseFee', [
    _feeBasisPoints,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetCustomParam(
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setCustomParam', [
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetCustomParamAsAddress(
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setCustomParamAsAddress', [
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetCustomParamAsUint(
  _param: any,
  _value: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setCustomParamAsUint', [
    _param,
    _value,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetManager(_who: any, _permit: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setManager', [
    _who,
    _permit,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetOperatorFee(
  _operatorFeePercent: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setOperatorFee', [
    _operatorFeePercent,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetOperatorTreasury(
  _operatorTreasury: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setOperatorTreasury', [
    _operatorTreasury,
 { ...options },
  ])
            }
    
export function submitParametersStorageSetTreasury(_treasury: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getParametersStorageContract(signer)
                return sendTransaction(contract, 'setTreasury', [
    _treasury,
 { ...options },
  ])
            }
    
export function getParametersStorageTreasury() {
              const contract = getParametersStorageContract(getEthersWsProvider())
                return contract.treasury().catch((e: any) => {
    console.error('Failed to call treasury in parametersStorage:', e)
  })
            }
    
