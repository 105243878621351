import { createContext } from 'react';

interface SearchContextProps {
  search: string;
  setSearch: (val: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({
  search: '',
  setSearch: () => {}
});