import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { looksRareApi } from 'src/api/looksRare';
import { rarifyApi } from 'src/api/rarify';
import { ChainId } from 'src/constants/chain';
import { getChainConfig, isMainNet } from 'src/store/chainStore';
import { pricesStore } from 'src/store/pricesStore';
import { Token, TokensMap } from 'src/types/tokens';
import { BN } from 'src/utils/bigNumber';
import { isAddressesEq } from 'src/utils/compareAddresses';
import { withTimeout } from 'src/utils/delay';
import { getNftsWithIndexes } from 'src/utils/erc721';
import { fetchTokensParams } from 'src/utils/token';

const log = debug('store:erc721Store');

export const erc721Store = {
  nfts: new BehaviorSubject<TokensMap>(new Map()),
  loading: new BehaviorSubject(false),
  loadingIndexes: new BehaviorSubject(false),
  fetchedForChainId: null as null | ChainId,
  fetchedForAddress: '',
  pricesFetched: false,

  fetchForNewChain(wallet: string, chainId: ChainId) {
    if (this.fetchedForChainId === chainId) return;
    this.pricesFetched = false;
    this.getAndSetNfts(wallet, chainId);
    this.nfts.next(new Map());
  },

  async getAndSetNfts(wallet: string, chainId: ChainId) {
    log('getAndSetNfts fired', { wallet, chainId, walletPrev: this.fetchedForAddress });
    if (isAddressesEq(this.fetchedForAddress, wallet) && this.fetchedForChainId === chainId) return;

    this.loading.next(true);
    this.fetchedForAddress = wallet;
    this.fetchedForChainId = chainId;

    log('getAndSetNfts fired', { wallet, chainId });

    const nfts = await this.getCollections(wallet);

    if (!nfts) return;

    this.addNftsToList(nfts);

    if (!this.pricesFetched) {
      this.pricesFetched = true;
      this.fetchNftsPrices(nfts);
    }
  },

  async fetchNftsPrices(nfts: Token[]) {
    let addresses = nfts.map((nft) =>
      isAddressesEq('0xb7f7f6c52f2e2fdb1963eab30438024864c313f6', nft.address)
        ? '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb'
        : nft.address,
    );
    const rarifyChain = getChainConfig()?.rarify;
    log('fetchNftsPrices fired', nfts);

    let rarifyResp: Awaited<ReturnType<typeof rarifyApi.getCollectionsParams>> = [];

    if (rarifyChain) {
      rarifyResp = await rarifyApi.getCollectionsParams(addresses, rarifyChain.chainName);
    }

    addresses = addresses.filter(
      (address) => !rarifyResp.find((el) => isAddressesEq(el.address, address)),
    );

    let looksRareResp: Awaited<ReturnType<typeof looksRareApi.getCollectionsParams>> = [];

    if (isMainNet()) {
      looksRareResp = (await looksRareApi.getCollectionsParams(addresses))
        .filter(Boolean)
        .filter((el) => el.floorPrice);
    }

    await pricesStore.fetchTokensPrice([getChainConfig()?.wethAddress || '']);

    const wethPrice = pricesStore.getTokenPrice(getChainConfig()?.wethAddress).price;

    log('rarifyResp, looksRareResp resp', { looksRareResp });

    const fetchedAddresses: string[] = [];
    const fetchedPrices: string[] = [];
    const sources: Token['source'][] = [];

    rarifyResp.forEach((el) => {
      if (isAddressesEq(el.address, '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb')) {
        fetchedAddresses.push('0xb7f7f6c52f2e2fdb1963eab30438024864c313f6');
      } else {
        fetchedAddresses.push(el.address.toLowerCase());
      }
      fetchedPrices.push(
        BN(el.attributes.price)
          .times(wethPrice || 1)
          .div(BN(10).pow(18))
          .toString(),
      );
      sources.push('rarify');
    });

    looksRareResp.forEach((el) => {
      if (isAddressesEq(el.address, '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb')) {
        fetchedAddresses.push('0xb7f7f6c52f2e2fdb1963eab30438024864c313f6');
      } else {
        fetchedAddresses.push(el.address.toLowerCase());
      }
      fetchedPrices.push(
        BN(el.floorPrice)
          .times(wethPrice || 1)
          .div(BN(10).pow(18))
          .toString(),
      );
      sources.push('looksRare');
    });

    log('fetchedAddresses, fetchedPrices', { fetchedAddresses, fetchedPrices, sources });

    pricesStore.setTokensPrices(fetchedAddresses, fetchedPrices, sources);
  },

  async getCollections(wallet: string) {
    log('getCollections fired', { wallet });
    const collections = getChainConfig()?.contracts.nftsCollections;

    if (!collections) {
      log('getCollections collection is empty', getChainConfig());
      return;
    }

    const collectionsAddresses = Object.values(collections);

    log('getCollections collectionsAddresses', collectionsAddresses);

    const { erc721Tokens } = await fetchTokensParams(collectionsAddresses, wallet, true);

    log('getCollections erc721Tokens', erc721Tokens);

    return erc721Tokens;
  },

  async getUserNfts(wallet: string) {
    log('getUserNfts fired', { wallet });
    const collections = getChainConfig()?.contracts.nftsCollections;

    if (!collections) {
      log('getUserNfts collection is empty', getChainConfig());
      return;
    }

    const collectionsAddresses = Object.values(collections);

    log('getUserNfts collectionsAddresses', collectionsAddresses);

    const { erc721Tokens } = await fetchTokensParams(collectionsAddresses, wallet);

    log('getUserNfts erc721Tokens', erc721Tokens);

    const nftsWithIndexes = await getNftsWithIndexes(erc721Tokens, wallet);

    log('nftsWithIndexes', nftsWithIndexes);

    return nftsWithIndexes;
  },

  async fetchCollectionUserNfts(collectionAddress: string, walletAddress: string) {
    const nft = this.nfts.getValue().get(collectionAddress);

    if (nft && nft.indexes) return;

    this.loadingIndexes.next(true);

    log('fetchCollectionUserNfts fired', { loading: erc721Store.loading.getValue(), nft });

    if (erc721Store.loading.getValue() || !nft) {
      withTimeout(1000, () =>
        erc721Store.fetchCollectionUserNfts(collectionAddress, walletAddress),
      );
      return;
    }

    const nftsWithIndexes = await getNftsWithIndexes([nft], walletAddress);
    log('fetchCollectionUserNfts nftsWithIndexes', nftsWithIndexes);
    this.addNftsToList(nftsWithIndexes);

    this.loadingIndexes.next(false);
  },

  addNftsToList(nfts: Token[]) {
    log('addTokensToList fired', { nfts });

    const newTokensMap = new Map([
      ...erc721Store.nfts.getValue(),
      ...new Map(nfts.map((nft) => [nft.address, nft])),
    ]);

    this.nfts.next(newTokensMap);
    this.loading.next(false);
  },

  async getNftParams(collection: string) {
    log('getNftParams fired');
    if (this.loading.getValue())
      await withTimeout(1500, () => erc721Store.getNftParams(collection));
    return this.nfts.getValue().get(collection);
  },

  setTokenBalance(address: string, token: Token, walletAddress: string) {
    const nftInTheList = this.nfts.getValue().get(address) || token;

    nftInTheList.balance = token.balance;

    this.nfts.next(new Map([...this.nfts.getValue(), [nftInTheList.address, nftInTheList]]));

    this.fetchCollectionUserNfts(token.address, walletAddress);
  },

  clearBalances() {
    const nfts = new Map(this.nfts.getValue());
    this.fetchedForAddress = '';
    this.nfts.next(new Map([...nfts].map(([address, nft]) => [address, { ...nft, balance: '0' }])));
  },

  balanceTrackingCallback(walletAddress: string, tokenAddress: string) {
    fetchTokensParams([tokenAddress], walletAddress, true)
      .then(({ erc721Tokens }) => {
        const token = erc721Tokens[0];
        if (token) erc721Store.setTokenBalance(tokenAddress, token, walletAddress);
      })
      .catch((e) => {
        console.error(e);
      });
  },
};
