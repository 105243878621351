import * as ethers from 'ethers'
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'

import { getEthersWsProvider } from 'src/store/chainStore'

    import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/ERC20.json'

export function getErc20TokenContract(provider: ethers.providers.WebSocketProvider | JsonRpcSigner, contractAddress: string) {
  return new ethers.Contract(contractAddress as string, abi, provider)
}

export function getErc20TokenName(contractAddress: string) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.name().catch((e: any) => {
    console.error('Failed to call name in erc20Token:', e)
  })
            }
    
export function submitErc20TokenApprove(
  contractAddress: string,
  guy: any,
  wad: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getErc20TokenContract(signer, contractAddress)
                return sendTransaction(contract, 'approve', [
    guy,
    wad,
 { ...options },
  ])
            }
    
export function getErc20TokenTotalSupply(contractAddress: string) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.totalSupply().catch((e: any) => {
    console.error('Failed to call totalSupply in erc20Token:', e)
  })
            }
    
export function submitErc20TokenTransferFrom(
  contractAddress: string,
  src: any,
  dst: any,
  wad: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getErc20TokenContract(signer, contractAddress)
                return sendTransaction(contract, 'transferFrom', [
    src,
    dst,
    wad,
 { ...options },
  ])
            }
    
export function submitErc20TokenWithdraw(contractAddress: string, wad: any, signer: JsonRpcSigner, options: any = {}) {
              const contract = getErc20TokenContract(signer, contractAddress)
                return sendTransaction(contract, 'withdraw', [
    wad,
 { ...options },
  ])
            }
    
export function getErc20TokenDecimals(contractAddress: string) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.decimals().catch((e: any) => {
    console.error('Failed to call decimals in erc20Token:', e)
  })
            }
    
export function getErc20TokenBalanceOf(contractAddress: string, addressInput1: any) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.balanceOf(
    addressInput1
  ).catch((e: any) => {
    console.error('Failed to call balanceOf in erc20Token:', e)
  })
            }
    
export function getErc20TokenSymbol(contractAddress: string) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.symbol().catch((e: any) => {
    console.error('Failed to call symbol in erc20Token:', e)
  })
            }
    
export function submitErc20TokenTransfer(
  contractAddress: string,
  dst: any,
  wad: any,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getErc20TokenContract(signer, contractAddress)
                return sendTransaction(contract, 'transfer', [
    dst,
    wad,
 { ...options },
  ])
            }
    
export function submitErc20TokenDeposit(
  contractAddress: string,
  ethAmount: string,
  signer: JsonRpcSigner,
  options: any = {},
) {
              const contract = getErc20TokenContract(signer, contractAddress)
                return sendTransaction(contract, 'deposit', [
    { ...options, value: ethAmount },
  ])
            }
    
export function getErc20TokenAllowance(contractAddress: string, addressInput1: any, addressInput2: any) {
              const contract = getErc20TokenContract(getEthersWsProvider(), contractAddress)
                return contract.allowance(
    addressInput1,
    addressInput2
  ).catch((e: any) => {
    console.error('Failed to call allowance in erc20Token:', e)
  })
            }
    
