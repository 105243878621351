import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { ChainId } from 'src/constants/chain';
import {
  getBorrowModule01GetUserLoans,
  getBorrowModule01Loans,
} from 'src/contracts/borrowmodule01/contractFunctions';
import {
  trackBorrowModule01AuctionCancelled,
  trackBorrowModule01AuctionInterestRateMaxUpdated,
  trackBorrowModule01AuctionStarted,
  trackBorrowModule01LoanIssued,
  trackBorrowModule01LoanLiquidated,
  trackBorrowModule01LoanRepaid,
} from 'src/contracts/borrowmodule01/eventListeners';
import { getWeb3WsProvider } from 'src/store/chainStore';
import { Auction, AuctionFromChain, AuctionInfo, AuctionsInfoFromChain } from 'src/types/auctions';
import { formatAuction, formatAuctions } from 'src/utils/auctions';
import { isAddressesEq } from 'src/utils/compareAddresses';
import { fromBpToPercent } from 'src/utils/misc';
import { auctionsStore } from './auctionsStore';

const log = debug('store:loansStore');

export const loansStore = {
  loans: new BehaviorSubject<Map<string, Auction> | null>(null),
  loading: new BehaviorSubject(true),
  fetchedForAddress: '',
  fetchedForChainId: null as null | ChainId,
  trackingInitialized: false,

  async fetchUserLoans(walletAddress: string, chainId: ChainId) {
    log('fetchLoans fired for user', {
      walletAddress,
      fetchedForAddress: this.fetchedForAddress,
      fetchedForChainId: this.fetchedForChainId,
    });
    if (!walletAddress) return;
    if (
      this.fetchedForAddress &&
      isAddressesEq(this.fetchedForAddress, walletAddress) &&
      this.fetchedForChainId === chainId
    ) {
      this.loading.next(false);
      return;
    }

    this.fetchedForAddress = walletAddress;
    this.fetchedForChainId = chainId;

    try {
      const loansInfo: AuctionsInfoFromChain = await getBorrowModule01GetUserLoans(walletAddress);
      log('loansInfo:', loansInfo);
      const formattedLoans = formatAuctions(loansInfo);
      log('formattedLoans:', formattedLoans);
      this.loans.next(new Map(formattedLoans.map((auction) => [auction.id, auction])));
      if (!this.trackingInitialized) this.startLoansTracking();
      this.trackingInitialized = true;
    } catch (e) {
      log('fetchUserLoans fault');
      console.error(e);
    } finally {
      this.loading.next(false);
    }
  },

  startLoansTracking() {
    log('startLoansTracking fired for address', loansStore.fetchedForAddress);
    trackBorrowModule01AuctionStarted({
      callback([event]) {
        if (!event) return;
        log('AuctionStarted callback fired', event);

        const loanId = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        log('loanId', loanId);

        loansStore.fetchLoanByIdAndSet(loanId);
      },
      loanId: '',
      borrower: '',
    });
    trackBorrowModule01LoanIssued({
      callback([event]) {
        if (!event) return;
        log('loans store trackBorrowModule01LoanIssued callback fired');

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        const lender = getWeb3WsProvider().eth.abi.decodeParameter(
          'address',
          event.topics[2] as string,
        ) as unknown as string;

        log({ id, lender });

        if (isAddressesEq(lender, loansStore.fetchedForAddress)) loansStore.fetchLoanByIdAndSet(id);
        else loansStore.updateLoanParam(id, 'state', 3);
      },
      loanId: '',
      lender: '',
    });
    trackBorrowModule01AuctionCancelled({
      callback([event]) {
        if (!event) return;
        log('AuctionCancelled callback fired', event);

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        const loan = loansStore.loans.getValue()?.get(id);

        if (!loan) {
          log('AuctionCancelled for another user');
        }

        loansStore.updateLoanParam(id, 'state', 2);
      },
      loanId: '',
      borrower: '',
    });
    trackBorrowModule01LoanLiquidated({
      callback([event]) {
        if (!event) return;
        log('LoanLiquidated callback fired', event);

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        const loan = loansStore.loans.getValue()?.get(id);

        if (!loan) {
          log('LoanLiquidated for another user');
        }

        loansStore.updateLoanParam(id, 'state', 5);
      },
      loanId: '',
      liquidator: '',
    });
    trackBorrowModule01AuctionInterestRateMaxUpdated({
      callback([event]) {
        if (!event) return;
        log('AuctionInterestRateMaxUpdated callback fired', event);

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        const { 0: newInterestRateMax } = getWeb3WsProvider().eth.abi.decodeParameters(
          ['uint16'],
          event.data,
        ) as [string];

        loansStore.updateLoanAuctionInfo(
          id,
          'interestRateMax',
          fromBpToPercent(newInterestRateMax),
        );
      },
      loanId: '',
      borrower: '',
    });
    trackBorrowModule01LoanRepaid({
      callback([event]) {
        log('Loan repaid', event);
        if (!event) return;

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        loansStore.updateLoanParam(id, 'state', 4);
        auctionsStore.updateAuctionParam(id, 'state', 4);
      },
      loanId: '',
      borrower: '',
    });
  },

  async fetchLoanByIdAndSet(id: string) {
    if (this.loans.getValue()?.has(id)) return;
    log('fetchLoanByIdAndSet fired');

    try {
      const loanInfo = (await getBorrowModule01Loans(id)) as AuctionFromChain;
      log('fetchLoanByIdAndSet auctionInfo', loanInfo);
      const formattedAuction = formatAuction(id, loanInfo);
      log('fetchLoanByIdAndSet formatted result', formattedAuction);
      loansStore.addLoan(formattedAuction);
    } catch (e) {
      console.error(e);
      log('fetchLoanByIdAndSet fault for id:', id);
    }
  },

  updateLoanAuctionInfo<K extends keyof AuctionInfo>(id: string, param: K, value: AuctionInfo[K]) {
    log('updateLoanAuctionInfo params: ', { id, param, value });
    const loans = this.loans.getValue();
    const loan = loans?.get(id);
    if (!loan) {
      log('updateLoanAuctionInfo fault auction not exist', { id });
      return;
    }
    loan.auctionInfo[param] = value;
    log('set loans', loans);
    this.loans.next(new Map(loans));
  },

  updateLoanParam<T extends keyof Auction>(id: string, param: T, value: Auction[T]) {
    log('updateLoanParam params: ', { id, param, value });
    const loans = this.loans.getValue();
    const loan = loans?.get(id);
    if (!loan) {
      log('updateLoanParam fault auction not exist', { id });
      return;
    }
    loan[param] = value;
    log('set loans', loans);
    this.loans.next(new Map(loans));
  },

  addLoan(loan: Auction) {
    log('addLoan fired', loan);
    const loans = loansStore.loans.getValue();
    if (!loans || loans.has(loan.id)) {
      log('addLoan fault auction exist', loan);
      return;
    }
    loans.set(loan.id, loan);
    log('set loans', loans);
    loansStore.loans.next(new Map(loans));
  },

  deleteLoan(loanId: string) {
    log('removeAuction fired', loanId);
    const loans = loansStore.loans.getValue();
    if (!loans || !loans.has(loanId)) {
      log('addAuction fault auction not exist', loanId);
      return;
    }
    loans.delete(loanId);
    loansStore.loans.next(new Map(loans));
  },
};
