import debug from 'debug';
import { getErc20TokenBalanceOf } from 'src/contracts/erc20token/contractFunctions';
import {
  trackErc20TokenDeposit,
  trackErc20TokenTransfer,
  trackErc20TokenWithdrawal,
} from 'src/contracts/erc20token/eventListeners';
import { trackErc721TokenTransfer } from 'src/contracts/erc721token/eventListeners';
import { getEthersWsProvider } from 'src/store/chainStore';
import web3Core from 'web3-core';

const log = debug('utils:balances');

export async function getBalance(walletAddress: string, tokenAddress: string): Promise<string> {
  return String(await getErc20TokenBalanceOf(tokenAddress, walletAddress));
}

export async function fetchEthBalance(walletAddress: string): Promise<string> {
  return String(await getEthersWsProvider().getBalance(walletAddress));
}

export const initializeBalanceTrackingForErc721 = (
  wallet: string,
  callback: ([event]: [web3Core.Log]) => void,
) => {
  log('initializeBalanceTrackingForErc721 fired');
  trackErc721TokenTransfer({
    callback,
    contract: '',
    from: wallet,
    to: '',
    tokenId: '',
  });
  trackErc721TokenTransfer({
    callback,
    contract: '',
    from: '',
    to: wallet,
    tokenId: '',
  });
};

export const initializeBalanceTrackingForTokens = (
  wallet: string,
  callback: ([event]: [web3Core.Log]) => void,
) => {
  log('initializeBalanceTrackingForTokens fired');
  trackErc20TokenTransfer(
    {
      callback,
      contract: '',
      src: wallet,
      dst: '',
    },
    wallet,
  );
  trackErc20TokenTransfer(
    {
      callback,
      contract: '',
      src: '',
      dst: wallet,
    },
    wallet,
  );
  trackErc20TokenDeposit(
    {
      callback,
      contract: '',
      dst: wallet,
    },
    wallet,
  );
  trackErc20TokenWithdrawal(
    {
      callback,
      contract: '',
      src: wallet,
    },
    wallet,
  );
};
