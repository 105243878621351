import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LeftSideMenu } from 'src/components/LeftSideMenu';
import {
  DaysFilterType,
  FilterContext,
  InterestRateType,
  LoanAmountType,
} from 'src/context/FilterContext';
import { SearchContext } from 'src/context/SearchContext';
import { FCC } from 'src/types/fcc';
import { breakpoint } from 'src/uiKit/theme';
import styled from 'styled-components';

export const PageLayout = styled.div`
  display: grid;
  align-items: start;
  justify-content: stretch;
  margin: 0;
  @media screen and (min-width: ${breakpoint.desktop}) {
    grid-template-columns: auto 1fr;
    margin: 4rem 0 0;
  }
`;

export const WithLeftSideMenu: FCC<{ withFilters?: boolean }> = ({ children, withFilters }) => {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [filters, setFilters] = useState<{
    loanTerm: DaysFilterType[];
    loanAmount: LoanAmountType[];
    interestRate: InterestRateType[];
  }>({
    loanTerm: [],
    loanAmount: [],
    interestRate: [],
  });

  useEffect(() => {
    const loanTerm = sessionStorage.getItem('loanTerm')
      ? (sessionStorage.getItem('loanTerm')?.split(',') as DaysFilterType[])
      : [];
    const loanAmount = sessionStorage.getItem('loanAmount')
      ? (sessionStorage.getItem('loanAmount')?.split(',') as LoanAmountType[])
      : [];
    const interestRate = sessionStorage.getItem('interestRate')
      ? (sessionStorage.getItem('interestRate')?.split(',') as InterestRateType[])
      : [];
    setFilters({ loanTerm, loanAmount, interestRate });
  }, []);

  const clearStorage = () => {
    Object.keys(filters).forEach((filter) => sessionStorage.removeItem(filter));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearStorage);
    return () => {
      window.removeEventListener('beforeunload', clearStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilters({ loanTerm: [], loanAmount: [], interestRate: [] });
    setSearch('');
  }, [location]);

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      <FilterContext.Provider value={{ filters, setFilters, showFilters, setShowFilters }}>
        <PageLayout>
          <LeftSideMenu showFilters={withFilters} />
          {children}
        </PageLayout>
      </FilterContext.Provider>
    </SearchContext.Provider>
  );
};
