import debug from 'debug';
import { ethers } from 'ethers';
import { BehaviorSubject } from 'rxjs';
import { BN } from 'src/utils/bigNumber';
import { getChainConfigByUrlRepresentation, getChainRepresentationFromUrl } from 'src/utils/chain';
import Web3 from 'web3';

import { CHAIN_ID, ChainConfig, ChainId } from '../constants/chain';

const log = debug('store:chainStore');

const chainRepresentationFromUrl = getChainRepresentationFromUrl();
const chainConfig = getChainConfigByUrlRepresentation(chainRepresentationFromUrl);

export const chainStore = {
  appChainId: new BehaviorSubject(chainConfig.id),
  prevChainId: new BehaviorSubject<ChainId | null>(null),
  chainConfig: new BehaviorSubject<ChainConfig | null>(null),
  ethersWsProvider: new BehaviorSubject<ethers.providers.WebSocketProvider | null>(null),
  web3WsProvider: new BehaviorSubject<Web3 | null>(null),

  setAppChainId(chainId: ChainId) {
    log('setAppChainId fired with params: ', chainId);
    if (chainId === this.prevChainId.getValue()) return;
    this.appChainId.next(chainId);
  },

  createNewWsProvider() {
    log('createNewWsProvider fired');
    const chainConfig = getChainConfig();
    if (!chainConfig) {
      log('createNewWsProvider failed chainConfig not settled');
      return;
    }
    log('createNewWsProvider with url: ', chainConfig.wsUrl);
    const provider = new ethers.providers.WebSocketProvider(chainConfig.wsUrl);
    this.setEthersWsProvider(provider);
  },

  createNewWeb3WsProvider() {
    log('createNewWeb3WsProvider fired');
    const chainConfig = getChainConfig();
    if (!chainConfig) {
      log('createNewWsProvider failed chainConfig not settled');
      return;
    }
    log('createNewWeb3WsProvider with url: ', chainConfig.wsUrl);
    const provider = new Web3(chainConfig.wsUrl);
    this.setWeb3Provider(provider);
  },

  setEthersWsProvider(provider: ethers.providers.WebSocketProvider) {
    log('setProvider fired with params: ', provider);
    if (getChainId() === CHAIN_ID.CELO) {
      log('setEthersWsProvider cello condition');
      const blockFormat = provider.formatter.formats.block;
      blockFormat.gasLimit = () => BN(0);
      blockFormat.nonce = () => '';
      blockFormat.difficulty = () => 0;
    }
    this.ethersWsProvider.next(provider);
  },

  setWeb3Provider(provider: Web3) {
    log('setWeb3Provider fired: ', provider);
    this.web3WsProvider.next(provider);
  },
};

export function getChainId() {
  return chainStore.appChainId.getValue();
}

export function getChainConfig() {
  return chainStore.chainConfig.getValue();
}

export function getEthersWsProvider() {
  return chainStore.ethersWsProvider.getValue() as ethers.providers.WebSocketProvider;
}

export function getWeb3WsProvider() {
  return chainStore.web3WsProvider.getValue() as Web3;
}

export const isRinkeby = () => getChainId() === CHAIN_ID.ETH_RINKEBY;
export const isMainNet = () => getChainId() === CHAIN_ID.ETH;
export const isBsc = () => getChainId() === CHAIN_ID.BSC;
export const isGno = () => getChainId() === CHAIN_ID.GNO;
export const isMoonbeam = () => getChainId() === CHAIN_ID.MOONBEAM;
export const isBoba = () => getChainId() === CHAIN_ID.BOBA;
export const isOasis = () => getChainId() === CHAIN_ID.OASIS;
