import { IS_DEV } from 'src/constants/constants';
import Web3 from 'web3';

export const CHAIN_ID = {
  ETH: 1,
  ETH_RINKEBY: 4,
  BSC: 56,
  GNO: 100,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  POLYGON: 137,
  AURORA: 1313161554,
  BOBA: 288,
  MOONBEAM: 1284,
  OKC: 66,
  MOONRIVER: 1285,
  HECO: 128,
  OASIS: 42262,
  CELO: 42220,
  CRONOS: 25,
  KLAYTN: 8217,
  SYSCOIN: 57,
} as const;

export type ChainId = typeof CHAIN_ID[keyof typeof CHAIN_ID];

export const CHAIN_URL_REPRESENTATION = {
  ETH: 'eth',
  ETH_RINKEBY: 'rinkeby',
  BSC: 'bsc',
  GNO: 'gnosis',
  AVALANCHE: 'avalanche',
  ARBITRUM: 'arbitrum',
  OPTIMISM: 'optimism',
  POLYGON: 'polygon',
  AURORA: 'aurora',
  BOBA: 'boba',
  MOONBEAM: 'moonbeam',
  OKC: 'okc',
  MOONRIVER: 'moonriver',
  HECO: 'heco',
  OASIS: 'oasis',
  CELO: 'celo',
  CRONOS: 'cronos',
  KLAYTN: 'klaytn',
  SYSCOIN: 'syscoin',
} as const;

export const urlRepresentationsList = Object.values(CHAIN_URL_REPRESENTATION) as string[];

export type UrlRepresentation =
  typeof CHAIN_URL_REPRESENTATION[keyof typeof CHAIN_URL_REPRESENTATION];

export type CoingeckoChainName =
  | 'ethereum'
  | 'binance-smart-chain'
  | 'xdai'
  | 'avalanche'
  | 'arbitrum-one'
  | 'optimistic-ethereum'
  | 'aurora'
  | 'boba'
  | 'moonbeam'
  | 'polygon-pos'
  | 'moonriver'
  | 'heco'
  | 'celo'
  | 'cronos'
  | 'klay-token'
  | 'oasis';

export type CoingeckoCategoryId =
  | 'ethereum-ecosystem'
  | 'binance-smart-chain'
  | 'xdai-ecosystem'
  | 'avalanche-ecosystem'
  | 'arbitrum-ecosystem'
  | 'moonbeam-ecosystem'
  | 'oec-ecosystem'
  | 'polygon-ecosystem'
  | 'moonriver-ecosystem'
  | 'heco-chain-ecosystem'
  | 'celo-ecosystem'
  | 'cronos-ecosystem'
  | 'klaytn-ecosystem'
  | 'oasis-network';

export enum CoinmarketcapCategory {
  'Ethereum Ecosystem' = '618c0beeb7dd913155b462f9',
  'Moonriver Ecosystem' = '61d923d341de9958121165fb',
  'Polygon Ecosystem' = '60a5f6765abd81761fe58688',
  'Arbitrum Ecosystem' = '6171122402ece807e8a9d3ed',
  'Avalanche Ecosystem' = '6051bc098a9b3f285eec4d40',
  'Fantom Ecosystem' = '60f078e54a4c180ab9f34cd0',
  'Celo Ecosystem' = '6172b83b6ea426155245c064',
  'Harmony Ecosystem' = '619f7223b02e240b33b7b8bc',
  'Cronos Ecosystem' = '61de44977ff10a2f03fd8b6c',
  'Heco Ecosystem' = '609a683ce58790484fa8abfe',
}

export type ZapperNetwork =
  | 'ethereum'
  | 'polygon'
  | 'optimism'
  | 'gnosis'
  | 'binance-smart-chain'
  | 'fantom'
  | 'avalanche'
  | 'arbitrum'
  | 'celo'
  | 'harmony'
  | 'moonriver'
  | 'bitcoin'
  | 'cronos'
  | 'aurora'
  | 'evmos';

export type ChainConfig = {
  maxLogQuery?: number;
  id: ChainId;
  urlRepresentation: UrlRepresentation;
  name: string;
  imgURL?: string;
  contracts: {
    parametersStorage: string;
    borrowModule01: string;
    assetViewer: string;
    bobHubOracle?: string;
    nftsCollections?: Record<string, string>;
  };
  blockList?: string[];
  additionalTokensList?: string[];
  trustWalletPath?: string;
  rarify?: {
    chainName: string;
  };
  wsUrl: string;
  rpcUrls: string[];
  wethAddress: string;
  ethMockAddress?: string;
  explorer: string;
  stables: string[];
  coingecko?: {
    chainName: CoingeckoChainName;
    categoryId: CoingeckoCategoryId;
  };
  zapper?: {
    network: ZapperNetwork;
  };
  coinmarketcap?: {
    categoryId: CoinmarketcapCategory;
  };
  paramsForAdding: {
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    hexId: string;
  };
};

const SUPPORTED_NETWORKS: ChainId[] = [
  CHAIN_ID.ETH,
  CHAIN_ID.BSC,
  CHAIN_ID.GNO,
  CHAIN_ID.POLYGON,
  CHAIN_ID.OPTIMISM,
  CHAIN_ID.ARBITRUM,
  CHAIN_ID.AVALANCHE,
  CHAIN_ID.AURORA,
  CHAIN_ID.MOONBEAM,
  CHAIN_ID.MOONRIVER,
  CHAIN_ID.HECO,
  CHAIN_ID.OASIS,
  // CHAIN_ID.CRONOS,
  CHAIN_ID.CELO,
  CHAIN_ID.KLAYTN,
  CHAIN_ID.SYSCOIN,
].filter(Boolean);

const TEST_CHAINS: ChainConfig[] = [
  {
    id: CHAIN_ID.ETH_RINKEBY,
    urlRepresentation: CHAIN_URL_REPRESENTATION.ETH_RINKEBY,
    name: 'Rinkeby (ETH)',
    stables: [],
    contracts: {
      parametersStorage: '0xc472b597DB27C6F04926557233B6479e77A48057',
      borrowModule01: '0x3Fbe5182dC975A10308Db5c4B028Aa830e48300d',
      assetViewer: '0xe8Dc2D00489AF000b65302B02458fD0C922ae624',
    },
    wethAddress: '0xc778417e063141139fce010982780140aa0cd5ab',
    explorer: 'https://rinkeby.etherscan.io',
    rpcUrls: [],
    wsUrl: 'wss://rinkeby.infura.io/ws/v3/db72eb2275564c62bfa71896870d8975',
    paramsForAdding: {
      nativeCurrency: {
        name: 'Ethereum Token',
        symbol: 'ETH',
        decimals: 18,
      },
      hexId: Web3.utils.toHex(CHAIN_ID.ETH_RINKEBY),
    },
    trustWalletPath: '',
  },
];

export const CHAIN_LIST: ChainConfig[] = [
  {
    id: CHAIN_ID.ETH,
    urlRepresentation: CHAIN_URL_REPRESENTATION.ETH,
    name: 'Ethereum',
    imgURL: '/images/icons/chains/ic-eth.svg',
    contracts: {
      parametersStorage: '0xf82f05b89d2432548263E3A01b18d8A5696b9A8E',
      borrowModule01: '0xAfaB0427Be2dC15199e24BE8646792cdc6f4dEc3',
      assetViewer: '0xBA40A7e0932B3f2256eA547e8dC515CB86c8d136',
      nftsCollections: {
        boredApeYachtClub: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
        mutantApeYachtClub: '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
        wPunks: '0xb7f7f6c52f2e2fdb1963eab30438024864c313f6',
        sM: '0xaaa7a35e442a77e37cde2f445b359aabf5ad0387',
        sMRev: '0xdd1f245d2920c5e144417ba2e59cf094bd615a91',
        pixelCats: '0x20172dab13e0cf9465382b32fe46cd51247cad71',
        azuki: '0xed5af388653567af2f388e6224dc7c4b3241c544',
        clonex: '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b',
        doodles: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
        cyberSamurai: '0xadec97ddc587f5285528069112b9b09f5ae5d15e',
        flufWorld: '0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d',
        othr: '0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258',
        onlyBots: '0xFCf5306EC78088DD85BB484816A1e37161B005e6',
        decentraland: '0xf87e31492faf9a91b02ee0deaad50d51d56d5d4d',
        ghostlers: '0x638196832001384b3a6457beb04372cff1878681',
        penguins: '0xbd3531da5cf5857e7cfaa92426877b022e612cf8',
        meebits: '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7',
        yaypegs: '0x7fda36c8daedcc55b73e964c2831d6161ef60a75',
        godhatesnftees: '0xe6d48bf4ee912235398b96e16db6f310c21e82cb',
        savageNation: '0x61e3d1c26802df805e9fc22dc26342e29ecfe860',
        yaygifs: '0xa6a4511e25c7f2a29be5ccf3978a0f3e238fd2c1',
        thepotatoz: '0x39ee2c7b3cb80254225884ca001f57118c8f21b6',
        nouns: '0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03',
        wolfGameFarmer: '0xbda2481db91fc0f942ed3f53de378ba45ba9d17e',
        pudgyrods: '0x062e691c2054de82f28008a8ccc6d7a1c8ce060d',
        pepeliberationarmyV2: '0x34cadc2ff14d57d6c3f806b5d798c6a27642af2e',
        veefriends: '0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb',
        coolCatsNft: '0x1a92f7381b9f03921564a437210bb9396471050c',
        wonkyStonks: '0x518ba36f1ca6dfe3bb1b098b8dd0444030e79d9f',
        primeraByMitchellAndYun: '0x9bf53d8c65f03d895dacaa776cc960e462ecb599',
        bitzApe: '0x6601a8e7b3d5ae631c1b1d07f01e75abe1cd1b89',
        squeakersnft: '0xaf60f9140dfdf85f8db527b4db609ce1986bf735',
        apehaterclub: '0x9370045ce37f381500ac7d6802513bb89871e076',
        thePlague: '0x8c3fb10693b228e8b976ff33ce88f97ce2ea9563',
        murakamiFlowers2022Official: '0x7d8820fa92eb1584636f4f5b8515b5476b75171a',
        sewerpass: '0x764aeebcf425d56800ef2c84f2578689415a2daa',
        thecaptainz: '0x769272677fab02575e84945f03eca517acc544cc',
        boredApeKennelClub: '0xba30e5f9bb24caa003e9f2f0497ad287fdf95623',
        kubz: '0xeb2dfc54ebafca8f50efcc1e21a9d100b5aeb349',
        porsche911: '0xccdf1373040d9ca4b5be1392d1945c1dae4a862c',
        liveLikeACatNekonoyouniikiru: '0x597d757f8502f1fe8e7dd6fc7fe884a51c5ae2b9',
        killabears: '0xc99c679c50033bbc5321eb88752e89a93e9e83c5',
        goblintownwtf: '0xbce3781ae7ca1a5e050bd9c4c77369867ebc307e',
        kubzRelic: '0xe1b81cd6a494cbca06a8e2055a62c2cf0fa5a8ac',
        rtfktAnimusEgg: '0x6c410cf0b8c113dc6a7641b431390b11d5515082',
        kitaroworldofficial: '0x7df64f69544c5bf71171dc5ab69b8602c2ff1e27',
        genesisCreepz: '0xfe8c6d19365453d26af321d0e8c910428c23873f',
        worldOfWomenNft: '0xe785e82358879f061bc3dcac6f0444462d4b5330',
        alienfrensnft: '0x123b30e25973fecd8354dd5f41cc45a3065ef88c',
        lobsterdao: '0x026224a2940bfe258d0dbe947919b62fe321f042',
        notbanksyechoes: '0x925f7eb0fe634702049a1344119d4046965b5c8c',
        neotokyoOuterCitizens: '0x9b091d2e0bb88ace4fe8f0fab87b93d8ba932ec4',
      },
    },
    blockList: [
      '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
      '0x45804880de22913dafe09f4980848ece6ecbaf78',
    ],
    additionalTokensList: ['0x92e187a03b6cd19cb6af293ba17f2745fd2357d5'],
    trustWalletPath: 'ethereum',
    rarify: {
      chainName: 'ethereum',
    },
    coingecko: {
      chainName: 'ethereum',
      categoryId: 'ethereum-ecosystem',
    },
    zapper: {
      network: 'ethereum',
    },
    coinmarketcap: {
      categoryId: CoinmarketcapCategory['Ethereum Ecosystem'],
    },
    wethAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    explorer: 'https://etherscan.io',
    rpcUrls: ['https://mainnet.infura.io/v3/db72eb2275564c62bfa71896870d8975'],
    wsUrl: IS_DEV
      ? 'wss://mainnet.infura.io/ws/v3/db72eb2275564c62bfa71896870d8975'
      : 'wss://mainnet.infura.io/ws/v3/32528b13d24c4a139a35e6f95c0c94b8',
    paramsForAdding: {
      nativeCurrency: {
        name: 'Ethereum Token',
        symbol: 'ETH',
        decimals: 18,
      },
      hexId: Web3.utils.toHex(CHAIN_ID.ETH),
    },
    stables: [
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      '0x1456688345527bE1f37E9e627DA0837D6f08C925',
      '0x6b175474e89094c44da98b954eedeac495271d0f',
    ],
  },
  // {
  //   id: CHAIN_ID.BSC,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.BSC,
  //   name: 'BSC',
  //   imgURL: '/images/icons/chains/ic-binance.svg',
  //   contracts: {
  //     parametersStorage: '0x89a00327718B811571A6fA536FE51579442f5579',
  //     borrowModule01: '0x2E0f13D18D210dd62EFf4818084B10697803e62C',
  //     assetViewer: '0x3Eb712991e6a7d4Cbc43A62266b7bfF143D8866B',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'binance',
  //   coingecko: {
  //     chainName: 'binance-smart-chain',
  //     categoryId: 'binance-smart-chain',
  //   },
  //   zapper: {
  //     network: 'binance-smart-chain',
  //   },
  //   wethAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   explorer: 'https://bscscan.com/',
  //   rpcUrls: ['https://bsc-dataseed.binance.org', 'https://bsc-dataseed.binance.org'],
  //   wsUrl:
  //     'wss://methodical-bitter-dream.bsc.quiknode.pro/775e50e7b4f613454fb879ecc57dfe55b2093d4a/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'Binance Coin',
  //       symbol: 'BNB',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.BSC),
  //   },
  //   stables: [
  //     '0xdacd011a71f8c9619642bf482f1d4ceb338cffcf',
  //     '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //     '0x55d398326f99059ff775485246999027b3197955',
  //     '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.GNO,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.GNO,
  //   name: 'Gnosis',
  //   imgURL: '/images/icons/chains/ic-gno.svg',
  //   contracts: {
  //     parametersStorage: '0x24425c2f419087fc85127B5B7e314d668193F705',
  //     borrowModule01: '0x764c0dfd3a37d02Ce3cDfeE4C0280B082bc34F0B',
  //     assetViewer: '0x32dC5dF6A4986FE6BcDf8549C0679d943a60892B',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'xdai',
  //   coingecko: {
  //     chainName: 'xdai',
  //     categoryId: 'xdai-ecosystem',
  //   },
  //   zapper: {
  //     network: 'gnosis',
  //   },
  //   wethAddress: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
  //   explorer: 'https://gnosisscan.io',
  //   rpcUrls: ['https://rpc.gnosischain.com', 'https://xdai-rpc.gateway.pokt.network'],
  //   wsUrl: IS_DEV
  //     ? 'wss://rpc.gnosischain.com/wss'
  //     : 'wss://intensive-polished-breeze.xdai.quiknode.pro/569655f4723a24b2d375b4230bb8fc3c32afac4c/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'XDAI',
  //       symbol: 'XDAI',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.GNO),
  //   },
  //   stables: [
  //     '0x068e56eBB63e5f98532bAF94fA1f9b9AE19Ba761',
  //     '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
  //     '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
  //     '0x44fa8e6f47987339850636f88629646662444217',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.AVALANCHE,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.AVALANCHE,
  //   name: 'Avalanche',
  //   imgURL: '/images/icons/chains/ic-avalanche.svg',
  //   contracts: {
  //     parametersStorage: '0x2318Fa4590B8FBC24C7703C8FeaAe63337D8213d',
  //     borrowModule01: '0xdfFE58013aEA8850767926892C9B176364E22c83',
  //     assetViewer: '0x26Ee42567C1de35f59c448A54d3CB55d1D1B6c2F',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'avalanchec',
  //   coingecko: {
  //     chainName: 'avalanche',
  //     categoryId: 'avalanche-ecosystem',
  //   },
  //   zapper: {
  //     network: 'avalanche',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Avalanche Ecosystem'],
  //   },
  //   wethAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  //   ethMockAddress: 'FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z',
  //   explorer: 'https://snowtrace.io/',
  //   rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  //   wsUrl:
  //     'wss://avax.getblock.io/mainnet/ext/bc/C/ws?api_key=35ea05e1-26a7-40e6-a910-d7afcdcae684',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'AVAX',
  //       symbol: 'AVAX',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.AVALANCHE),
  //   },
  //   stables: [
  //     '0x0378210a6f770Fb70BEe091467A1ef2dE351003B',
  //     '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  //     '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
  //     '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.ARBITRUM,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.ARBITRUM,
  //   name: 'Arbitrum',
  //   imgURL: '/images/icons/chains/ic-arbitrum.svg',
  //   contracts: {
  //     parametersStorage: '0x50B17e535D6b19872a2f968BEaE4d691A7694E27',
  //     borrowModule01: '0x38f3E3261c72652EE259a6ed8D2BC0Ade097DEb7',
  //     assetViewer: '0xaDbB07E36874c08ad663e3880CFBd38e9704F98d',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'arbitrum',
  //   coingecko: {
  //     chainName: 'arbitrum-one',
  //     categoryId: 'arbitrum-ecosystem',
  //   },
  //   zapper: {
  //     network: 'arbitrum',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Arbitrum Ecosystem'],
  //   },
  //   wethAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  //   explorer: 'https://arbiscan.io/',
  //   rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  //   wsUrl: IS_DEV
  //     ? 'wss://apis.ankr.com/wss/c8599cd6d29742ceaa82374a87a73c36/43250602f649ba850025b2d48a351072/arbitrum/full/main'
  //     : 'wss://nameless-divine-star.arbitrum-mainnet.quiknode.pro/08a17331bd7e75aac05cba26a10771b84ecd4765/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'ETH',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.ARBITRUM),
  //   },
  //   stables: [
  //     '0x47dBAFd10ba52B88148bC6a20999cbB098AFa593',
  //     '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  //     '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
  //     '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.OPTIMISM,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.OPTIMISM,
  //   name: 'Optimism',
  //   imgURL: '/images/icons/chains/ic-optimism.svg',
  //   contracts: {
  //     parametersStorage: '0x6764b0Ed665F60BBECAfA4Fa697C2851612EEA65',
  //     borrowModule01: '0x4676E053d6ea1E907aAf3bD69a2D81462Eba0e00',
  //     assetViewer: '0xcb034A4B45905f03179ABcE17f15a678d88dA4c8',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'optimism',
  //   coingecko: {
  //     chainName: 'optimistic-ethereum',
  //     categoryId: 'ethereum-ecosystem',
  //   },
  //   zapper: {
  //     network: 'optimism',
  //   },
  //   wethAddress: '0x4200000000000000000000000000000000000006',
  //   explorer: 'https://optimistic.etherscan.io/',
  //   rpcUrls: ['https://mainnet.optimism.io'],
  //   wsUrl: IS_DEV
  //     ? 'wss://optimism-mainnet.public.blastapi.io'
  //     : 'wss://fluent-flashy-card.optimism.quiknode.pro/f6b70c62f2dd2b1e0c303e569e2b82ee7cb868ec/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'ETH',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.OPTIMISM),
  //   },
  //   stables: [
  //     '0x51D08eB1012D1c67400a0Abac40EA1987Fdd07eC',
  //     '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
  //     '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
  //     '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.POLYGON,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.POLYGON,
  //   name: 'Polygon',
  //   imgURL: '/images/icons/chains/ic-polygon.svg',
  //   contracts: {
  //     parametersStorage: '0xB502208B09889390C576BdA3f2c8d78B6C0d9b37',
  //     borrowModule01: '0x68628f2294B5e1e7BED8bB5Ab9351d7cd3f019A0',
  //     assetViewer: '0x7057f74f59d5AC27b88d0b2Ba3E8c98bE0cadc44',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //     nftsCollections: {
  //       trump: '0x24a11e702cd90f034ea44faf1e180c0c654ac5d9',
  //       galxeHoliday2022: '0x6c97ef47d60b35f858ad04c4b4f649747e00a417',
  //       lensProtocolProfiles: '0xdb46d1dc155634fbc732f92e853b10b288ad5a1d',
  //       voxies: '0xfbe3ab0cbfbd17d06bdd73aa3f55aaf038720f59',
  //       polygonpunks: '0x9498274b8c82b4a3127d67839f2127f2ae9753f4',
  //       theSmurfsSocietyAccessCrystals: '0xbac7e3182bb6691f180ef91f7ae4530abb3dc08d',
  //       voxieTacticsItems: '0x8f8e18dbebb8ca4fc2bc7e3425fcdfd5264e33e8',
  //       deraceHorses: '0x9d77cb4d8371736e2a2b2bfaa677b7841cdc8fc1',
  //       polygonapeyachtclub: '0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d',
  //       cryptokhat: '0x098e1bc661398ed6858626ba5750e56ce03f9072',
  //       cryptusXCrypton2023: '0xbebe08aaeb53b29ee3e8ea10bcb4fe64e7d3a536',
  //       wfwolfpack: '0xce8a6e03e6996f259191a18c4e2aa398319b04e9',
  //       piratenationpolygon: '0x687e4d88c45eba1aefb0ace6d127d038e7235943',
  //       theredvillagechampions: '0x8a57d0cb88e5dea66383b64669aa98c1ab48f03e',
  //       mrcryptoByRacksmafia: '0xef453154766505feb9dbf0a58e6990fd6eb66969',
  //       chumbivalleyofficial: '0x5492ef6aeeba1a3896357359ef039a8b11621b45',
  //     },
  //   },
  //   trustWalletPath: 'polygon',
  //   rarify: {
  //     chainName: 'polygon',
  //   },
  //   coingecko: {
  //     chainName: 'polygon-pos',
  //     categoryId: 'polygon-ecosystem',
  //   },
  //   zapper: {
  //     network: 'polygon',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Polygon Ecosystem'],
  //   },
  //   wethAddress: '0x4200000000000000000000000000000000000006',
  //   ethMockAddress: '0x0000000000000000000000000000000000001010',
  //   explorer: 'https://polygonscan.com/',
  //   rpcUrls: ['https://polygon-rpc.com/'],
  //   wsUrl: IS_DEV
  //     ? 'wss://rpc-mainnet.matic.network'
  //     : 'wss://proportionate-dry-tent.matic.quiknode.pro/99b04f6ba81d26f8e066191ca3d2a38405dfa1c3/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'MATIC',
  //       symbol: 'MATIC',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.POLYGON),
  //   },
  //   stables: [
  //     '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  //     '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  //     '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
  //     '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.AURORA,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.AURORA,
  //   name: 'Aurora',
  //   imgURL: '/images/icons/chains/ic-aurora.svg',
  //   contracts: {
  //     parametersStorage: '0x7546FBaDC41208Dd4713E7c0353c9263CBBF702C',
  //     borrowModule01: '0xb47F2Ca3e0c78EB0e06ebf1293b451d2cf97087d',
  //     assetViewer: '0x6D06724AD0A7F3734498c897381660c672e7ae75',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'aurora',
  //     categoryId: 'ethereum-ecosystem',
  //   },
  //   zapper: {
  //     network: 'aurora',
  //   },
  //   wethAddress: '0xc9bdeed33cd01541e1eed10f90519d2c06fe3feb',
  //   explorer: 'https://aurorascan.dev/',
  //   rpcUrls: ['https://mainnet.aurora.dev'],
  //   wsUrl: 'wss://mainnet.aurora.dev',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'ETH',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.AURORA),
  //   },
  //   stables: [
  //     '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
  //     '0x4988a896b1227218e4a686fde5eabdcabd91571f',
  //     '0xe3520349f477a5f6eb06107066048508498a291b',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.BOBA,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.BOBA,
  //   name: 'Boba',
  //   contracts: {
  //     parametersStorage: '0x3f64d234CeD2817c72f3a70ed7476c8622ffb4ff',
  //     borrowModule01: '0x32D6c1d3E5A11dc118eC9681A6d2573CAc347B6b',
  //     assetViewer: '0xb075420578c0DfA8C341C9632f76c2100F345283',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'boba',
  //     categoryId: 'ethereum-ecosystem',
  //   },
  //   wethAddress: '',
  //   explorer: 'https://blockexplorer.boba.network/',
  //   rpcUrls: ['https://mainnet.boba.network/'],
  //   wsUrl: 'wss://wss.mainnet.boba.network/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'ETH',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.BOBA),
  //   },
  //   stables: [
  //     '0x5de1677344d3cb0d7d465c10b72a8f60699c062d',
  //     '0x66a2a913e447d6b4bf33efbec43aaef87890fbbc',
  //     '0xf74195bb8a5cf652411867c5c2c5b8c2a402be35',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.MOONBEAM,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.MOONBEAM,
  //   name: 'Moonbeam',
  //   imgURL: '/images/icons/chains/ic-moonbeam.svg',
  //   contracts: {
  //     parametersStorage: '0x29be5b554e3E8F94CdDd2639EF96242eC032285A',
  //     borrowModule01: '0x1848D8cC1609fFaC7a50a6d754a172EfE317D2E2',
  //     assetViewer: '0x3657e92099D42D828fe37aDFb36eEE7e90d157a2',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'moonbeam',
  //     categoryId: 'moonbeam-ecosystem',
  //   },
  //   wethAddress: '0xacc15dc74880c9944775448304b263d191c6077f',
  //   explorer: 'https://moonscan.io/',
  //   rpcUrls: ['https://rpc.api.moonbeam.network'],
  //   wsUrl: 'wss://wss.api.moonbeam.network',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'GLMR',
  //       symbol: 'GLMR',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.MOONBEAM),
  //   },
  //   stables: [
  //     '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
  //     '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
  //     '0x765277eebeca2e31912c9946eae1021199b39c61',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.OKC,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.OKC,
  //   name: 'OKC',
  //   contracts: {
  //     parametersStorage: '0xA35d760Af6BA0538564D0a8C6D7390f4Cdd8B56F',
  //     borrowModule01: '0x6b50608351866bDC5C8612F433341954Cf41CA98',
  //     assetViewer: '0xBDA6F9d3F7f0512324A9305F321Ad871Ba8C5617',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   trustWalletPath: 'okc',
  //   wethAddress: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
  //   explorer: 'https://www.oklink.com/okc',
  //   rpcUrls: ['https://exchainrpc.okex.org'],
  //   wsUrl:
  //     'wss://apis.ankr.com/wss/be7845f36a83429fbc392a9c9ad7707b/43250602f649ba850025b2d48a351072/okxchain/full/main',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'OKT',
  //       symbol: 'OKT',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.OKC),
  //   },
  //   stables: [
  //     '0x382bb369d343125bfb2117af9c149795c6c65c50',
  //     '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.MOONRIVER,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.MOONRIVER,
  //   name: 'Moonriver',
  //   imgURL: '/images/icons/chains/ic-moonriver.svg',
  //   contracts: {
  //     parametersStorage: '0x4a5A73028Fa100aCe58Dd9C438245d9FdBf88bc5',
  //     borrowModule01: '0x547eCCc358845C49102CF9BB1C91bBa671A4Ce12',
  //     assetViewer: '0xE92f4D9F66175f69A90060cad92444E1df015859',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'moonriver',
  //     categoryId: 'moonriver-ecosystem',
  //   },
  //   zapper: {
  //     network: 'moonriver',
  //   },
  //   wethAddress: '0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
  //   explorer: 'https://moonriver.moonscan.io/',
  //   rpcUrls: ['https://rpc.api.moonriver.moonbeam.network'],
  //   wsUrl: 'wss://wss.api.moonriver.moonbeam.network',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'MOVR',
  //       symbol: 'MOVR',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.MOONRIVER),
  //   },
  //   stables: [
  //     '0xb44a9b6905af7c801311e8f4e76932ee959c663c',
  //     '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
  //     '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.HECO,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.HECO,
  //   name: 'Heco',
  //   imgURL: '/images/icons/chains/ic-heco.svg',
  //   contracts: {
  //     parametersStorage: '0x3779F1c123aFD9de977f86fccc62e8aE94b80b11',
  //     borrowModule01: '0x44471d1789C52a10117c16c06a9fE26fCAa66490',
  //     assetViewer: '0x6Af36cD9a3fB12A868d41AcEc43aAf14882Ad005',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'heco',
  //     categoryId: 'heco-chain-ecosystem',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Heco Ecosystem'],
  //   },
  //   wethAddress: '0x6f259637dcD74C767781E37Bc6133cd6A68aa161',
  //   explorer: 'https://www.hecoinfo.com/',
  //   rpcUrls: ['https://http-mainnet.hecochain.com/'],
  //   wsUrl: 'wss://ws-mainnet.hecochain.com',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'HuobiToken',
  //       symbol: 'HT',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.HECO),
  //   },
  //   stables: [
  //     '0xa71edc38d189767582c38a3145b5873052c3e47a',
  //     '0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b',
  //     '0x3d760a45d0887dfd89a2f5385a236b29cb46ed2a',
  //   ],
  // },
  // {
  //   maxLogQuery: 98,
  //   id: CHAIN_ID.OASIS,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.OASIS,
  //   name: 'Oasis',
  //   imgURL: '/images/icons/chains/ic-oasis.svg',
  //   contracts: {
  //     parametersStorage: '0x864df2528eF58ac881a425f7811783543D4c271f',
  //     borrowModule01: '0x44F5f70c6B6205db695309fd81B4a82e71368134',
  //     assetViewer: '0x864c35120BFb2c45143354896d9CA13260Fd8C30',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'oasis',
  //     categoryId: 'oasis-network',
  //   },
  //   wethAddress: '0xC3B6Dc47b28c5E62286C2cb59685F6cEF62716B6',
  //   explorer: 'https://explorer.emerald.oasis.dev',
  //   rpcUrls: ['https://emerald.oasis.dev'],
  //   wsUrl: 'wss://emerald.oasis.dev/ws',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'Oasis.Network',
  //       symbol: 'ROSE',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.OASIS),
  //   },
  //   stables: [
  //     '0x6Cb9750a92643382e020eA9a170AbB83Df05F30B',
  //     '0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.CELO,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.CELO,
  //   name: 'Celo',
  //   imgURL: '/images/icons/chains/ic-celo.svg',
  //   contracts: {
  //     parametersStorage: '0x03D448B704F52eE966De0770708ea226640e6c26',
  //     borrowModule01: '0x9627385D21387e15c3F306eFc4247C0123f75Dd5',
  //     assetViewer: '0x2CAdE59D443023fe27656014DD346f70b00fEA34',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'celo',
  //     categoryId: 'celo-ecosystem',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Celo Ecosystem'],
  //   },
  //   zapper: {
  //     network: 'celo',
  //   },
  //   wethAddress: '0x471ece3750da237f93b8e339c536989b8978a438',
  //   explorer: 'https://explorer.celo.org',
  //   rpcUrls: [
  //     'https://virulent-lingering-ensemble.celo-mainnet.discover.quiknode.pro/cce5a73f8d0464061156877b44efc561d74338f4/',
  //   ],
  //   wsUrl:
  //     'wss://virulent-lingering-ensemble.celo-mainnet.discover.quiknode.pro/cce5a73f8d0464061156877b44efc561d74338f4/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'Celo',
  //       symbol: 'CELO',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.CELO),
  //   },
  //   stables: [
  //     '0x765de816845861e75a25fca122bb6898b8b1282a',
  //     '0xef4229c8c3250c675f21bcefa42f58efbff6002a',
  //     '0xe4fe50cdd716522a56204352f00aa110f731932d',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.CRONOS,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.CRONOS,
  //   name: 'Cronos',
  //   contracts: {
  //     parametersStorage: '0x72099782D2aB3Bb9b650eAD67970D817A272F4ac',
  //     borrowModule01: '0x14789A4e84dC814CE90010De479e535633f9b13f',
  //     assetViewer: '0x7B50c34069c5E007df84e2CCd831365e9BF396bf',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'cronos',
  //     categoryId: 'cronos-ecosystem',
  //   },
  //   coinmarketcap: {
  //     categoryId: CoinmarketcapCategory['Cronos Ecosystem'],
  //   },
  //   zapper: {
  //     network: 'cronos',
  //   },
  //   wethAddress: '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
  //   explorer: 'https://cronoscan.com/',
  //   rpcUrls: ['https://rpc.cronos.org/', 'https://rest.cronos.org/', 'https://evm.cronos.org/'],
  //   wsUrl: 'wss://cro.getblock.io/mainnet/',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'Cronos Coin',
  //       symbol: 'CRO',
  //       decimals: 8,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.CRONOS),
  //   },
  //   stables: [
  //     '0x66e428c3f67a68878562e79a0234c1f83c208770',
  //     '0xc21223249ca28397b4b6541dffaecc539bff0c59',
  //     '0xf2001b145b43032aaf5ee2884e456ccd805f677d',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.KLAYTN,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.KLAYTN,
  //   name: 'Klaytn',
  //   imgURL: '/images/icons/chains/ic-klaytn.svg',
  //   contracts: {
  //     parametersStorage: '0x86055E9870c87972f5508adbF4ae59a6Fc2D98b6',
  //     borrowModule01: '0x71Fc539d79a06c263C6dC437d4381Aa512F61f33',
  //     assetViewer: '0x4D4098D30861e4b563e544012D165E051b438342',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   coingecko: {
  //     chainName: 'klay-token',
  //     categoryId: 'klaytn-ecosystem',
  //   },
  //   wethAddress: '0xe4f05a66ec68b54a58b17c22107b02e0232cc817',
  //   explorer: 'https://scope.klaytn.com/',
  //   rpcUrls: ['https://public-node-api.klaytnapi.com/v1/cypress'],
  //   wsUrl: 'wss://public-en-cypress.klaytn.net/ws',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'KLAY',
  //       symbol: 'KLAY',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.KLAYTN),
  //   },
  //   stables: [
  //     '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167',
  //     '0x754288077d0ff82af7a5317c7cb8c444d421d103',
  //     '0x078db7827a5531359f6cb63f62cfa20183c4f10c',
  //   ],
  // },
  // {
  //   id: CHAIN_ID.SYSCOIN,
  //   urlRepresentation: CHAIN_URL_REPRESENTATION.SYSCOIN,
  //   name: 'Syscoin',
  //   imgURL: '/images/icons/chains/ic-syscoin.svg',
  //   contracts: {
  //     parametersStorage: '0x7989C52b8e10663025cDa93Dc6c0B653E6f60fE2',
  //     borrowModule01: '0x8664BEf8026230fd2aaB4bd78C4C1Ee5C3f6B46f',
  //     assetViewer: '0x3d259A822AF4528F1672680D5568bD43c0D2425b',
  //     bobHubOracle: '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA',
  //   },
  //   wethAddress: '0x747b1223b23e53070c54df355fac2e198aa54708',
  //   explorer: 'https://explorer.syscoin.org/',
  //   rpcUrls: ['https://rpc.syscoin.org'],
  //   wsUrl: 'wss://rpc.syscoin.org/wss',
  //   paramsForAdding: {
  //     nativeCurrency: {
  //       name: 'Syscoin',
  //       symbol: 'SYS',
  //       decimals: 18,
  //     },
  //     hexId: Web3.utils.toHex(CHAIN_ID.SYSCOIN),
  //   },
  //   stables: [
  //     '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
  //     '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
  //     '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
  //   ],
  // },
  ...TEST_CHAINS,
];

const onlySupported = (nets: ChainConfig[]) =>
  nets.filter((net) => SUPPORTED_NETWORKS.includes(net.id));

export const NETWORK_LIST = Object.freeze(onlySupported(CHAIN_LIST));

export const NETWORK_LIST_FOR_ERC_20 = Object.freeze(onlySupported(CHAIN_LIST));
export const NETWORK_LIST_FOR_ERC_721 = Object.freeze(
  onlySupported(CHAIN_LIST).filter((el) => el.contracts.nftsCollections),
);
