import { ToastType } from 'react-hot-toast';

import { borderRadius, color } from 'src/uiKit/theme';
import styled from 'styled-components';

export const ToastContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: auto 1fr;
  font-size: 3rem;
`;

export const Icon = styled.div<{ status: ToastType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 3rem;
  height: 3rem;
  border-radius: ${borderRadius.round};
  color: ${(p) =>
          p.status === 'success' ? color.white
                  : p.status === 'loading' ? color.black
                          : color.black};
  background-color: ${(p) =>
          p.status === 'success' ? color.green
                  : p.status === 'loading' ? color.white
                          : color.white}
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 2rem;
  color: ${color.gray};
  cursor: pointer;
`;
