import React, { useEffect, useState } from 'react'

import { UiCheckboxLabel } from './styled'

export const UiCheckbox: React.FC<{
  label: string
  value: boolean
  onChange: () => void
  style?: React.CSSProperties
}> = ({ label, value, onChange, style }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <UiCheckboxLabel style={style}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span>{label}</span>
    </UiCheckboxLabel>
  )
}
