import React from 'react';
import { ClipIcon, LinkContainer } from './styled';

import Clip from '../../assets/images/icons/ic-clip.png';

type LinkProps = {
  href: string;
  title?: string;
};

export const Link: React.FC<LinkProps> = ({ href, title = 'View on Explorer' }) => {
  return (
    <LinkContainer href={href} target="_blank" rel="noopener noreferrer">
      {title}
      <ClipIcon src={Clip} alt="clip" />
    </LinkContainer>
  );
};
