import styled from 'styled-components';

export const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 5rem;
`

export const StyledMessage = styled.div`
  font-size: 2.5rem;
`