export const TOKENS_LIST_RINKEBY = [
  '0xE3993D6107DE4707891AFC01a8dbB568F9a250fa',
  '0xb6F1CBA91AAed2EcDd80896198ffcB1C17B2142f',
  '0x2ed865c50384EEE2b0b65D1Fb2d0287f2627d6a2',
  '0x058987ffFf443641CB7f335340E3B35fDFC39540',
].map((address) => address.toLowerCase());

export const NOT_SUPPORTED_TOKENS = [
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  '0x0d88ed6e74bbfd96b831231638b66c05571e824f',
  '0x9b99cca871be05119b2012fd4474731dd653febe',
  '0x0000000000000000000000000000000000000000',
];
