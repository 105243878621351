import { getChainConfig } from 'src/store/chainStore'
import abi from 'src/contracts/abis/borrowModule01.json'
import web3Core from 'web3-core'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackBorrowModule01AuctionCancelled = ({ callback, loanId, borrower }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, borrower: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'AuctionCancelled',
    params: { loanId, borrower },
    topic: '0x10ac9f0bb365b5d22d7bec500408692f23fdf83eadfec71615ef88b4c1134f0e',
    namespace: 'borrowModule01',
  })

export const trackBorrowModule01AuctionInterestRateMaxUpdated = ({ callback, loanId, borrower }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, borrower: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'AuctionInterestRateMaxUpdated',
    params: { loanId, borrower },
    topic: '0x9c0777b36d2e766597aefb484e11717a6cb888fafa68f450984d7fd36a2abe5b',
    namespace: 'borrowModule01',
  })

export const trackBorrowModule01AuctionStarted = ({ callback, loanId, borrower }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, borrower: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'AuctionStarted',
    params: { loanId, borrower },
    topic: '0x16da476d7265fc95576888b93de4fa4849d6cea1228235887f569c6530ddfec1',
    namespace: 'borrowModule01',
  })

export const trackBorrowModule01LoanIssued = ({ callback, loanId, lender }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, lender: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'LoanIssued',
    params: { loanId, lender },
    topic: '0xc50b8be185ff56251d3594a960bda150e5d529b8ba3933c226c8cce911140822',
    namespace: 'borrowModule01',
  })

export const trackBorrowModule01LoanLiquidated = ({ callback, loanId, liquidator }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, liquidator: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'LoanLiquidated',
    params: { loanId, liquidator },
    topic: '0x73de9acc561f27528ab0a3b5dd63fefb4e59f95575891299a6f862a787798176',
    namespace: 'borrowModule01',
  })

export const trackBorrowModule01LoanRepaid = ({ callback, loanId, borrower }: { callback: ([event]: [web3Core.Log]) => void, loanId: string, borrower: string }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig()?.contracts.borrowModule01,
    abi,
    name: 'LoanRepaid',
    params: { loanId, borrower },
    topic: '0xe69d7686a8bc68278b8c5419579f91716b3ef2ac2fac0d8cf80b8011f8f458a4',
    namespace: 'borrowModule01',
  })
