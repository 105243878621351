import styled from 'styled-components';

import { color } from '../../../uiKit/theme';

export const ConnectStep = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  :not(:last-child) {
    margin-bottom: 5rem;
  }
`;

export const ConnectStepNum = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: ${color.grayBg};
  color: #7c7c7c;
  margin-right: 1.2rem;
  flex-shrink: 0;
`;

export const ConnectStepTtl = styled.span`
  display: block;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  margin-top: -0.4rem;
  color: ${color.gray};

  a {
    color: ${color.black};
  }
`;

export const ConnectStepIcon = styled.img<{ disabled: boolean }>`
  width: 6rem;
  display: inline-block;
  cursor: pointer;

  :not(:last-child) {
    margin-right: 1.2rem;
  }
`;

export const ModalConnectContainer = styled.div<{ termsAccepted: boolean }>`
  ${(p) =>
    !p.termsAccepted &&
    `
    * {
      color: ${color.gray};
    }
  `}
`;
