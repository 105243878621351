import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { coingeckoApi } from 'src/api/coingecko';
import { getTokensPrices } from 'src/api/zapper/zapper';
import { getChainConfig, isRinkeby } from 'src/store/chainStore';
import { Token } from 'src/types/tokens';
import { getFullTokensListFromCoingecko } from 'src/utils/coingecko';
import { isAddressesEq } from 'src/utils/compareAddresses';
import { withTimeout } from 'src/utils/delay';

export type TokenPrice = {
  address: string;
  price: string;
  source?: Token['source'];
};

const log = debug('store:pricesStore');

export const pricesStore = {
  prices: new BehaviorSubject<Map<string, TokenPrice>>(new Map()),
  fetchingPrices: false,

  getTokenPrice(address?: string) {
    if (!address)
      return {
        address: 'N/A',
        price: '',
        source: undefined,
      };
    return (
      this.prices.getValue().get(address.toLowerCase()) || {
        address: address.toLowerCase(),
        price: '',
        source: undefined,
      }
    );
  },

  async getAndSetPricesFromZapper() {
    if (!getChainConfig()?.zapper?.network) return;
    getTokensPrices().then((resp) => {
      const addresses: string[] = [];
      const prices: string[] = [];
      log('getAndSetPricesFromZapper resp', resp);
      resp.forEach((token) => {
        if (!token.price) log('getAndSetPricesFromZapper price not presented', token);
        addresses.push(token.address.toLowerCase());
        prices.push(token.price?.toString());
      });
      pricesStore.setTokensPrices(addresses, prices, 'zapper');
    });
  },

  setTokensPrices(
    addresses: string[],
    prices: string[],
    source?: Token['source'] | Token['source'][],
  ) {
    const newPrices = new Map();

    addresses.forEach((address, i) => {
      const token = this.prices.getValue().get(address.toLowerCase());
      if (token) return;

      newPrices.set(address.toLowerCase(), {
        address,
        price: prices[i],
        source: Array.isArray(source) ? source[i] : source,
      });
    });

    log('setTokensPrices fired', newPrices);

    this.prices.next(new Map([...this.prices.getValue(), ...newPrices]));
  },

  setTokenPrice(address: string, price: string, source?: Token['source']) {
    const token = this.prices.getValue().get(address.toLowerCase());
    if (token) {
      return;
    }

    this.prices.next(
      new Map([...this.prices.getValue(), [address.toLowerCase(), { address, price, source }]]),
    );
  },

  async fetchTokensPrice(tokensAddresses: string[]): Promise<void> {
    log('fetchTokensPrice fired', tokensAddresses);

    if (!isRinkeby()) {
      if (this.fetchingPrices)
        await withTimeout(1000, () => pricesStore.fetchTokensPrice(tokensAddresses));

      this.fetchingPrices = true;

      try {
        const tokensList = await getFullTokensListFromCoingecko();

        const tokens = tokensAddresses
          .filter((address) => !this.prices.getValue().get(address.toLowerCase()))
          .map((address) => {
            const token = tokensList?.find((token) =>
              isAddressesEq(token.platforms.ethereum || '', address),
            );
            return { ...token, address };
          });

        if (tokens.length === 0) {
          this.fetchingPrices = false;
          return;
        }

        log('fetchTokensPrice tokens', tokens);

        const ids = tokens.filter((token) => token.id).map((token) => token.id) as string[];

        if (ids.length === 0) {
          this.fetchingPrices = false;
          return;
        }

        const resp = await coingeckoApi.getTokensPrices(ids);

        this.fetchingPrices = false;

        const prices = new Map(
          tokens.map((token) =>
            token.id ? [token.address, resp.data[token.id].usd] : [token.address, '0'],
          ),
        );

        pricesStore.setTokensPrices([...prices.keys()], [...prices.values()], 'coingecko');
      } catch (e) {
        console.error(e);
        this.fetchingPrices = false;
      }
    }
    this.fetchingPrices = false;
  },
};

pricesStore.prices.subscribe((res) => {
  log('pricesStore.prices.subscribe', res);
  log('test', res.get('0xdd1f245d2920c5e144417ba2e59cf094bd615a91'));
});
