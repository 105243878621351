import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3Controller } from 'src/providers/Web3CtxProvider';
import { Modal } from 'src/uiKit/Modal';
import { color } from 'src/uiKit/theme';
import { UiCheckbox } from 'src/uiKit/UiCheckbox';
import { isMobile } from 'src/utils/helpers/isMobile';
import {
  ConnectStep,
  ConnectStepIcon,
  ConnectStepNum,
  ConnectStepTtl,
  ModalConnectContainer,
} from './styled';

const termsAcceptedFromLS = JSON.parse(localStorage.getItem('termsAccepted') || 'false');
const citizenConfirmFromLS = JSON.parse(localStorage.getItem('citizenConfirm') || 'false');

export const ConnectWalletModal: React.FC = () => {
  const [termsAccepted, setTermAccepted] = useState(termsAcceptedFromLS);
  const [citizenConfirm, setCitizenConfirm] = useState(citizenConfirmFromLS);
  const setModal = useSetModal();
  const { connect } = useWeb3Controller();

  function termOfUseClickAction() {
    localStorage.setItem('termsAccepted', (!termsAccepted).toString());
    setTermAccepted(!termsAccepted);
  }

  function citizenConfirmClickAction() {
    localStorage.setItem('citizenConfirm', (!citizenConfirm).toString());
    setCitizenConfirm(!citizenConfirm);
  }

  async function handleConnect(type: string) {
    if (termsAccepted) {
      if (type === 'walletconnect') setModal(null);
      await connect(type);
      setModal(null);
    }
  }

  return (
    <Modal onCloseModal={() => setModal(null)} title="Connect Wallet" forConnect>
      <ModalConnectContainer termsAccepted={termsAccepted && citizenConfirm}>
        <ConnectStep>
          <ConnectStepNum>1</ConnectStepNum>
          <div>
            <ConnectStepTtl>
              Accept{' '}
              <Link target="_blank" to="/knowledge-center?tab=terms">
                Terms of use
              </Link>
            </ConnectStepTtl>
            <UiCheckbox
              label="I have read and accept the terms."
              value={termsAccepted}
              onChange={termOfUseClickAction}
              style={{ color: color.gray }}
            />
          </div>
        </ConnectStep>
        <ConnectStep>
          <ConnectStepNum>2</ConnectStepNum>
          <UiCheckbox
            label="I hereby confirm that I am not a citizen or resident of the following countries: Afghanistan, American Samoa, U.S. Virgin Islands, Guam, Iran, Iraq, Yemen, Libya, Pakistan, State of Palestine, Puerto Rico, Somalia, North Korea, The Northern Mariana Islands, The United States of America, Syria, Russian Federation, Republic of Belarus, Transnistria, Turkish Republic of Northern Cyprus, Western Sahara, Federal Republic of Ambazonia, Kosovo, Ukraine, Sudan, Venezuela, Democratic Republic of the Congo, Singapore, Cuba, Canada, China, Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden."
            value={citizenConfirm}
            onChange={citizenConfirmClickAction}
            style={{ color: color.gray }}
          />
        </ConnectStep>
        <ConnectStep>
          <ConnectStepNum>3</ConnectStepNum>
          <div>
            <ConnectStepTtl>Choose Wallet</ConnectStepTtl>
            <div>
              {!isMobile() && (
                <ConnectStepIcon
                  onClick={() => handleConnect('injected')}
                  disabled={termsAccepted && citizenConfirm}
                  src={
                    termsAccepted && citizenConfirm
                      ? '/images/icons/ic-metamask.svg'
                      : '/images/icons/ic-metamask-disabled.svg'
                  }
                />
              )}
              <ConnectStepIcon
                onClick={() => handleConnect('walletconnect')}
                disabled={termsAccepted && citizenConfirm}
                src={
                  termsAccepted && citizenConfirm
                    ? '/images/icons/ic-walletconnect.svg'
                    : '/images/icons/ic-walletconnect-disabled.svg'
                }
              />
            </div>
          </div>
        </ConnectStep>
      </ModalConnectContainer>
    </Modal>
  );
};
