import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppType } from 'src/hooks/useAppType';
import { useCurrentChainsList } from 'src/hooks/useCurrentChainsList';

import { useSubject } from 'src/hooks/useSubject';
import { chainStore } from 'src/store/chainStore';
import { walletStore } from 'src/store/walletStore';
import { Spinner } from 'src/uiKit/Spinner';
import {
  ChainSelectorContainer,
  ChainSelectorCurrent,
  ChainSelectorOption,
  ChainSelectorOptions,
} from './styled';

export const ChainSelector: React.FC = () => {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const chainsList = useCurrentChainsList();
  const tokenType = useAppType();

  const walletConnecting = useSubject(walletStore.isConnecting);
  const chainConfig = useSubject(chainStore.chainConfig);

  function documentClickListener() {
    setShowOptions(false);
  }

  useEffect(() => {
    if (!showOptions) return;

    document.addEventListener('click', documentClickListener);
    return () => document.removeEventListener('click', documentClickListener);
  }, [showOptions]);

  return (
    <ChainSelectorContainer onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
      <ChainSelectorCurrent
        disabled={chainsList.length === 1}
        showOptions={showOptions}
        onClick={() => !walletConnecting && setShowOptions(!showOptions)}
      >
        {walletConnecting || !chainConfig ? <Spinner /> : chainConfig.name}
      </ChainSelectorCurrent>
      {showOptions && (
        <ChainSelectorOptions>
          {chainsList
            .filter((chain) => chain.id !== chainConfig?.id)
            .map((chain) => (
              <ChainSelectorOption
                key={chain.id}
                onClick={() => {
                  setShowOptions(false);
                  navigate(`/${chain.urlRepresentation}/${tokenType}`);
                }}
              >
                {chain.name}
              </ChainSelectorOption>
            ))}
        </ChainSelectorOptions>
      )}
    </ChainSelectorContainer>
  );
};
