import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AppType } from 'src/constants/appType';
import { useAppType } from 'src/hooks/useAppType';
import { MenuSection } from './MenuSection';

export const MyPositions = () => {
  const appType = useAppType();
  const { chainUrlRepresentation } = useParams();

  const myPositions20 = useMemo(
    () => [
      {
        id: 'liveOffers',
        name: 'Active orders',
        to: `/${chainUrlRepresentation}/${appType}/my-positions/live-offers`,
      },
      {
        id: 'borrowing',
        name: 'Borrowed',
        to: `/${chainUrlRepresentation}/${appType}/my-positions/borrowing`,
      },
      {
        id: 'lending',
        name: 'Lended',
        to: `/${chainUrlRepresentation}/${appType}/my-positions/lending`,
      },
    ],
    [appType, chainUrlRepresentation],
  );

  const myPositions721 = useMemo(
    () => [
      ...myPositions20,
      {
        id: 'myNfts',
        name: 'My NFT Collections',
        to: `/${chainUrlRepresentation}/${appType}/my-positions/my-nfts`,
      },
    ],
    [appType, chainUrlRepresentation, myPositions20],
  );

  return (
    <MenuSection
      title="My positions"
      links={appType === AppType.erc20 ? myPositions20 : myPositions721}
    />
  );
};
