import { createContext } from 'react';

export type DaysFilterType = '1d - 1m' | '1m - 3m' | '3m - 6m' | '6m - 1y' | '1y +';
export type LoanAmountType = '< $1000' | '$1000 - $10000' | '$10000 - $100000' | '> $100000';
export type InterestRateType = '0,1% - 10%' | '10% - 20%' | '20% +';

export type FiltersType = {
  loanTerm: DaysFilterType[];
  loanAmount: LoanAmountType[];
  interestRate: InterestRateType[];
};

interface FilterContextProps {
  filters: FiltersType;
  setFilters: (val: FiltersType) => void;
  showFilters: boolean;
  setShowFilters: (val: boolean) => void;
}

export const FilterContext = createContext<FilterContextProps>({
  filters: {
    loanTerm: [],
    loanAmount: [],
    interestRate: [],
  },
  setFilters: () => {},
  showFilters: true,
  setShowFilters: () => {},
});
