import { Link } from 'react-router-dom';
import { base, color } from 'src/uiKit/theme';
import styled from 'styled-components';

export const MenuItem = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop)
})<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => (p.isActive ? color.black : 'inherit')};
  text-decoration: ${(p) => (p.isActive ? 'underline' : 'none')};
  font-size: ${base.base5};
  font-weight: 700;
  cursor: pointer;
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;