import axios from 'axios';
import debug from 'debug';
import { PresentedBalancePayload, ZapperPricesList } from 'src/api/zapper/zapperTypes';
import { ZapperNetwork } from 'src/constants/chain';
import { getChainConfig } from 'src/store/chainStore';

const log = debug('api:zapper');
const API_KEY = '711112e0-947e-4045-be37-c2106c18a55b';

const generateUrl = (addresses: string[], networks: ZapperNetwork[]) => {
  let url = `https://api.zapper.xyz/v2/balances/apps?`;

  addresses.forEach((address, _index) => {
    url += `addresses[]=${address}${_index === addresses.length - 1 ? '' : '&'}`;
  });

  if (networks.length) url += '&';

  networks.forEach((network, _index) => {
    url += `networks[]=${network}${_index === addresses.length - 1 ? '' : '&'}`;
  });

  url += `&api_key=${API_KEY}&bundled=true`;

  return encodeURI(url);
};

export const getUserBalancesFromZapper = (
  walletAddress: string,
  network: ZapperNetwork,
  callback: (data: PresentedBalancePayload) => void,
) => {
  const url = generateUrl([walletAddress], [network]);
  const eventSource = new EventSource(url);

  eventSource.addEventListener('open', () => {
    log('Open ...');
  });

  eventSource.addEventListener('error', (e) => {
    e && log('Error :', e);
  });

  eventSource.addEventListener('balance', ({ data }) => {
    const parsedDatas = JSON.parse(data) as PresentedBalancePayload;
    callback(parsedDatas);
  });

  eventSource.addEventListener('end', () => {
    // all datas received
    log('Close ...');
    eventSource.close();
  });
};

export const getTokensPrices = async () => {
  const url = `https://api.zapper.xyz/v2/prices?api_key=${API_KEY}&network=${
    getChainConfig()?.zapper?.network
  }`;

  try {
    const resp = await axios.get<ZapperPricesList>(url);
    return resp.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};
