export const Tick = (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2602 1.00242C10.6022 0.664578 11.1533 0.665863 11.4937 1.00628C11.8354 1.34799 11.8354 1.90201 11.4937 2.24372L11.4872 2.25019L5.38025 8.1375C4.89424 8.61962 4.11091 8.62083 3.62341 8.14112L0.512751 5.25019L0.506282 5.24372C0.164573 4.90201 0.164573 4.34799 0.506282 4.00628C0.845393 3.66717 1.3936 3.66459 1.73589 3.99855L4.4937 6.56156L10.2602 1.00242Z"
      fill="currentColor"
    />
  </svg>
);
