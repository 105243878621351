import debug from 'debug';
import eventTracker from 'src/contracts/websocketEventTracker';
import { auctionsParamsStore } from 'src/store/auctionsParamsStore';
import { auctionsStore } from 'src/store/auctionsStore';
import { blockStore } from 'src/store/blockStore';
import { chainStore, getChainId } from 'src/store/chainStore';
import { erc20Store } from 'src/store/erc20Store';
import { erc721Store } from 'src/store/erc721Store';
import { walletStore } from 'src/store/walletStore';
import {
  initializeBalanceTrackingForErc721,
  initializeBalanceTrackingForTokens,
} from 'src/utils/balances';
import { getChainConfigById } from 'src/utils/chain';
import { isAddressesEq } from 'src/utils/compareAddresses';

const log = debug('store:subscriptions');

export const initStoresSub = () => {
  log('initStoresSub fired');

  chainStore.appChainId.subscribe((appChainId) => {
    if (!appChainId) return;

    log('appChainId.subscribe fired: ', appChainId);

    const prevChain = chainStore.prevChainId.getValue();

    log('appChainId.subscribe prevChain: ', prevChain);

    if (prevChain === appChainId) {
      log('appChainId.subscribe chain is eq to prev one SKIP');
      return;
    }

    if (prevChain) eventTracker.clearSubscriptionsOfChain(prevChain);

    erc20Store.tokensLoading.next(true);

    chainStore.chainConfig.next(getChainConfigById(appChainId));
    chainStore.createNewWsProvider();
    chainStore.createNewWeb3WsProvider();
    blockStore.fetchAndTrackBlockForChain().then(() => {
      if (prevChain) auctionsStore.fetchAuctions(true);
    });
    auctionsParamsStore.init();
    chainStore.prevChainId.next(appChainId);
  });

  walletStore.address.subscribe((address) => {
    log('walletStore.address.subscribe fired', { address });
    const prevAddress = walletStore.prevAddress.getValue();

    if (isAddressesEq(prevAddress || '', address || '')) return;

    if (prevAddress) {
      eventTracker.clearSubscriptionsOfAddress(prevAddress, getChainId()).then(() => {
        log('walletStore.address.subscribe clearSubscriptionsOfAddress fired');
        if (!address) return;

        initializeBalanceTrackingForTokens(address, ([event]) => {
          if (!event) return;
          const tokenAddress = event.address;
          log('initializeBalanceTrackingForTokens callback fired', tokenAddress);
          erc20Store.balanceTrackingCallback(address, tokenAddress);
        });

        initializeBalanceTrackingForErc721(address, ([event]) => {
          if (!event) return;
          const tokenAddress = event.address;
          log('initializeBalanceTrackingForErc721 callback fired', event);
          erc721Store.balanceTrackingCallback(address, tokenAddress);
        });
      });
    } else if (address) {
      initializeBalanceTrackingForErc721(address, ([event]) => {
        if (!event) return;
        const tokenAddress = event.address;
        log('initializeBalanceTrackingForErc721 callback fired', event);
        erc721Store.balanceTrackingCallback(address, tokenAddress);
      });
      initializeBalanceTrackingForTokens(address, ([event]) => {
        if (!event) return;
        const tokenAddress = event.address;
        log('initializeBalanceTrackingForTokens callback fired', tokenAddress);
        erc20Store.balanceTrackingCallback(address, tokenAddress);
      });
    }

    log({ prevAddress, address });

    walletStore.prevAddress.next(address);
  });
};
