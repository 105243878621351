import { Links } from './styled';

export const SocialLinks = () => (
  <Links>
    <a href="https://twitter.com/unitprotocol" target="_blank" rel="noopener noreferrer">
      <img src="/images/icons/ic-twitter.svg" alt="soc icon" />
    </a>
    <a
      href="https://github.com/unitprotocol/borrow-module"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/images/icons/ic-github.svg" alt="soc icon" />
    </a>
    <a href="https://discord.gg/XZWEAKYR" target="_blank" rel="noopener noreferrer">
      <img src="/images/icons/ic-discord.svg" alt="soc icon" />
    </a>
  </Links>
);
