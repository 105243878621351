import styled from 'styled-components';

import { borderRadius, color, fontFamily } from '../theme';

export const UIButton = styled.button<{ small?: boolean; outlined?: boolean }>`
  flex-shrink: 0;
  background: ${(p) => (p.outlined ? color.white : color.black)};
  font-size: 2.5rem;
  ${fontFamily.maru}
  color: ${(p) => (p.outlined ? color.black : color.white)};
  cursor: pointer;
  border-radius: ${borderRadius.normal};
  border: 0.25rem solid ${(p) => (p.outlined ? color.black : color.black)};
  padding: 2rem;
  display: block;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    text-decoration: none;
  }

  &[disabled] {
    box-shadow: none;
    opacity: 0.6;
    cursor: unset;

    &:hover {
      box-shadow: none;
    }
  }
`;
