import { chainStore } from '../store/chainStore';
import { walletStore } from '../store/walletStore';
import { isChainSupported } from '../utils/chain';
import { useSubject } from './useSubject';

export const useChainStatus = () => {
  const walletAddress = useSubject(walletStore.address);
  const walletChain = useSubject(walletStore.chainId);
  const appChain = useSubject(chainStore.appChainId);

  const isWalletConnected = Boolean(walletAddress);
  const isChainsIdsAreEq = walletChain === appChain;
  const isWalletChainSupported = isChainSupported(walletChain);

  return { isWalletConnected, isChainsIdsAreEq, isWalletChainSupported };
};
