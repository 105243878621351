import { createGlobalStyle } from 'styled-components';

import { breakpoint, color, fontFamily } from './theme';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5rem);
  }
  
  html {
    ${fontFamily.inter}
    font-size: calc(100vw / 60);
    padding: 2.5em;
    background: ${color.grayBg};
    min-height: 100vh;
    @media (min-width: ${breakpoint.tablet}) {
      font-size: calc(100vw / 100);
    }
    @media (min-width: ${breakpoint.desktop}) {
      font-size: calc(100vw / 220);
    }
  }
  
  @font-face {
    font-family: 'Circe Regular';
    src: url('/fonts/Circe/Circe_Regular.woff2') format('woff2'),
         url('/fonts/Circe/Circe_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Circe Regular';
    src: url('/fonts/Circe/Circe_Bold.woff2') format('woff2'),
         url('/fonts/Circe/Circe_Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'GT Maru Black';
    src: url('/fonts/GT-Maru/GT-Maru-Black.woff') format('woff'),
         url('/fonts/GT-Maru/GT-Maru-Black.ttf') format('truetype'),
         url('/fonts/GT-Maru/GT-Maru-Black.woff2') format('woff2'),
         url("/fonts/GT-Maru/GT-Maru-Black.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  p {
    letter-spacing: 0.03em;
    line-height: 1.3em;
  }
  
  .scale-enter {
    opacity: 0;
    transform: scale(0.85) translateY(20%);
  }
  
  .scale-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: 200ms linear;
  }
  
  .scale-exit {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  
  .scale-exit-active {
    opacity: 0;
    transform: scale(0.85) translateY(20%);
    transition: 200ms linear;
  }

  .slide-in-enter {
    opacity: 0;
    transform: translateY(-100%);
  }

  .slide-in-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 200ms linear;
  }

  .slide-in-exit {
    opacity: 1;
    transition: 200ms linear;
  }

  .slide-in-exit-active {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms linear;
  }
  
  .fade-exit {
    opacity: 1;
    transition: opacity 200ms linear;
  }
  
  .fade-exit-active {
    opacity: 0;
  }
`;
