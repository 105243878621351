import debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import {
  getBorrowModule01GetActiveAuctions,
  getBorrowModule01GetUserLoans,
  getBorrowModule01Loans,
} from 'src/contracts/borrowmodule01/contractFunctions';
import {
  trackBorrowModule01AuctionCancelled,
  trackBorrowModule01AuctionInterestRateMaxUpdated,
  trackBorrowModule01AuctionStarted,
  trackBorrowModule01LoanIssued,
} from 'src/contracts/borrowmodule01/eventListeners';
import { getWeb3WsProvider } from 'src/store/chainStore';
import { Auction, AuctionFromChain, AuctionInfo, AuctionsInfoFromChain } from 'src/types/auctions';
import { formatAuction, formatAuctions } from 'src/utils/auctions';
import { fromBpToPercent } from 'src/utils/misc';
import web3Core from 'web3-core';

const log = debug('store:auctionsStore');

export const auctionsStore = {
  auctions: new BehaviorSubject<Map<string, Auction> | null>(null),
  auctionsFetched: false,
  trackingInitialized: false,

  async fetchAuctions(force = false) {
    if (this.auctionsFetched && !force) return;

    this.auctionsFetched = true;

    log('fetchAuctions fired');

    try {
      const auctionsInfo = (await getBorrowModule01GetActiveAuctions()) as AuctionsInfoFromChain;
      log('auctionsInfo:', auctionsInfo);
      const formattedAuctions = formatAuctions(auctionsInfo);
      log('formattedAuctions:', formattedAuctions);
      this.auctions.next(new Map(formattedAuctions.map((auction) => [auction.id, auction])));
      if (!this.trackingInitialized) this.startAuctionsTracking();
    } catch (e) {
      console.error(e);
      log('fault fetchAuctions');
    }
  },

  async fetchAuctionsForUser(walletAddress: string | null) {
    if (!walletAddress || this.auctions.getValue()) return;
    log('fetchAuctionsForUser fired');

    try {
      const auctionsInfo = (await getBorrowModule01GetUserLoans(
        walletAddress,
      )) as AuctionsInfoFromChain;
      log('auctionsInfo:', auctionsInfo);
      const formattedAuctions = formatAuctions(auctionsInfo).filter(
        (auction) => !this.auctions.getValue()?.has(auction.id),
      );
      log('formattedAuctions:', formattedAuctions);
      this.auctions.next(
        new Map(
          ...(this.auctions.getValue() || new Map()),
          formattedAuctions.map((auction) => [auction.id, auction]),
        ),
      );
    } catch (e) {
      console.error(e);
      log('fault fetchAuctionsForUser');
    }
  },

  async fetchAuctionByIdAndSet(id: string) {
    if (this.auctions.getValue()?.has(id)) return;
    log('fetchAuctionByIdAndSet fired');

    try {
      const auctionInfo = (await getBorrowModule01Loans(id)) as AuctionFromChain;
      log('fetchAuctionByIdAndSet auctionInfo', auctionInfo);
      const formattedAuction = formatAuction(id, auctionInfo);
      log('fetchAuctionById formatted result', formattedAuction);
      auctionsStore.addAuction(formattedAuction);
    } catch (e) {
      console.error(e);
      log('fetchAuctionById fault for id:', id);
    }
  },

  startAuctionsTracking() {
    this.trackingInitialized = true;
    log('startAuctionsTracking fired');
    trackBorrowModule01AuctionStarted({
      callback([event]) {
        if (!event) return;
        log('AuctionsTracking AuctionStarted fired event: ', { event });
        log('topics', event.topics);
        log(
          'decode topic 1',
          getWeb3WsProvider().eth.abi.decodeParameter('uint256', event.topics[1] as string),
        );

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        log('AuctionsTracking AuctionStarted data: ', {
          id,
        });

        auctionsStore.fetchAuctionByIdAndSet(id);
      },
      loanId: '',
      borrower: '',
    });

    trackBorrowModule01LoanIssued({
      callback: auctionsStore.loanIssuedOrCancelledCallback,
      loanId: '',
      lender: '',
    });

    trackBorrowModule01AuctionCancelled({
      callback: auctionsStore.loanIssuedOrCancelledCallback,
      loanId: '',
      borrower: '',
    });

    trackBorrowModule01AuctionInterestRateMaxUpdated({
      callback([event]) {
        if (!event) return;
        log('AuctionInterestRateMaxUpdated callback fired', event);

        const id = getWeb3WsProvider().eth.abi.decodeParameter(
          'uint256',
          event.topics[1] as string,
        ) as unknown as string;

        const { 0: newInterestRateMax } = getWeb3WsProvider().eth.abi.decodeParameters(
          ['uint16'],
          event.data,
        ) as [string];

        auctionsStore.updateAuctionAuctionInfo(
          id,
          'interestRateMax',
          fromBpToPercent(newInterestRateMax),
        );
      },
      loanId: '',
      borrower: '',
    });
  },

  loanIssuedOrCancelledCallback([event]: [web3Core.Log]) {
    log('AuctionsTracking LoanIssued fired', event);

    if (!event) return;

    const id = getWeb3WsProvider().eth.abi.decodeParameter(
      'uint256',
      event.topics[1] as string,
    ) as unknown as string;

    log('startAuctionsTracking LoanIssued data: ', {
      id,
    });

    auctionsStore.removeAuction(id);
  },

  addAuction(auction: Auction) {
    if (!this.trackingInitialized) this.startAuctionsTracking();
    log('addAuction fired', auction);
    const auctions = auctionsStore.auctions.getValue() || new Map();
    if (auctions.has(auction.id)) {
      log('addAuction fault auction exist', auction);
      return;
    }
    auctions.set(auction.id, auction);
    log('set auctions', auctions);
    auctionsStore.auctions.next(new Map(auctions));
  },

  updateAuctionAuctionInfo<K extends keyof AuctionInfo>(
    id: string,
    param: K,
    value: AuctionInfo[K],
  ) {
    log('updateAuctionAuctionInfo params: ', { id, param, value });
    const auctions = this.auctions.getValue();
    if (!auctions) return;
    const auction = auctions?.get(id);
    if (!auction) {
      log('updateAuctionAuctionInfo fault auction not exist', { id });
      return;
    }
    auction.auctionInfo[param] = value;
    log('set auctions', auctions);
    this.auctions.next(new Map([...auctions]));
  },

  updateAuctionParam<T extends keyof Auction>(id: string, param: T, value: Auction[T]) {
    log('updateAuctionParam params: ', { id, param, value });
    const auctions = this.auctions.getValue();
    const auction = auctions?.get(id);
    if (!auction) {
      log('updateAuctionParam fault auction not exist', { id });
      return;
    }
    auction[param] = value;
    log('set auctions', auctions);
    this.auctions.next(new Map(auctions));
  },

  removeAuction(auctionId: string) {
    log('removeAuction fired', auctionId);
    let auctions = new Map(auctionsStore.auctions.getValue());
    if (!auctions || !auctions.has(auctionId)) {
      log('addAuction fault auction not exist', { auctionId, auctions });
      return;
    }
    auctions.delete(auctionId);
    auctionsStore.auctions.next(auctions);
  },
};
