import { Web3Provider } from '@ethersproject/providers';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import debug from 'debug';
import { ethers } from 'ethers';
import { AppType } from 'src/constants/appType';
import { NETWORK_LIST_FOR_ERC_20, NETWORK_LIST_FOR_ERC_721 } from 'src/constants/chain';
import { walletStore } from 'src/store/walletStore';
import { NotNullValues } from 'src/types/objectHelpers';
import { EventEmitter } from 'src/utils/EventEmitter';
import { isWalletLocked } from 'src/utils/wallet';
import Web3Modal from 'web3modal';

const log = debug('utils:web3Controller');

const createEthereumProviderOptions = (appType: AppType): EthereumProviderOptions => {
  const networkList = appType === 'erc-20' ? NETWORK_LIST_FOR_ERC_20 : NETWORK_LIST_FOR_ERC_721;
  return {
    projectId: '0f23acfb130ecdad88eae4f65292c9d8',
    chains: networkList.map((network) => network.id),
    showQrModal: true,
    qrModalOptions: {
      explorerExcludedWalletIds: 'ALL',
    },
    events: ['connect', 'disconnect', 'chainChanged', 'accountsChanged'],
    methods: [
      'eth_sendTransaction',
      'eth_signTransaction',
      'eth_sign',
      'personal_sign',
      'eth_signTypedData',
    ],
  };
};

export type Web3ControllerState = {
  walletProvider: null | Web3Provider;
  userAddress: null | string;
  isConnecting: boolean;
  chainId: null | number;
  version: number;
};

export type ConnectedWeb3ControllerState = NotNullValues<Web3ControllerState>;

export type Web3Controller = ReturnType<typeof getWeb3Controller>;

/**
 * This controller is responsible for connecting/disconnecting wallet account,
 * storing active account address and (un)subscribing to wallet events.
 */
export const getWeb3Controller = (initialState: Web3ControllerState, web3Modal: Web3Modal) => {
  let state = initialState;
  let unsubscribe = () => {};
  let walletconnectProvider: EthereumProvider;

  const emitter = new EventEmitter<Web3ControllerState>();

  const getState = () => state;
  const setState = (newState: Partial<Web3ControllerState>) => {
    state = { ...state, ...newState, version: state.version + 1 };
    emitter.emit(state);
  };

  const init = async (appType: AppType) => {
    walletconnectProvider = await EthereumProvider.init(createEthereumProviderOptions(appType));

    if (walletconnectProvider && walletconnectProvider.session) {
      return connect('walletconnect');
    }

    if (web3Modal.cachedProvider && !isWalletLocked()) {
      return connect();
    }
  };

  const connect = async (walletId?: string) => {
    walletStore.isConnecting.next(true);
    let newWalletProvider: Web3Provider;
    try {
      log('connect start');
      setState({ isConnecting: true });

      if (walletId === 'walletconnect') {
        if (!walletconnectProvider.session) await walletconnectProvider.connect();
        newWalletProvider = new ethers.providers.Web3Provider(walletconnectProvider);
      } else {
        const instance = await (walletId ? web3Modal.connectTo(walletId) : web3Modal.connect());
        newWalletProvider = new ethers.providers.Web3Provider(instance);
      }

      const [accounts, network, signer] = await Promise.all([
        newWalletProvider.listAccounts(),
        newWalletProvider.getNetwork(),
        newWalletProvider.getSigner(),
      ]);

      log('connect', accounts, network);

      subscribe(newWalletProvider);

      setState({
        isConnecting: false,
        chainId: network.chainId,
        userAddress: accounts[0] || '',
        walletProvider: newWalletProvider,
      });

      walletStore.setWallet(accounts[0]);
      walletStore.setSigner(signer);
      walletStore.setChainId(network.chainId);
      walletStore.isConnecting.next(false);
    } catch (err) {
      console.error('components:Web3CtxProvider caught error:', err);
      setState({ isConnecting: false });
      disconnect();
    }
  };

  const subscribe = (provider: Web3Provider) => {
    log('subscribe');

    unsubscribe();

    const onChainChange = (chainId: number) => {
      log('chainChanged', chainId);
      connect();
    };
    const onAccountsChange = (accounts: string[]) => {
      log('accountChanged', accounts);
      if (accounts.length > 0) {
        connect();
      } else {
        disconnect();
      }
    };

    const extProvider = provider.provider as any;

    extProvider.on('close', disconnect);
    extProvider.on('disconnect', disconnect);
    extProvider.on('accountsChanged', onAccountsChange);
    extProvider.on('chainChanged', onChainChange);

    unsubscribe = () => {
      log('unsubscribe');
      extProvider.removeListener('close', disconnect);
      extProvider.removeListener('disconnect', disconnect);
      extProvider.removeListener('accountsChanged', onAccountsChange);
      extProvider.removeListener('chainChanged', onChainChange);
    };
  };

  const disconnect = () => {
    log('disconnect');

    unsubscribe();
    web3Modal.clearCachedProvider();
    const extProvider = state.walletProvider?.provider as any;
    // may work only in walletconnect
    extProvider?.close?.();
    extProvider?.disconnect?.();
    setState({ ...initialState, userAddress: '' });
    walletStore.clearAll();
  };

  return {
    getState,
    init,
    connect,
    disconnect,
    onUpdate: emitter.listen,
  };
};

export const isWalletConnected = (
  state: Web3ControllerState,
): state is ConnectedWeb3ControllerState =>
  Boolean(state.userAddress && state.chainId && state.walletProvider);
