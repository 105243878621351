import { ConnectWalletModal } from 'src/components/Modals/ConnectWalletModal';
import { TransactionModal } from 'src/components/Modals/TransactionModal/TransactionModal';
import { ModalSettings, useModal } from 'src/providers/ModalsProvider';
import { ConfirmationDialog } from './ConfirmationDialog';

const renderModal = (modal: ModalSettings) => {
  switch (modal.key) {
    case 'connect_wallet':
      return <ConnectWalletModal />;
    case 'transaction':
      return (
        <TransactionModal message={modal.message} txHash={modal.txHash} txError={modal.txError} />
      );
    case 'confirmation':
      return <ConfirmationDialog {...modal} />;
    default:
      return null;
  }
};

export const Modals = () => {
  const modal = useModal();
  return modal ? renderModal(modal) : null;
};
