import { Link } from 'react-router-dom';
import { base, borderRadius, breakpoint, color } from 'src/uiKit/theme';
import styled from 'styled-components';

export const MobileButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 4rem 0;

  button {
    background: ${color.grayBg};
  }

  @media (min-width: ${breakpoint.tablet}) {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  position: fixed;
  padding: ${base.base4};
  display: grid;
  grid-gap: ${base.base2};
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 ${borderRadius.normal} ${borderRadius.normal};
  background: ${color.white};
  margin: 0;
  width: 100%;

  @media (min-width: ${breakpoint.tablet}) {
    position: absolute;
    top: calc(100% - 3rem);
    width: auto;
    border-radius: ${borderRadius.normal};
  }
`;

export const ButtonsWrap = styled.div`
  padding: ${base.base4} 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: ${base.base4} 0;
  list-style: none;
  a {
    text-decoration: none;
    color: inherit;
    opacity: 0.8;
  }
  :not(:last-of-type) {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
`;

export const MenuItem = styled.li`
  padding: 1.25rem 3.5rem;
  font-size: 2.5rem;
  font-weight: 700;
  :hover {
    background: ${color.grayBg};
  }
  a {
    text-decoration: none;
    color: ${color.black};
  }
`;

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})<{ disabled?: boolean }>(
  ({ disabled }) => `
  display: grid; 
  width: 100%;
  padding: 1.25rem 3.5rem;
  text-decoration: none;
  opacity: ${disabled ? 0.4 : 1};
  pointer-events: ${disabled ? 'none' : 'initial'};
`,
);

export const CloseButton = styled.div`
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
