import { AxiosResponse } from 'axios';
import debug from 'debug';
import { coingeckoApi } from 'src/api/coingecko';
import { CoingeckoToken, CoingeckoTokenFromFullList } from 'src/api/coingecko/types';
import { getChainConfig } from 'src/store/chainStore';
import { pricesStore } from 'src/store/pricesStore';
import { Token } from 'src/types/tokens';
import { withTimeout } from 'src/utils/delay';

export let tokensList: null | CoingeckoTokenFromFullList[] = null;
let tokensListFetching = false;

const log = debug('utils:coingecko');

// @ts-ignore
export async function getFullTokensListFromCoingecko(force = false) {
  if (tokensListFetching) {
    await withTimeout(1500, () => getFullTokensListFromCoingecko(force));
  }
  log('getFullTokensListFromCoingecko fired', { force });
  try {
    if (force || !tokensList) {
      tokensListFetching = true;
      tokensList = (await coingeckoApi.getTokensList()).data;
    }
  } catch (e) {
    console.error(e);
  } finally {
    tokensListFetching = false;
  }

  return tokensList;
}

export async function getTop100TokensFromCoingecko() {
  const log = debug('utils:getTop100TokensFromCoingecko');

  const categoryId = getChainConfig()?.coingecko?.categoryId;

  if (!categoryId) {
    console.warn('getTop100TokensFromCoingecko categoryId is undefined');
    return [];
  }

  const [list, top] = (
    await Promise.allSettled([
      getFullTokensListFromCoingecko(true),
      coingeckoApi.getTop100Tokens(categoryId),
    ])
  ).map((res) => (res.status === 'fulfilled' ? res.value : [])) as [
    CoingeckoTokenFromFullList[],
    AxiosResponse<CoingeckoToken[], any>,
  ];

  log(list);
  log(top.data);

  const tokenAddressPriceMap: Record<string, string> = {};

  const tokens: Token[] = (
    await Promise.all(
      top.data.map(async (token: CoingeckoToken) => {
        const { id, name, symbol, current_price, image } = token;

        const tokenFromList = list.find(
          (el: CoingeckoTokenFromFullList) => el.id === id,
        ) as CoingeckoTokenFromFullList;

        if (!tokenFromList) return;

        const coingeckoChainName = getChainConfig()?.coingecko?.chainName;

        if (!coingeckoChainName) return;

        const address = tokenFromList.platforms[coingeckoChainName];

        if (!address) return;

        tokenAddressPriceMap[address] = String(current_price);

        return {
          address,
          name,
          symbol: symbol.toUpperCase(),
          type: 1 as 1,
          decimals: 0,
          balance: '0',
          source: 'coingecko' as const,
          image,
        };
      }),
    )
  ).filter(Boolean);

  pricesStore.setTokensPrices(
    Object.keys(tokenAddressPriceMap),
    Object.values(tokenAddressPriceMap),
    'coingecko',
  );

  return tokens;
}
