export type TxError = any | null

export enum TxStatus {
  Failed,
  Mined,
  Pending,
}

export type TxRecord = {
  hash: string
  from: string
  status: TxStatus.Pending | TxStatus.Mined | TxStatus.Failed
  error: TxError
}

export type TxByHash = Record<string, TxRecord>

export type TransactionsState = {
  lastTxHash: null | string
  txByHash: TxByHash
}
