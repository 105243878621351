import debug from 'debug';
import { ethers } from 'ethers';
import { BehaviorSubject } from 'rxjs';
import eventTracker from 'src/contracts/websocketEventTracker';
import { getEthersWsProvider } from 'src/store/chainStore';

const log = debug('store:blockStore');

export const blockStore = {
  currentBlock: new BehaviorSubject<ethers.providers.Block | null>(null),

  async fetchAndTrackBlockForChain() {
    log('fetchAndTrackBlockForChain init');

    this.currentBlock.next(null);
    const currentBlock = await getEthersWsProvider().getBlock('latest');

    log('init currentBlock: ', currentBlock);

    this.currentBlock.next(currentBlock);

    eventTracker.trackBlockHeader((block: ethers.providers.Block) => {
      log('new block: ', block);
      blockStore.currentBlock.next(block);
    });
  },

  getBlockNumber() {
    return this.currentBlock.getValue()?.number;
  },

  getBlockTimestamp() {
    return this.currentBlock.getValue()?.timestamp;
  },

  getBlockHash() {
    return this.currentBlock.getValue()?.hash;
  },
};
