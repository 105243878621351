import * as ethers from 'ethers'
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'

import { getChainConfig, getEthersWsProvider } from 'src/store/chainStore'

    import abi from 'src/contracts/abis/bobHubOracle.json'

export function getBobHubOracleContract(provider: ethers.providers.WebSocketProvider | JsonRpcSigner) {
  return new ethers.Contract(getChainConfig()?.contracts.bobHubOracle as string, abi, provider)
}

export function getBobHubOracleQuoteAssets(assets: any) {
              const contract = getBobHubOracleContract(getEthersWsProvider())
                return contract.quoteAssets(
    assets
  ).catch((e: any) => {
    console.error('Failed to call quoteAssets in bobHubOracle:', e)
  })
            }
    
