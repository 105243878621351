import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CloseModal } from '../../assets/images/icons/ic-close-modal';

import {
  ModalCloseBtn,
  ModalContent,
  ModalControlsContainer,
  ModalInner,
  ModalOuter,
  ModalTitle,
  StyledModal,
} from './styled';

export const Modal: React.FC<{
  title?: string;
  onCloseModal: () => void;
  children: React.ReactNode;
  ModalControls?: React.FC;
  forCollateral?: boolean;
  forMenu?: boolean;
  forConnect?: boolean;
  forTransaction?: boolean;
}> = ({
  onCloseModal,
  ModalControls,
  children,
  title,
  forCollateral = false,
  forMenu = false,
  forConnect = false,
  forTransaction = false,
}) => {
  const [show, setShow] = useState(false);
  const innerRef = useRef(null);

  useEffect(() => {
    setShow(true);
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scroll(scrollLeft, scrollTop);
    };
    return () => {
      window.onscroll = () => null;
    };
  }, []);

  function handleClose() {
    setShow(false);
    setTimeout(() => onCloseModal(), 200);
  }

  return (
    <ModalOuter
      onClick={handleClose}
      forConnect={forConnect}
      forCollateral={forCollateral}
      forTransaction={forTransaction}
      forMenu={forMenu}
    >
      <StyledModal>
        <CSSTransition
          in={show}
          timeout={200}
          classNames={forMenu ? 'slide-in' : 'scale'}
          unmountOnExit
          nodeRef={innerRef}
        >
          <ModalInner ref={innerRef}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              {title && <ModalTitle>{title}</ModalTitle>}
              {children}
              {ModalControls && (
                <ModalControlsContainer>
                  <ModalControls />
                </ModalControlsContainer>
              )}
              <ModalCloseBtn onClick={handleClose}>{CloseModal}</ModalCloseBtn>
            </ModalContent>
          </ModalInner>
        </CSSTransition>
      </StyledModal>
    </ModalOuter>
  );
};
